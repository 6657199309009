<template>
  <div id="reportlink" class="orderlist container">
    <h2>
      <font-awesome-icon icon="rectangle-list" />&nbsp;リンクレポート表示
    </h2>
    <p id="result_orderlist" v-if="result != ''">{{ result }}</p>
    <br />
    <div class="popup" id="js-popup">
      <span id="reportPageNum">{{currentPage_report + '/' + reportJsonList.length}}</span>
      <span v-if="currentPage_report > 1" class="reportViewControl" style="left: 5%;" @click.stop="reportPage(-1)">＜</span>

      <span class="popup-inner">
        <!-- <form id="print-form">
          <input type="button" value="レポート印刷" id="print" @click="funcprint()" />
        </form> -->
        <!-- <div class="close-btn" id="js-close-btn">X</div> -->
        <div id="reportList">
          <ReportTemplate :contents="reportJsonList" :currentPage_report="currentPage_report"></ReportTemplate>
        </div>
      </span>

      <span v-if="currentPage_report != reportJsonList.length" class="reportViewControl" style="right: 5%;" @click.stop="reportPage(1)">＞</span>
      <div class="black-background" id="js-black-bg">
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import ReportTemplate from "./ReportTemplate.vue"
// import {API_IP, API_PORT} from "../const/const.js"

export default {
  name: "ReportLink",
  components: {
    ReportTemplate,
  },
  data() {
    // TableCreate用プロパティ end-------------------------------------------------------------------------------
    return {
      key_orderID:"",
      key_PatientID: "",
      key_status: "",
      result: "",
      // 1ページに表示する件数
      perPage_group: 10,
      perPage_order: "all",
      perPage_report: 1,
      currentPage_group:1,
      currentPage_order:1,
      currentPage_report:1,
      resultList_group:{},
      resultList_order:{},
      allCheckFlg_group:false,
      allCheckFlg_order:false,
      selectMaxNum_group:500,
      selectMaxNum_order:10000,
      cond_group:{},
      cond_order:{},
      // TableCreate用プロパティ end-------------------------------------------------------------------------------
      groupnumber:0,
      ordernumber:0,
      groupcount:0,
      ordercount:0,
      ready_check:false,
      ordergroupseq_list:[],
      postcount:0,
      orderuid:"",
      postcount2:0,
      htmlContent: "",
      orderjsonList: [],
      GroupName: "",
      DoubleReadingMode: "",
      Anonymously: "",
      GroupComment: "",
      Applicant: "",
      InterpretationRequests: "",
      OutPatientHospital: "",
      Departments: "",
      LedgerNumber: "",
      EditedPatientName: "",
      EditedPatientIdeogram: "",
      EditedPatinePhonetic: "",
      BodyPart: "",
      AttendingPhysician: "",
      Protocol: "",
      Operator: "",
      ContrastAgent: "",
      ClinicalInfo: "",
      StudyComments: "",
      OrderComments: "",
      Base64text: "",
      reportView_orderuidList:[],
      reportJsonList:[],
      paginationFlg : false,
    };
  },
  methods: {
    async viewReport(search_type,seq){
			await this.select_report(search_type,seq)
			if(this.reportJsonList.length != 0){
				this.set_report()
				this.result = ""
			}else{
				this.result = "読影が完了してないため、レポートを表示できませんでした。"
			}
    },
    async select_report(search_type,seq) {
      var _this = this
			this.reportView_orderuidList = []
      let formData = new FormData()
      if(search_type == 'group'){
        formData.append("orderuidList","")
        formData.append("ordergroupseq",seq)
      }else{
				this.reportView_orderuidList.push(seq)
        formData.append("orderuidList",this.reportView_orderuidList)
        // formData.append("orderuidList","")
        formData.append("ordergroupseq","none")
      }
      formData.append("accessToken",localStorage.getItem("accessToken"))
      formData.append("refreshToken",localStorage.getItem("refreshToken"))
      formData.append("id",localStorage.getItem('userID'))
      await axios.post(this.$store.state.API_HOST + '/select_report/',formData)
      .then(
        function(response) {
          console.log(response)
          if(typeof(response.data) != "string"){
            if(response.data.authData.result == "Refreshed"){
              _this.$store.dispatch('setAuthData', {
              accessToken: response.data.authData.access_token,
              expiresIn: response.data.authData.refresh_exp,
              refreshToken: response.data.authData.refresh_token,
              user_id:response.data.authData.user_id,
              sysAdmin:response.data.authData.sysAdmin
              });
            }
            _this.reportJsonList = response.data.res
            console.log(_this.reportJsonList);
            console.log(_this.reportJsonList[0]["reportjson"]);
          }else{
            _this.$store.dispatch('logout');
            _this.$store.commit('setLoginUser', '')
          }
        }.bind(this)
      )
      .catch(
        function(error) {
          this.result = "GETエラー";
          console.log(error);
          this.$store.dispatch('logout');
          this.$store.commit('setLoginUser', '')
        }.bind(this)
      );
      return;
    },
    async set_report(){
      // var _this = this;
      // ポップアップ表示
      var popup = document.getElementById('js-popup');
      if(!popup) return;
      popup.classList.add('is-show');
      // var blackBg = document.getElementById('js-black-bg');
      // var closeBtn = document.getElementById('js-close-btn');
      // closePopUp(blackBg);
      // closePopUp(closeBtn);
      // function closePopUp(elem) {
      //   if(!elem) return;
      //   elem.addEventListener('click', function(event) {
      //     event.stopPropagation();
      //     popup.classList.remove('is-show');
      //     _this.reportJsonList = []
      //   })
      // }
    },
    // 開いているレポートのページ番号算出
    reportPage(num){
      if(num == 1){
        this.currentPage_report += 1;
      }else{
        this.currentPage_report -= 1;
      }
    },
  },
  mounted: function(){
		console.log(location)
		let getValue = location.search.split("=")[1]
		console.log(getValue)
		if(location.search.includes('ordergroupseq')){
			this.viewReport('group',getValue)
		}else{
			this.viewReport('order',getValue)
		}
  }
};
</script>

<style>
    /* .orderuid, .orderuid_header{
        display:none;
    } */
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: .6s;
}
.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: max-content;
    min-height: 100%;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: .6s;
    overflow: auto; /* ←追記 */
    background-color: rgba(0,0,0,.8);
}

.popup.is-show {
  opacity: 1;
  visibility: visible;
}

.popup-inner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1000px;
    margin-top: 0.5rem;
     /* padding: 50px; / ←下記に修正 */
    padding: 1rem;
    background-color: #fff;
    z-index: 2;
}

#popup-inner_orderDetail {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    margin-top: 0.5rem;
     /* padding: 50px; / ←下記に修正 */
    padding: 1rem;
    background-color: #fff;
    z-index: 2;
}
.popup-inner img {
  width: 100%;
}

.popup-inner input {
  width: 100px;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.close-btn i {
  font-size: 20px;
  color: #333;
}

.black-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 1;
  /* cursor: pointer; */
}
#result {
    color:red
}

#print {
  width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 1em;
}

.mouse_hover:hover {
  background-color: rgb(118, 183, 239);
}
#ViewZone textarea{
  vertical-align:top;
}
#ViewZone textarea,#ViewZone input,#ViewZone select{
  pointer-events: none;
  background: #e4e4e4;
}
#orderTable, #groupTable {
  width: max-content;
}
.tableScroll {
  /* overflow:hidden;
  overflow-y: scroll; */
  overflow: auto;
  border: solid 1px;
  resize: vertical;
}
#orderSearch span.filter-name {
  display: inline-block;
  width: 7em;
}
.container::-webkit-scrollbar {  
  display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
}
#groupTable td,#groupTable th {
  width: max-content;
}
#orderTable td,#orderTable th {
  width: max-content;
}
#groupTable, #orderTable {
    border-top: solid 1px;
    border-bottom: solid 1px
}
#groupTable thead, #orderTable thead {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;  
}
.hide {
    display: none;
}
.table-wrapper {
  position: relative;
    width: calc(100% - 1rem);
    height: 20rem;
    overflow: auto;
}
.table-wrapper table {
  width: max-content;
  position: relative;
}
.table-wrapper td, th {
  width: max-content;
}
.table-wrapper th {
  position: sticky;
  top: 0px;
  z-index: 4;
}
.table-wrapper td {
  background-color: #ffffff;
  padding: 0.75rem 0.5rem;
  vertical-align: baseline;
}
.table-wrapper .cell-status, .cell-orderer, .cell-function {
  text-align: center;
}
.table-wrapper .cell-function {
  position: sticky;
  right: 0px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 2;
}
.table-wrapper .cell-function:hover svg {
  opacity: 0.75;
}
.table-wrapper th.cell-function {
  position: sticky;
  top: 0px;
  font-size: 1em;
  cursor: inherit;
  z-index: 3;
}
td.cell-checked,th.cell-checked {
  position: sticky;
  top: 0px;
  left: 0px;
  vertical-align: middle;
}

td.cell-function,th.cell-function{
  position: sticky;
  top: 0px;
  right: 0px;
  vertical-align: middle;
}
.VariableTable th {
  background-color:#d3d3d3 !important;
}

.VariableTable tr:nth-of-type(odd) td {
  background-color: #eee !important;
}

.VariableTable tr:nth-of-type(even) td {
  background-color: #f2f2f2 !important;
}


.orderlist-function-group {
  position: absolute;
  top: 25%;
  /* right: 48px; */
  width: max-content;
  z-index: 3;
  background-color: #ffffff;
}
.orderlist-function-group .btn {
  border-radius: 5px;
  color: #3b3b3b;
}
.orderlist-function-group .btn:first-child {
  border-top-right-radius: 0;
}
.olgHide, .olgHide_function {
  display: none!important;
}
.page {
  overflow: auto;
}

.VariableTable thead th {
  resize: horizontal;
  overflow: hidden;
}

.VariableTable thead th:not(.condition,.cell-checked,.cell-function) {
  cursor: pointer;
}

.VariableTable thead th input:not(.date-form,.form-check-input){
  width: 100%;
}
.VariableTable thead th .dates{
  width: 100%;
}

.hide {
  display: none;
}

th.active {
  color: #000;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}
.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #000;
}
.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}
.perPage_label {
  margin-left: 20px;
}
.sortClear {
  margin-left: 20px;
  margin-bottom: 3px;
}
.pagination {
  margin-top: 5px;
}
.page-link {
  color:#333 !important;
}
.active>.page-link,.page-link.active {
  /* background-color:#44aa34e0 !important; */
  color:#fff !important;
}
.form-check-input{
  background-color: #fff;
  color: inherit;
  /* cursor: not-allowed; */
  opacity: 1 !important;
}
#reportMenu{
  float:right;
  margin-right: 20px;
}
.reportMenuItem {
  margin-right: 20px;
  cursor: pointer;
}
#reportPageNum {
  z-index: 9999;
  position: absolute;
  font-size: 45px;
  left: 5%;
  top: 1%;
}
.reportViewControl, #reportPageNum {
  color:rgb(129 13 143);
}
.reportViewControl {
  font-size:70px;
  font-weight:900;
  position:absolute;
  top: 45%;
  z-index: 9999;
  cursor:pointer;
}

#reportList_forPrint{
	page-break-after: avoid;
}

#result_orderlist{
  color: indianred;
}

</style>
