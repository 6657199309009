<template>
<div class="container">
	<h2><FontAwesomeIcon icon="address-card" />&nbsp;メール認証</h2>
	<div class="container">
		<div class="row mb-2">
			<label for="verifykey" class="col-form-label" style="width:100%;">承認ボタンをクリックして、本登録を完了してください。</label>
			<div class="col-sm-10">
				<input type="text" id="verifykey" class="d-none" name="verifycode" :value="verifycode" style="width:50%">
			</div>
		</div>
	</div>
	<button @click="verify" class="btn btn-primary">承認</button>
	<p id="message" class="message">{{ message }}</p>
</div>
</template>

<script>
// import axios from "axios";
// import crypto from 'crypto-js';

export default {
  data(){
    return{
      message: "",
      verifycode: ""
    }
  },
  mounted() {
    this.setKey()
  },
  methods:{
    setKey(){
      this.verifycode = this.$route.params.token
    },
    async verify(){
      var _this = this

      // let params = new FormData()
      let data = {'verifykey': this.verifycode}
      
      await this.$store.dispatch('verify',data);
      console.log("承認処理")
      console.log(_this.$store.getters.verifyResult)
      _this.message = ""
      if(_this.$store.getters.verifyResult != 0 && _this.$store.getters.verifyResult != -1 && parseInt(_this.$store.getters.verifyResult)){
          _this.message = "登録完了しました。"
      }else if (_this.$store.getters.verifyResult == 0){
          _this.message = "有効期限が切れているため、本登録できませんでした。\n再度、仮登録をお願いいたします。"
      }else{
          _this.message = "登録に失敗しました。"
      }
    }
  }
}
</script>

<style>
.message {
  white-space: pre-wrap;
}
</style>