<template>
<div>
	<h2><font-awesome-icon icon="sign-in-alt" />&nbsp;{{ title }}</h2>
	<div class="container">
		<div class="row">
			<div class="col">
				<div class="card shadow mx-auto login-form">
					<div class="card-header">
						<h3 class="m-0 p-0">{{ title }}</h3>
					</div>
					<div class="card-body">
						<div>
							<label class="form-label w-100">ユーザー名：
								<input id="loginname" name="loginname" v-model="loginname" class="form-control">
							</label>
						</div>
						<div>
							<label class="form-label w-100">パスワード：
								<input id="password" type="password" v-model="password" class="form-control">
							</label>
						</div>
						<button class="btn btn-primary my-3" @click="login">ログイン</button>
					</div>
					<p id="message">{{ message }}</p>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
// import axios from 'axios'
import crypto from 'crypto-js';

export default {
  data () {
    return {
      title: "ログイン",
      message: "",
      loginname:"",
      password:""
    }
  },
  methods: {
    async login(){
      console.log("Login:: loginname=", this.loginname, " password=", this.password)
        const params = new URLSearchParams();
        params.append('username', this.loginname);   // OAuth2PasswordRequestFormでは「username」を使用しないといけないため、key名変更
        // params.append('password', this.password);
        
        // パスワード暗号化
        const hash = crypto.SHA3(this.password);
        params.append('password', hash.toString());
        params.append('app_name', this.$store.state.APP_NAME);


        await this.$store.dispatch('login_manage',params);
        console.log(localStorage)
        console.log(this.$store.getters)
        if(this.$store.getters.userID != 0 && this.$store.getters.userID != -1){
          this.message = ""
          this.$store.commit('setLoginUser', this.loginname)
        }else{
          this.message = "ログインに失敗しました。"
        }
        this.loginname='';
        this.password='';
    }
  },
}
</script>

<style>
#message {
  color:red
}
.login-form {
	width: 16rem;
}
</style>