<template>
	<div v-if="contents.length > 0">
		<span id="detailPageNum">ページ：{{currentnumber + '/' + contents.length}}</span>
		<span style="left: 32%; position: relative;">
			<v-btn :disabled="currentnumber <= 1" class="detailViewControl" @click.stop="userDetailPage(-1)">＜</v-btn>
			<v-btn :disabled="currentnumber == contents.length || contents.length <= 0" class="detailViewControl" @click.stop="userDetailPage(1)">＞</v-btn>
		</span>
		<span id="detailPage_Message" style="float:right;color:red;">{{ detailPage_Message }}</span>
	</div>

	<div v-for="( userItems, n ) in contents" :key="n" v-bind:detailnumber="n+1">
		<v-card v-show="currentnumber == (n+1)">

			<v-toolbar color="green">
				<v-toolbar-title>ユーザー名：{{localoldContents[n].loginname__users}}</v-toolbar-title>
				<v-spacer></v-spacer>
				<div v-if="!editFlg">
					<v-btn icon class="editButton">
						<v-icon @click="dataClear()">mdi-grease-pencil</v-icon>
						<v-tooltip activator="parent" location="bottom">編集</v-tooltip>
					</v-btn>
				</div>
				<div v-else>
					<v-btn icon class="editSaveButton" @click="saveUserDetail()">
						<v-icon>mdi-download</v-icon>
						<v-tooltip activator="parent" location="bottom">保存</v-tooltip>
					</v-btn>
					<v-btn small class="editCancelButton" @click="editCancel()">
						<v-icon>mdi-close-circle-outline</v-icon>
						<v-tooltip activator="parent" location="bottom">キャンセル</v-tooltip>
					</v-btn>
				</div>
			</v-toolbar>

			<div class="d-flex flex-row">
				<v-tabs v-model="tab" direction="vertical" color="green">
					<v-tab v-for="( tabitem, i ) in tabList" :key="i" v-bind:value="tabitem.dbname" style="border: 1px solid lightgray;" :disabled="editFlg && tab != tabitem.dbname">
						<span v-if="editedFlg && tab == tabitem.dbname">
							{{ tabitem.displayname }}*
						</span>
						<span v-else>
							{{ tabitem.displayname }}
						</span>
					</v-tab>
				</v-tabs>

				<v-window v-model="tab">
					<v-window-item v-for="( tabitem, k ) in tabList" :key="k" v-bind:value="tabitem.dbname">
						<v-card flat>
							<v-card-text>
								<div v-if="editFlg" style="color:red;">※のついた項目は必ず入力してください。</div>
								<div class="columnRow" v-for="(column,i) in this[tabitem.dataname]" :key="i" style="margin-bottom: 5px;">
									<div v-if="column.type == 'text' || column.type == 'number' || column.type == 'date'">
										<label v-bind:for="column.dbname">
											<span v-if="editFlg && column.required == 'required'" style="color:red;">※</span>
											{{column.displayname}}：
										</label>
										<input
											v-if="!editFlg"
											v-bind:name="column.dbname"
											v-model="localContents[n][column.dbname]"
											v-bind:type="column.type"
											:disabled="!editFlg"
											v-bind:class="{'bg-gray':!editFlg}"
											@change="inputCheck($event,column,n)"
										>
										<input
											v-else
											v-bind:name="column.dbname"
											v-model="localContents[n][column.dbname]"
											v-bind:type="column.type"
											:disabled="!editFlg"
											v-bind:class="{'bg-gray':!editFlg}"
											@change="inputCheck($event,column,n)"
											v-bind:placeholder="column.placeholder"
											v-bind:required="column.required == 'required'"
										>

										<span v-if="column.dbname.includes('zip__') && editFlg" style="margin-left: 5px;">
											<v-btn
												height="30"
												class="text-button"
												@click="fetchAddress($event,n,tabitem.dbname)"
												style="background-color: #f2f2f2 !important;"
											>住所入力</v-btn>
										</span>

										<span class="errorMessage" style="color:red;margin-left:10px;"></span>
									</div>

									<div v-else-if="column.type == 'textarea'">
										<label v-bind:for="column.dbname">{{column.displayname}}：</label>
										<textarea
											v-bind:name="column.dbname"
											v-model="localContents[n][column.dbname]"
											:disabled="!editFlg"
											v-bind:class="{'bg-gray':!editFlg}"
											v-bind:placeholder="column.placeholder"
											@change="inputCheck($event,column,n)"
											style="width: 400px;vertical-align:top;"
										></textarea>
										<span class="errorMessage" style="color:red;margin-left:10px;"></span>
									</div>

									<div v-else-if="column.type == 'radio'" style="width:700px;">
										<template v-if="column.dbname != 'user_category__users' ||  (column.dbname == 'user_category__users' && !editFlg)">
											<label class="col-sm-2 col-form-label" style="white-space: nowrap;">{{column.displayname}}：</label>
											<span class="col-sm-10">
												<div v-for="( radioitem, l ) in this[column.dbname+'_radio']" :key="l" class="form-check form-check-inline">
													<label class="mr-2">
														<input
															type="radio"
															v-bind:name="column.dbname+n"
															:disabled="!editFlg"
															v-model="localContents[n][column.dbname]"
															class="form-check-input"
															v-bind:value="radioitem.value"
															v-bind:class="{'bg-gray_radio':!editFlg}"
															@change="inputCheck($event,column,n)"
														>{{ radioitem.name }}
													</label>
												</div>
											</span>
										</template>
									</div>

									<div v-else-if="column.type == 'select'">
										<label v-bind:for="column.dbname+n">{{column.displayname}}：</label>
										<select
											v-bind:name="column.dbname+n"
											v-bind:id="column.dbname+n"
											v-model="localContents[n][column.dbname]"
											:disabled="!editFlg"
											v-bind:class="{'bg-gray':!editFlg}"
											@change="inputCheck($event,column,n)"
										>
											<option v-for="( optionitem, l ) in this[column.dbname+'_select']" :key="l">{{ optionitem }}</option>
										</select>
									</div>
									<div v-else-if="column.type == 'select_combo'" style="display:inline-flex;">
										<label v-bind:for="column.dbname+n">{{column.displayname}}：</label>
										<span style="width:250px;">
											<!-- <v-col cols="12"> -->
												<v-combobox
													v-bind:name="column.dbname+n"
													v-bind:items="this[column.dbname+'_combo']"
													v-model="localContents[n][column.dbname]"
													:disabled="!editFlg"
													v-bind:class="{'bg-gray':!editFlg}"
													variant="solo"
													@change="inputCheck($event,column,n)"
													@blur="inputCheck($event,column,n)"
												></v-combobox>
											<!-- </v-col> -->
										</span>
									</div>

									<div v-else-if="column.type == 'checkbox'">
										<label v-bind:for="column.dbname+n" class="col-sm-2 col-form-label" style="width: auto;">{{column.displayname}}：</label>
										<span class="col-sm-10 form-check form-switch" style="display: inline-block;margin-left: 5px;width:200px;">
											<label>
												<input
													type="checkbox"
													:disabled="!editFlg"
													v-bind:class="{'bg-gray':!editFlg}"
													v-bind:name="column.dbname+n"
													v-model="localContents[n][column.dbname]"
													v-bind:value="localContents[n][column.dbname]"
													true-value=1
													false-value=0
													class="form-check-input"
													@change="inputCheck($event,column,n)"
												>&nbsp;{{ (localContents[n][column.dbname] == 1) ? 'あり' : 'なし' }}
											</label>
										</span>
									</div>

									<div v-else-if="column.type == 'json'">
										<div v-if="localContents[n][column.dbname] != null">
											<div v-for="( jsonitem, key, l ) in localContents[n][column.dbname]" :key="key">
												<label v-bind:for="column.dbname">
													<span v-if="editFlg && column.required == 'required'" style="color:red;">※</span>
													{{column.displayname + (l+1)}}：
												</label>
												<input
													v-bind:name="column.dbname"
													v-bind:number="column.dbname.split('__')[0]"
													v-model="localContents[n][column.dbname][column.dbname.split('__')[0] + (l+1)]"
													type="text"
													:disabled="!editFlg"
													v-bind:class="{'bg-gray':!editFlg}"
													v-bind:placeholder="column.placeholder"
													@change="inputCheck($event,column,n)"
												>
												<span v-if="editFlg && (l+1) == Object.keys(localContents[n][column.dbname]).length" style="margin-left: 5px;" v-bind:keynumber="n">
													<v-btn v-if="Object.keys(localContents[n][column.dbname]).length < 5" icon class="editAddButton" @click="RowEdit(n,column.dbname,'add')">
														<v-icon color="green">mdi-plus-circle</v-icon>
													</v-btn>
													<v-btn v-if="Object.keys(localContents[n][column.dbname]).length > 1" icon class="editDeleteButton" @click="RowEdit(n,column.dbname,'delete')">
														<v-icon>mdi-minus-circle-outline</v-icon>
													</v-btn>
												</span>
												<span class="errorMessage" style="color:red;margin-left:10px;"></span>
											</div>
										</div>

										<!-- json型の項目が空の場合はフォームを一つ作り、値をlocalContentsに入れるとv-if側の処理が動く -->
										<div v-else>
											<div>
												<label v-bind:for="column.dbname">
													<span v-if="editFlg && column.required == 'required'" style="color:red;">※</span>
													{{column.displayname + "1"}}：
												</label>
												<input
													v-bind:name="column.dbname"
													type="text"
													:disabled="!editFlg"
													v-bind:class="{'bg-gray':!editFlg}"
													v-bind:placeholder="column.placeholder"
													@change="editJson($event,n,column.dbname)"
												>
												<span class="errorMessage" style="color:red;margin-left:10px;"></span>
											</div>
										</div>
									</div>

									<div v-else-if="column.type == 'json_double'">
										<div v-if="localContents[n][column.dbname] != null">
											<label v-bind:for="column.dbname+n">{{column.displayname}}：</label>
											<span v-for="( jsonitem, key) in this[column.dbname.split('__')[0]+'_combo']" :key="key" style="width:200px;display:inline-flex;margin-right: 5px;">
													<label style="vertical-align: super;">{{ this[column.dbname.split('__')[0]+'_combo'][key].name }}⇒</label>
												<!-- <v-col cols="12"> -->
													<v-combobox
														v-bind:name="column.dbname+n"
														v-bind:items="jsonitem.value"
														v-model="localContents[n][column.dbname][key]"
														:disabled="!editFlg"
														v-bind:class="{'bg-gray':!editFlg}"
														@change="editJson_double(n,column.dbname,key,'update')"
														@blur="editJson_double(n,column.dbname,key,'update')"
														variant="solo"
														multiple
														clearable
													></v-combobox>
												<!-- </v-col> -->
											</span>
										</div>
										<div v-else>
											<label v-bind:for="column.dbname+n">{{column.displayname}}：</label>
											<span v-for="( jsonitem, key) in this[column.dbname.split('__')[0]+'_combo']" :key="key" style="width:200px;display: inline-flex;margin-right: 5px;">
												<!-- <v-col cols="12"> -->
													<label style="vertical-align: super;">{{ this[column.dbname.split('__')[0]+'_combo'][key].name }}⇒</label>
													<v-combobox
														v-bind:name="column.dbname+n"
														v-bind:items="this[column.dbname.split('__')[0]+'_combo'][key].value"
														:disabled="!editFlg"
														v-bind:class="{'bg-gray':!editFlg}"
														variant="solo"
														v-model="this[column.dbname.split('__')[0]+'_combo'][key].selectedValue"
														@change="editJson_double(n,column.dbname,key,'insert')"
														@blur="editJson_double(n,column.dbname,key,'insert')"
														multiple
														clearable
													></v-combobox>
												<!-- </v-col> -->
											</span>
										</div>
									</div>

									<div v-else-if="column.type.indexOf('connection_source') != -1 || column.type.indexOf('connection_point') != -1">
										<div v-if="!editFlg">
											<template v-if="column.type == 'connection_source_1'">
												<label v-bind:for="column.dbname">契約対象：</label>
												<template v-if="column.type.indexOf('connection_source') != -1">
													<input v-if="localContents[n][column.dbname]" v-bind:name="column.dbname" v-bind:value="localContents[n][column.dbname] + localContents[n]['customer__customers']" :disabled="true" v-bind:class="{'bg-gray':!editFlg}">
													<input v-else v-bind:name="column.dbname" value="" :disabled="true" v-bind:class="{'bg-gray':!editFlg}">
												</template>
											</template>
										</div>
									</div>

									<div v-else>
										<div v-if="!editFlg">
											<label v-bind:for="column.dbname">{{column.displayname}}：</label>
											<input v-bind:name="column.dbname" v-model="localContents[n][column.dbname]" :disabled="true" v-bind:class="{'bg-gray':!editFlg}">
										</div>
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-window-item>
				</v-window>
			</div>
		</v-card>
	</div>
</template>

<script>
	import axios from "axios";
	// import {API_IP, API_PORT} from "../const/const.js"
	import { Core as YubinBangoCore } from 'yubinbango-core2'

	export default {
		props: {
			contents: Object,
			currentnumber: Number,
			editFlg:Boolean,
			editedFlg:Boolean,
			saveFlg:Boolean,
			oldContents:Object,
		},
    data(){
			return {
				tab: 'users',
				tabList:[
					{displayname:"ユーザー",dbname:"users",dataname:"usersColumns"},
					{displayname:"施設",dbname:"hospitals",dataname:"hospitalsColumns"},
					{displayname:"法人",dbname:"corporations",dataname:"corporationsColumns"},
					{displayname:"契約",dbname:"customers",dataname:"customersColumns"},
				],
				usersColumns: [
					{displayname:"ユーザーID",dbname:"id__users",type:"",inputcheck:"",placeholder:"",required:"",unique:"check"},
					{displayname:"ユーザー名",dbname:"loginname__users",type:"text",inputcheck:"Hankaku",placeholder:"半角英数字で入力してください",required:"required",unique:""},
					{displayname:"ユーザー名（漢字）",dbname:"kanjiname__users",type:"text",inputcheck:"ZenkakuKanji",placeholder:"漢字で入力してください",required:"",unique:""},
					{displayname:"ユーザー名（カナ）",dbname:"kananame__users",type:"text",inputcheck:"ZenkakuKana",placeholder:"全角カナで入力してください",required:"",unique:""},
					{displayname:"メールアドレス",dbname:"email__users",type:"text",inputcheck:"",placeholder:"",required:"required",unique:"check"},
					// {displayname:"パスワード",dbname:"password__users",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約カテゴリー",dbname:"user_category__users",type:"radio",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"施設ID",dbname:"hospid__users",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"法人ID",dbname:"corpid__users",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"ドメイン名",dbname:"domain__users",type:"text",inputcheck:"",placeholder:"半角英大文字または数字",required:"",unique:""},
					{displayname:"職種",dbname:"profession__users",type:"select",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"所属先",dbname:"workspace__users",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"グループ権限",dbname:"groupadmin__users",type:"checkbox",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"システム管理権限",dbname:"sysadmin__users",type:"checkbox",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"ユーザー契約",dbname:"contracted__users",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約管理ID",dbname:"customerid__users",type:"number",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"登録ステータス",dbname:"reg_status__users",type:"checkbox",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"有効フラグ",dbname:"valid__users",type:"radio",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"リフレッシュトークン",dbname:"refresh_token__users",type:"textarea",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"承認日時",dbname:"verifydatetime__users",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"承認キー",dbname:"verifykey__users",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
				],
				hospitalsColumns:[
					{displayname:"施設ID",dbname:"id__hospitals",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"登録コード",dbname:"regist_code__hospitals",type:"text",inputcheck:"",placeholder:"",required:"",unique:"check"},
					{displayname:"ドメイン名",dbname:"domain__hospitals",type:"text",inputcheck:"HankakuOmoji",placeholder:"半角英大文字または数字",required:"",unique:"check"},
					{displayname:"施設名",dbname:"name__hospitals",type:"text",inputcheck:"",placeholder:"",required:"required",unique:"check"},
					{displayname:"施設名（カナ）",dbname:"name_kana__hospitals",type:"text",inputcheck:"ZenkakuKana",placeholder:"",required:"required",unique:""},
					{displayname:"郵便番号",dbname:"zip__hospitals",type:"number",inputcheck:"yubinbango",placeholder:"ハイフン不要",required:"",unique:""},
					{displayname:"都道府県",dbname:"prefecture__hospitals",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"市区町村",dbname:"city__hospitals",type:"text",inputcheck:"",placeholder:"例：川崎市川崎区",required:"",unique:""},
					{displayname:"町域・番地",dbname:"address1__hospitals",type:"text",inputcheck:"",placeholder:"例：旭町1-1",required:"",unique:""},
					{displayname:"建物名など",dbname:"address2__hospitals",type:"text",inputcheck:"",placeholder:"例：〇〇マンション101号",required:"",unique:""},
					{displayname:"電話番号",dbname:"phone__hospitals",type:"json",inputcheck:"",placeholder:"ハイフン不要",required:"",unique:""},
					{displayname:"FAX",dbname:"fax__hospitals",type:"json",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"ホームページURL",dbname:"homepage__hospitals",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"施設タイプ",dbname:"type__hospitals",type:"select_combo",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"部門",dbname:"department__hospitals",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"規模",dbname:"scale__hospitals",type:"select",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"院長名",dbname:"president__hospitals",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"事務長名",dbname:"manager__hospitals",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"オーダーカテゴリー",dbname:"order_category__hospitals",type:"json_double",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"法人ID",dbname:"corpid__hospitals",type:"number",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約状況",dbname:"contracted__hospitals",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約ID",dbname:"customerid__hospitals",type:"number",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"登録日",dbname:"startdate__hospitals",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"有効フラグ",dbname:"valid__hospitals",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
				],
				corporationsColumns:[
					{displayname:"法人ID",dbname:"id__corporations",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"登録コード",dbname:"regist_code__corporations",type:"text",inputcheck:"",placeholder:"",required:"",unique:"check"},
					{displayname:"ドメイン名",dbname:"domain__corporations",type:"text",inputcheck:"HankakuOmoji",placeholder:"半角英大文字または数字",required:"",unique:"check"},
					{displayname:"法人名",dbname:"name__corporations",type:"text",inputcheck:"",placeholder:"",required:"required",unique:"check"},
					{displayname:"法人名（カナ）",dbname:"name_kana__corporations",type:"text",inputcheck:"ZenkakuKana",placeholder:"",required:"required",unique:""},
					{displayname:"郵便番号",dbname:"zip__corporations",type:"number",inputcheck:"yubinbango",placeholder:"ハイフン不要",required:"",unique:""},
					{displayname:"都道府県",dbname:"prefecture__corporations",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"市区町村",dbname:"city__corporations",type:"text",inputcheck:"",placeholder:"例：川崎市川崎区",required:"",unique:""},
					{displayname:"町域・番地",dbname:"address1__corporations",type:"text",inputcheck:"",placeholder:"例：旭町1-1",required:"",unique:""},
					{displayname:"建物名など",dbname:"address2__corporations",type:"text",inputcheck:"",placeholder:"例：〇〇マンション101号",required:"",unique:""},
					{displayname:"電話番号",dbname:"phone__corporations",type:"json",inputcheck:"",placeholder:"ハイフン不要",required:"",unique:""},
					{displayname:"FAX",dbname:"fax__corporations",type:"json",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"ホームページURL",dbname:"homepage__corporations",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"法人タイプ",dbname:"type__corporations",type:"select_combo",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"代表者",dbname:"president__corporations",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"実務管理者",dbname:"manager__corporations",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"オーダーカテゴリー",dbname:"order_category__corporations",type:"json_double",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約状況",dbname:"contracted__corporations",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約ID",dbname:"customerid__corporations",type:"number",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"登録日",dbname:"startdate__corporations",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"有効フラグ",dbname:"valid__corporations",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
				],
				customersColumns:[
					{displayname:"契約ID",dbname:"id__customers",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約管理ID",dbname:"contract_id__customers",type:"text",inputcheck:"",placeholder:"",required:"required",unique:"check"},
					{displayname:"契約単位",dbname:"level__customers",type:"connection_source_1",inputcheck:"",placeholder:"",required:"",unique:"doublecheck"},
					{displayname:"契約番号",dbname:"customer__customers",type:"connection_point_1",inputcheck:"",placeholder:"",required:"",unique:"doublecheck"},
					// {displayname:"契約単位",dbname:"level__customers",type:"text",inputcheck:"",placeholder:"",required:"",unique:"doublecheck"},
					// {displayname:"契約番号",dbname:"customer__customers",type:"number",inputcheck:"",placeholder:"",required:"",unique:"doublecheck"},
					// {displayname:"契約対象",dbname:"level__customers/customer__customers",type:"connect",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"契約名",dbname:"name__customers",type:"text",inputcheck:"",placeholder:"",required:"required",unique:""},
					{displayname:"契約名（カナ）",dbname:"name_kana__customers",type:"text",inputcheck:"",placeholder:"",required:"required",unique:""},
					{displayname:"郵便番号",dbname:"zip__customers",type:"number",inputcheck:"yubinbango",placeholder:"ハイフン不要",required:"required",unique:""},
					{displayname:"都道府県",dbname:"prefecture__customers",type:"text",inputcheck:"",placeholder:"",required:"required",unique:""},
					{displayname:"市区町村",dbname:"city__customers",type:"text",inputcheck:"",placeholder:"例：川崎市川崎区",required:"required",unique:""},
					{displayname:"町域・番地",dbname:"address1__customers",type:"text",inputcheck:"",placeholder:"例：旭町1-1",required:"required",unique:""},
					{displayname:"建物名など",dbname:"address2__customers",type:"text",inputcheck:"",placeholder:"例：〇〇マンション101号",required:"",unique:""},
					{displayname:"電話番号",dbname:"phone__customers",type:"json",inputcheck:"",placeholder:"ハイフン不要",required:"required",unique:""},
					{displayname:"FAX",dbname:"fax__customers",type:"json",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"ホームページURL",dbname:"homepage__customers",type:"text",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"実務管理者",dbname:"manager__customers",type:"text",inputcheck:"",placeholder:"",required:"required",unique:""},
					{displayname:"登録日",dbname:"startdate__customers",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"更新日",dbname:"updatedate__customers",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
					{displayname:"有効フラグ",dbname:"valid__customers",type:"",inputcheck:"",placeholder:"",required:"",unique:""},
				],
				profession__users_select:["医師","放射線技師","検査技師","医療事務","その他"],
				scale__hospitals_select:["無床","-100床","101-200床","201-300床","301-床","年間-1000件","年間1001-5000件","年間5001-10000件","年間10000-件","その他"],
				// profession__users_select:[
				// 	{name:"医師",value:"医師"},
				// 	{name:"放射線技師",value:"放射線技師"},
				// 	{name:"検査技師",value:"検査技師"},
				// 	{name:"医療事務",value:"医療事務"},
				// 	{name:"その他",value:"その他"},
				// ],
				type__hospitals_combo:["クリニック","単科病院","総合病院","健診施設","療養施設"],
				type__corporations_combo:["株式会社","医療法人"],
				user_category__users_radio:[
					{name:"個人ユーザー",value:"1"},
					{name:"施設ユーザー",value:"2"},
					{name:"その他",value:"9"},
				],
				valid__users_radio:[
					{name:"有効",value:"1"},
					{name:"無効",value:"0"},
					{name:"休止中",value:"-1"},
				],
				order_category_combo:{
					treatment:{
						name:"診療",
						value:["CR","RF","CT","MR","MG","US"],
						selectedValue:""
					},
					checkup:{
						name:"健診",
						value:["CR","RF","CT","MR","MG","US","FS","ECG","PET"],
						selectedValue:""
					}
				},
				editlist:[],
				oldlist:[],
				my_dialog:false,
				confirmResult_save:true,
				confirmResult_cancel:true,
				NGlist:{},
				detailPage_Message:"",
				dummyValue : "",
			}
		},
		computed: {
			localContents: {
				get: function() {
					return this.contents
				},
				set: function(value) {
					this.$emit('update:contents', value)
				},
			},
			localoldContents: {
				get: function() {
					return this.oldContents
				},
				set: function(value) {
					this.$emit('update:oldContents', value)
				},
			},
		},
		watch:{
			tab(){
				this.$emit('update:editFlg',false)
			},
			treatment(){
				console.log("テスト成功")
			}
		},
		methods:{
			// 開いているレポートのページ番号算出
			userDetailPage(num){
				this.confirm_cancel()
				if(!this.confirmResult_cancel){
					return false;
				}
				this.$emit('update:editFlg',false)
				this.$emit('update:editedFlg',false)
				let tmpnumber = this.currentnumber
				if(num == 1){
					this.$emit('update:currentnumber',tmpnumber += 1);
				}else{
					this.$emit('update:currentnumber',tmpnumber -= 1);
				}
			},
			editCancel(){
				this.confirm_cancel()
				if(this.confirmResult_cancel){
					let elements = document.querySelectorAll(".errorMessage")
					elements.forEach(element => element.textContent = "");
					this.$emit('update:editFlg',false)
					this.$emit('update:editedFlg',false)
				}
			},
			confirm_cancel(){
				if(this.editFlg){
					this.confirmResult_cancel = window.confirm('編集中のデータがあります。\n編集内容を保存せずに操作を続けますか？');
				}
			},
			async inputCheck(event,columnInfo,userIndex){
				let _this = this
				var uniqueNGflg = false
				if(columnInfo.unique == "check" || columnInfo.unique){
					uniqueNGflg = await this.uniqueCheck(columnInfo,userIndex)
				}
				this.$emit('update:editedFlg',true)
				var str=event.target.value;
				var NGflg = false
				if(columnInfo.inputcheck != ""){
					let pattern = ""
					switch (columnInfo.inputcheck){
						case "Hankaku":
							pattern = /[A-Z|a-z|0-9]/;
							break;
						case "HankakuOmoji":
							pattern = /[A-Z|0-9]/;
							break;
						case "ZenkakuKanji":
							pattern = /([\s+\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
							break;
						case "ZenkakuKana":
							pattern = /[\s+\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]/mu;
							break;
						case "yubinbango":
							pattern = /[0-9]/;
					}
					var reg = new RegExp(pattern)
					var strArray = str.split("")
					for (let i=0;i<strArray.length;i++){
						if(!strArray[i].match(reg) && str != ""){
							NGflg = true
						}
					}
					// var editedstr = ""
					// var strArray = str.split("")
					// for (let i=0;i<strArray.length;i++){
					// 	if(strArray[i].match(reg)){
					// 		editedstr += strArray[i]
					// 	}
					// }
					// event.currentTarget.value=editedstr;
				}
				if(columnInfo.required == "required" && (str == "" || str == null)){
					NGflg = true
				}
				if(columnInfo.inputcheck == "yubinbango" && str.length != 7 && str != ""){
					NGflg = true
				}
				// 数値項目は空欄だとDB更新でエラーになるため、nullに変換
				if(columnInfo.type == "number" && str == ""){
					console.log(_this.localContents)
					_this.localContents[userIndex][columnInfo.dbname] = null
					console.log(_this.localContents)
				}
				if(!NGflg && !uniqueNGflg){
					// editedstr += strArray[i]
					console.log(columnInfo.displayname + "：入力問題なし")
					event.target.style.backgroundColor = null
					if(this.NGlist[columnInfo.dbname] >= 1){
						delete this.NGlist[columnInfo.dbname];
					}
					if(event.target.parentNode.querySelector(".errorMessage") != null){
						event.target.parentNode.querySelector(".errorMessage").textContent = ""
					}
				}else{
					event.target.style.backgroundColor = "red"
					if(uniqueNGflg){
						this.NGlist[columnInfo.dbname] = 2
						event.target.parentNode.querySelector(".errorMessage").textContent = "すでに使われているので、変更してください。"
					}else{
						this.NGlist[columnInfo.dbname] = 1
						console.log(this.NGlist)
						event.target.parentNode.querySelector(".errorMessage").textContent = "入力に誤りがあります。"
					}
				}
			},
			async uniqueCheck(column,index){
				let ResFlg = false
				let checkList = []
				let columnname = column.dbname.split("__")[0]
				let dbname = column.dbname.split("__")[1]
				let dbID = this.localContents[index]["id__" + dbname]
				// let formData = new FormData()
				if(column.unique == "doublecheck"){
					if(columnname == "level" || columnname == "customers"){
						checkList.push({"level":this.localContents[index]["level__customers"]})
						checkList.push({"customers":this.localContents[index]["customer__customers"]})
					}
				}else{
					let dic = {}
					dic[columnname] = this.localContents[index][column.dbname]
					checkList.push(dic)
				}
				var _this = this
				let data = {"checkList":checkList, "checkDB":dbname, "dbID":dbID}
				await axios.post(
					this.$store.state.API_HOST + '/unique_check/',
					data,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(response.data.res == "NG"){
								ResFlg = true
							}
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.result = "GETエラー";
						console.log(error);
						this.$store.dispatch('logout');
						this.$store.commit('setLoginUser', '')
					}.bind(this)
				);
				return ResFlg;
			},
			// 郵便番号から住所を取得する関数
			fetchAddress(event,userIndex,dbname) {
				let _this = this
				let zip = String(_this.localContents[userIndex]["zip__"+dbname])
				if (!zip.match(/^\d{3}-?\d{4}$/)) { //入力された値をチェック
					return false;
				}
				new YubinBangoCore(zip, (addr) => {
					console.log("住所：");
					console.log(addr)
					// region=都道府県, locality=市区町村, street=町域
					_this.localContents[userIndex]["prefecture__"+dbname]  = addr.region
					_this.localContents[userIndex]["city__"+dbname] = addr.locality
					_this.localContents[userIndex]["address1__"+dbname] = addr.street
				})
			},
			// json型の項目はDBに登録されていない場合、nullとなりv-modelが使用できないため、こちらで直接編集
			editJson(event,userindex,dbname){
				let jsonData = {}
				jsonData[dbname.split("__")[0] + "1"] = event.currentTarget.value
				this.localContents[userindex][dbname] = jsonData
				this.$emit('update:editedFlg',true)
			},
			// json型の項目はDBに登録されていない場合、nullとなりv-modelが使用できないため、こちらで直接編集。nullではない場合はeditedFlgのみ編集する。
			editJson_double(userIndex,dbname,key,status){
				if(status == "insert"){
					let jsonData = {}
					jsonData[key] = this[dbname.split('__')[0]+'_combo'][key].selectedValue
					this.localContents[userIndex][dbname] = jsonData
				}
				this.$emit('update:editedFlg',true)
			},
			// 電話番号などの値が複数存在する項目はJson用に行の追加または削除
			RowEdit(userIndex,dbname,type){
				if(type == "add"){
					let keyIndex = Object.keys(this.localContents[userIndex][dbname]).length
					let keyname = dbname.split("__")[0] + (keyIndex+1)
					this.localContents[userIndex][dbname][keyname] = ""
				}else{
					let keyIndex = Object.keys(this.localContents[userIndex][dbname]).length
					let keyname = dbname.split("__")[0] + (keyIndex)
					delete this.localContents[userIndex][dbname][keyname]
					this.$emit('update:editedFlg',true)
				}
			},
			// 前残りを防ぐため各変数を初期化
			dataClear(){
				this.$emit('update:editFlg',true);
				this.detailPage_Message='';
				this.NGlist = []
			},
			// 編集されたデータをDBに登録
			async saveUserDetail(){
				this.$emit('update:saveFlg',false)
				let _this = this
				this.detailPage_Message = ""
				if(this.editedFlg){
					let saveData = {};
					let targetInfo = {}
					targetInfo["dbname"] = this.tab
					targetInfo["userID"] = this.localContents[this.currentnumber - 1]["id__users"]
					// APIに送るデータを作成
					for (let key in this.localContents[this.currentnumber - 1]) {
						if(key.split("__")[1] == this.tab){
							let index = this[this.tab + "Columns"].findIndex(({dbname}) => dbname === key);
							if(this[this.tab + "Columns"][index]["required"] == "required"){
								if(this.localContents[this.currentnumber - 1][key] == "" || this.localContents[this.currentnumber - 1][key] == null){
									this.NGlist[key] = 1
								}
							}
							saveData[key.split("__")[0]] = this.localContents[this.currentnumber - 1][key]
							// 対象のテーブルのidが存在している場合は更新、存在しない場合は追加
							if(key == ("id__" + this.tab)){
								if(this.localContents[this.currentnumber - 1][key] != null){
									targetInfo["action"] = "update"
								}else{
									targetInfo["action"] = "insert"
								}
							}
							// 入力必須項目がNULLの場合は初期値を入れる
							else if(key == ("contracted__" + this.tab)){
								if(!this.localContents[this.currentnumber - 1][key]){
									saveData[key.split("__")[0]] = 0
								}
							}
							// 入力必須項目がNULLの場合は初期値を入れる
							else if(key == ("domain__" + this.tab)){
								if(!this.localContents[this.currentnumber - 1][key]){
									saveData[key.split("__")[0]] = ""
								}
							}
							// 入力必須項目がNULLの場合は初期値を入れる
							else if(key == ("startdate__" + this.tab) || key == ("updatedate__" + this.tab)){
								if(!this.localContents[this.currentnumber - 1][key]){
									//今日の日付データを変数hidukeに格納
									var hiduke=new Date(); 

									//年・月・日・曜日を取得する
									var year = hiduke.getFullYear();
									var month = ("00" + (hiduke.getMonth()+1)).slice(-2);
									var day = ("00" + (hiduke.getDate())).slice(-2);
									saveData[key.split("__")[0]] = year + month + day
								}
							}
						}
					}
					let saveInfo = [saveData,targetInfo]
					let data = {"saveInfo":saveInfo}
					console.log(saveInfo)

					if(Object.keys(this.NGlist).length == 0){
						// 保存するかどうかの確認ダイアログ
						this.confirmResult_save = window.confirm('編集内容をデータベースに保存しますか？');
						if(this.confirmResult_save){
							await axios.post(
								this.$store.state.API_HOST + '/save_userdetail/',
								data,
								{
									params: {
										'app_name': _this.$store.state.APP_NAME,
										'user_id': _this.$store.state.userID,
									},
									headers: {
										'accept': 'application/json',
										'Content-Type': 'application/json',
									},
									withCredentials: true
								}
							).then(
								function(response) {
									console.log(response)
									// API処理成功の場合
									if(typeof(response.data) != "string"){
										console.log(response.data.res);
										// _this.localoldContents = []
										// _this.localoldContents[this.currentnumber - 1] = Object.assign({},_this.localContents[this.currentnumber - 1]);
										this.detailPage_Message = this.localoldContents[this.currentnumber - 1].loginname__users + "様の情報を更新しました。"
										this.$emit('update:editFlg',false)
										this.$emit('update:editedFlg',false)
										this.$emit('update:saveFlg',true)
									}else{
										if(response.data == "Expired"){
											_this.$store.dispatch('logout');
											_this.$store.commit('setLoginUser', '');
										}else{
											this.detailPage_Message = "エラーのため保存できませんでした。"
										}
									}
								}.bind(this)
							)
							.catch(
								function(error) {
									this.result = "POSTエラー";
									console.log(error);
									this.$store.dispatch('logout');
									this.$store.commit('setLoginUser', '')
								}.bind(this)
							);
						}
					}else{
						console.log(this.NGlist)
						this.detailPage_Message = "入力内容が適切ではないため、保存できませんでした。"
					}
				}else{
					this.detailPage_Message = "編集されたデータはありません。"
				}
				return;
			},
		}
  }
</script>

<style>
	#UserDetailZone .v-slide-group {
    overflow: visible;
	}
	#UserDetailZone .v-tab.v-tab {
		min-width: 106px;
	}
	#UserDetailZone .v-toolbar__content {
		height:32px !important;
	}
	.editSaveButton ,.editCancelButton{
		min-width: 35px;
		padding: 0;
		margin: 0;
		--v-btn-height: 18px;
	}
	.editAddButton, .editDeleteButton{
		min-width: 30px;
		padding: 0;
		margin: 0;
		--v-btn-height: 18px;
	}
	.bg-gray{
		background-color: #f2f2f2 !important;
	}
	.bg-gray_radio{
		background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
	}
	.v-input__details{
		display: none;
	}

	.v-application--wrap {
    min-height: auto;
	}
	.v-field__input,.v-field__append-inner {
		padding:0;
	}
	.v-input,.v-input__control{
		height:23px;
	}
	.v-input__control{
		border-style: solid;
    border-width: 1px;
		border-color: lightgray;
	}
	.v-field,.v-field__input input{
		font-size: small !important;
		border-style: none !important;
	}
	.v-field__clearable{
		padding-top: 1px;
	}
	.mdi-menu-down{
		top:1.5px;
	}
</style>