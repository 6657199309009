<template>
	<div id="userlist_top" class="userlist container">
		<h2>
			<font-awesome-icon icon="fa-user" />&nbsp;ユーザー管理
			<!-- <span id="reportMenu">
				<span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="グループテーブル再読み込み">
					<font-awesome-icon icon="fa-redo-alt" @click="get_data('user','')" />
				</span>
				<span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="一括レポート表示">
					<font-awesome-icon icon="fa-file-lines" @click="ViewCheckedReport()" />
				</span>
				<span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="一括レポート保存">
					<font-awesome-icon icon="fa-cloud-arrow-down" @click="download_Reports()"/>
				</span>
				<span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="一括レポート印刷">
					<font-awesome-icon icon="fa-print" @click="funcprint_allReport()" />
				</span>
			</span> -->
		</h2>
		<p id="result_userlist" v-if="result != ''">{{ result }}</p>
		<br />
		<div>
			<span>取得ユーザー数：{{usercount}}件</span>
			<label for="perPage_user" class="perPage_label">最大表示件数：</label>
			<select name="perPage_user" id="perPage_user" v-model="perPage_user">
				<option value=10>10件</option>
				<option value=100>100件</option>
				<option value=500>500件</option>
				<option value=1000>1000件</option>
			</select>
			<span>
				<button type="button" class="sortClear btn btn-secondary btn-sm" @click.stop="sortClearEvent(userItems,sortKey_list_user,sortOrders_user,'user')">ソートクリア</button>
			</span>
		</div>
		<div class="tableScroll"  v-bind:style="{height:table_height_user}">
			<table id="userTable" class="table table-striped table-bordered VariableTable">
				<thead>
					<draggable @end="set_draggable_toLocal('user')" :list="userItems2" item-key="no" tag="tr">
						<template #item="{ element, index }">
							<th v-bind:id="element.code + '_user'" v-bind:key="index" v-bind:class="'px-2 py-1 align-middle ' + element.class" v-bind:style="{width: table_width_user[element.width]}">
								<div @click="sortEvent(element.code,sortOrders_user,'user')">
									{{ element.name }}
									<span  v-if="index!=0 && index!=userItems2.length-2" class="arrow" :class="sortOrders_user[element.code] > 0 ? 'asc' : sortOrders_user[element.code] < 0 ? 'dsc' : ''"></span>
									<span style="color:rgb(181 66 128);margin-left: 1px;">{{ sortNumber(element.code) }}</span>
								</div>
							</th>
						</template>
					</draggable>
					<tr>
						<th v-bind:class="'condition px-2 py-1 align-middle ' + item.class" v-for="(item,index) in userItems2" v-bind:genre="item.genre" v-bind:key="index">
							<div v-if="item.type==='date'" class="dates">
								<input v-bind:type="item.type" v-model="table_cond_user[item.cond.start]" class="date-form" @change="get_data('user','')">
								<span>～</span>
								<input v-bind:type="item.type" v-model="table_cond_user[item.cond.end]" class="date-form" @change="get_data('user','')">
							</div>
							<div v-else-if="item.type==='checkbox'">
								<input type="checkbox" class="form-check-input" v-model="allCheckFlg_user" @click.stop="checked_all(resultList_user,allCheckFlg_user,'user')">
							</div>
							<div v-else-if="item.type===''"></div>
							<div v-else>
								<input v-bind:type="item.type" v-model="table_cond_user[item.cond]" @change="get_data('user','')">
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="( item, n ) in filteredUser" :key="n" class='groupRow mouse_hover' v-bind:usernumber="n+1"  @click="get_data('detail',$event)">
						<template v-for="(item2) in userItems2" :key="item2">
							<td v-if="item2.code=='checked'" class="cell-checked">
									<div class="form-check">
										<input type="checkbox" class="form-check-input" v-model="item.checked" disabled="disabled">
									</div>
							</td>
							<td v-else-if="item2.code=='listfunction'" class="col-sm-4 px-2 py-1 align-middle cell-function" @click.stop="showFunction(n+1,'user')">
								<font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
								<div class="btn-group-vertical userlist-function-user" v-if="clicknumber_user === n+1">
									<button v-for="(item, i) of olgButtonList_user" :key="i" type="button" class="btn btn-outline-secondary btn-sm" @click.stop="handle_function_call(item.action,$event)">{{ item.name }}</button>
								</div>
							</td>
							<td v-else :class="'col-sm-4 px-2 py-1 align-middle cell-' + item2.code + ' ' + item2.class">
								{{item[item2.code]}}
							</td>
						</template>
					</tr>
				</tbody>
			</table>
		</div>
		<div>
			<paginate
				:page-count="getPageCount"
				:page-range="3"
				:margin-pages="2"
				:click-handler="clickCallback_user"
				:prev-text="'＜'"
				:next-text="'＞'"
				:container-class="'pagination'"
				:page-class="'page-item'">
			</paginate>
		</div>
		<div id="UserDetailZone">
			<UserDetailTemplate v-model:contents="editList_userdetail" v-model:currentnumber="currentnumber" v-model:editFlg="editFlg" v-model:editedFlg="editedFlg" v-model:saveFlg="saveFlg" :oldContents="resultList_userdetail"></UserDetailTemplate>
		</div>
	</div>
</template>


<script>
import axios from "axios";
// import {API_IP, API_PORT} from "../const/const.js"
import draggable from 'vuedraggable'
import Paginate from 'vuejs-paginate-next'
import tableCreate from './mixins/TableCreate'
import UserDetailTemplate from "./UserDetailTemplate.vue"
import _ from 'lodash'

export default {
	name: "userlist_test",
	mixins: [tableCreate],
	components: {
		draggable,
		Paginate,
		UserDetailTemplate,
	},
	data() {
		var userColumns =  [
				{ name: 'ユーザーID', code: 'id',class: '' ,cond:"cond_id",width:"width_id",type:"text"},
				{ name: '氏名', code: 'kanjiname',class: '' ,cond:"cond_kanjiname",width:"width_kanjiname",type:"text"},
				{ name: 'ログイン名', code: 'loginname',class: '' ,cond:"cond_loginname",width:"width_loginname",type:"text"},
				{ name: 'カテゴリー', code: 'user_category',class: '' ,cond:"cond_user_category",width:"width_user_category",type:"number"},
				{ name: '施設名', code: 'hospid',class: '', cond:"cond_hospid",width:"width_hospid",type:"text"},
				{ name: '法人名', code: 'corpid',class: '', cond:"cond_corpid",width:"width_corpid",type:"text"},
				{ name: 'ドメイン名', code: 'domain',class: '', cond:"cond_domain",width:"width_domain",type:"text"},
				{ name: '所属先', code: 'workspace',class: '', cond:"cond_workspace",width:"width_workspace",type:"text"},
				{ name: '職種', code: 'profession',class: '', cond:"cond_profession",width:"width_profession",type:"text"},
				{ name: '登録日時', code: 'verifydatetime',class: '', cond:{start:"cond_verifydatetime_start",end:"cond_verifydatetime_end"},width:"width_verifydatetime",type:"date"},
				{ name: 'グループ管理者', code: 'groupadmin',class: '', cond:"cond_groupadmin",width:"width_groupadmin",type:"text"},
				{ name: 'システム管理者', code: 'sysadmin',class: '', cond:"cond_sysadmin",width:"width_sysadmin",type:"text"},
				{ name: '登録状態', code: 'reg_status',class: '', cond:"cond_reg_status",width:"width_reg_status",type:"text"},
				{ name: '有効', code: 'valid',class: '', cond:"cond_valid",width:"width_valid",type:"text"},
			];
		// TableCreate用プロパティ start-------------------------------------------------------------------------------
		var sortOrders_user = {};
		for (let count = 0; count < userColumns.length; count++) {
			sortOrders_user[userColumns[count]['code']] = 0
		}
		var table_cond_user_common = {
			cond_id:"",
			cond_kanjiname:"",
			cond_loginname:"",
			cond_user_category:"",
			cond_hospid:"",
			cond_corpid:"",
			cond_domain:"",
			cond_workspace:"",
			cond_profession:"",
			cond_verifydatetime_start:"",
			cond_verifydatetime_end:"",
			cond_groupadmin:"",
			cond_sysadmin:"",
			cond_reg_status:"",
			cond_valid:"",
		}
		var table_width_user_common = {
			width_checked:"60px",
			width_id:"100px",
			width_kanjiname:"100px",
			width_loginname:"100px",
			width_user_category:"100px",
			width_hospid:"100px",
			width_corpid:"100px",
			width_domain:"100px",
			width_workspace:"100px",
			width_profession:"100px",
			width_verifydatetime:"100px",
			width_groupadmin:"100px",
			width_sysadmin:"100px",
			width_reg_status:"100px",
			width_valid:"100px",
			width_listfunction:"100px",
		}
		// TableCreate用プロパティ end-------------------------------------------------------------------------------
		return {
			key_orderID:"",
			key_PatientID: "",
			key_status: "",
			result: "",
			// TableCreate用プロパティ start-------------------------------------------------------------------------------
			table_cond_user: {
				cond_ordergroupname:"",
				...table_cond_user_common
						},
			table_width_user: {
				...table_width_user_common,
						},
			table_height_user:"35vh",
			userItems: userColumns,
			userItems2:[
				{ name: '選択', code: 'checked', class: 'checked-item cell-checked',cond:"",width:"width_checked",type:"checkbox"},
				...userColumns,
				{ name: '操作', code: 'listfunction', class: 'cell-function',cond:"",width:"width_listfunction",type:""}
			],
			sortKey: '',
			sortOrders_user: sortOrders_user,
			sortKey_list_user:[],
			// 1ページに表示する件数
			perPage_user: 10,
			perPage_report: 1,
			currentPage_user:1,
			currentPage_report:1,
			resultList_user:{},
			resultList_userdetail:{},
			editList_userdetail:{},
			allCheckFlg_user:false,
			allCheckFlg_order:false,
			selectMaxNum_user:500,
			selectMaxNum_userdetail:500,
			cond_user:{},
			// TableCreate用プロパティ end-------------------------------------------------------------------------------
			usernumber:0,
			usercount:0,
			detailnumber:0,
			currentnumber:1, //ユーザー詳細表示部のページ番号
			ready_check:false,
			userid_list:[],
			postcount:0,
			orderuid:"",
			postcount2:0,
			htmlContent: "",
			orderjsonList: [],
			GroupName: "",
			DoubleReadingMode: "",
			Anonymously: "",
			GroupComment: "",
			Applicant: "",
			InterpretationRequests: "",
			OutPatientHospital: "",
			Departments: "",
			LedgerNumber: "",
			EditedPatientName: "",
			EditedPatientIdeogram: "",
			EditedPatinePhonetic: "",
			BodyPart: "",
			AttendingPhysician: "",
			Protocol: "",
			Operator: "",
			ContrastAgent: "",
			ClinicalInfo: "",
			StudyComments: "",
			OrderComments: "",
			Base64text: "",
			olgButtonList_user: [
				// { name: 'レポート表示', action: "viewReport_user" , class: "viewReport_user" },
				// { name: 'レポート保存', action: "downloadReport_user" , class: "downloadReport_user" },
				// { name: 'レポート印刷', action: "printReport_user" , class: "printReport_user" },
				// { name: 'オーダー取消', action: "orderCancel_user", class: "orderCancel_user" },
				// { name: 'レポートリンク', action: "reportLinkCopy_user", class: "reportLinkCopy_user" },
				// { name: 'キー情報コピー', action: "keyInfoCopy_user", class: "keyInfoCopy_user" },
				// { name: 'オーダー詳細', action: "viewOrderInfo_user", class: "viewOrderInfo_user" },
				{ name: '閉じる', action: "closeFunction_user", class:"closeFunction_user" },
			],
			olgHide: false,
			olgHide_function: false,
			clicknumber_user: 0,
			reportView_orderuidList:[],
			reportJsonList:[],
			paginationFlg : false,
			editFlg:false,
			editedFlg: false,
			saveFlg:false,
		};
	},
	watch:{
		select: function(){
			this.currentPage = 1;
		},
		editFlg(){
			if(!this.editFlg){
				this.editList_userdetail = _.cloneDeep(this.resultList_userdetail);
			}
		},
		saveFlg(){
			if(this.saveFlg){
				this.get_data_user_detail();
			}
		}
	},
	computed: {
		// TableCreate用プロパティ start-------------------------------------------------------------------------------
		// 昇順または降順でデータをソート
		filteredUser: function () {
			// return (arg) => {
				console.log('computed発火')
				return this.sortFunc(this.userItems,this.resultList_user,this.sortKey_list_user,this.sortOrders_user,this.perPage_user,this.currentPage_user,"user");
			// }
		},
		sortNumber: function() {
			const _this = this
			return (arg) => {
				return _this.addSortNumber(arg,"user")
			}
		},
		getPageCount: function() {
			return this.MathCeil(this.resultList_user,this.perPage_user);
		},
		// TableCreate用プロパティ end-------------------------------------------------------------------------------
	},
	methods: {
		clickCallback_user(pageNum) {
			let _this = this
			this.currentPage_user = Number(pageNum);
			this.paginationFlg = true
			setTimeout(() => {_this.changeChecked("detail","")},10)
		},
		sortEvent(code,sortOrders,search_type){
			let genre = ""
			if(search_type == "user"){
				genre = "detail"
			}
			let throughFlg = this.sortBy(code,"","",sortOrders,search_type);
			if(!throughFlg){
				this.changeChecked(genre,"")
			}
		},
		sortClearEvent(Items,sortKey_list,sortOrders,search_type){
			let genre = ""
			if(search_type == "user"){
				genre = "detail"
			}
			this.changeChecked(genre,"")
			this.sortClear(Items,sortKey_list,sortOrders,search_type)
			this.get_data('user','')
		},
		async get_data(search_type,event) {
			let genre = ""
			if(search_type == "user" || search_type == "detail"){
				genre = "detail"
			}
			if(search_type == "user" && event == ""){
				this.paginationFlg = true
			}
			if(search_type == "detail" && this.editedFlg){
				let confirmResult = window.confirm('編集中のデータがあります。\n編集内容を保存せずに操作を続けますか？');
				if(!confirmResult){
					return false;
				}
				else{
					this.editFlg = false;
					this.editedFlg = false;
				}
			}
			this.changeChecked(genre,event)
			// console.log(localStorage);

			if(search_type == "user") {
				this.get_data_user()
			}else if (search_type == "detail"){
				this.get_data_user_detail()
			}
		},
		async get_data_user() {
			let genre = "user"
			let resObj = ""
			let cond = {}
			// TableCreate用プロパティ start-------------------------------------------------------------------------------
			resObj = this.set_conditions(this.userItems,cond,this.perPage_user,this.table_cond_user,genre)
			this.cond_user = resObj.cond
			this.perPage_user = resObj.perPage
			// TableCreate用プロパティ end-------------------------------------------------------------------------------
			await this.select_user()
			await this.set_list_user()
			// TableCreate用プロパティ start-------------------------------------------------------------------------------
			this.set_draggable(genre)
			this.table_height_user = this.set_height(this.table_height_user,"table_height_user",genre,document.querySelector('#' + 'userTable').parentNode)
			this.set_width(this.userItems,this.table_width_user,genre,)
			// TableCreate用プロパティ end-------------------------------------------------------------------------------
			this.resultList_userdetail = {}
			this.editList_userdetail = {}
		},
		async select_user() {
			var _this = this
			console.log("ユーザーリスト検索条件-------------------")
			console.log(_this.cond_user)
			console.log("----------------------------------------")
			await axios
				.post(
					this.$store.state.API_HOST + '/select_userslist/',
					_this.cond_user,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'max': _this.selectMaxNum_user,
							'id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				)
				.then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							_this.usercount = response.data.res.length
							_this.resultList_user = response.data.res
							// let resultList = response.data.res
							// for (let i=0;i < resultList.length;i++){
							//   resultList[i].checked = false
							// }
							// _this.resultList_user = resultList
							console.log(_this.resultList_user);
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.result = "GETエラー";
						console.log(error);
						this.$store.dispatch('logout');
						this.$store.commit('setLoginUser', '')
					}.bind(this)
				);
			return;
		},
		async set_list_user() {
			var _this = this
			var groups = _this.resultList_user
			for( var i=0; i < groups.length; i++ ){
				var group = groups[i]
				for (var key in group) {
					// console.log(key)
					if(key == "insertdatetime" || key == "updatedatetime" || key == "verifydatetime"){
						if(group[key] != null){
							var date = group[key].substr(0,10).replace(/-/g,"/")
							var time = group[key].substr(11,8)
							_this.resultList_user[i][key] = date + " " + time
						}
					}
				}
			}
		},
		async get_data_user_detail() {
			if(this.userid_list.length == 0){
				this.resultList_userdetail = []
				this.editList_userdetail = []
			}else{
				console.log("ユーザー情報詳細取得")
				await this.select_user_detail()
			}
			// await this.set_list_user_detail()
		},
		async select_user_detail() {
			var _this = this
			let formData = new FormData()
			formData.append("useridList",this.userid_list)
			formData.append("max",_this.selectMaxNum_userdetail)
			await axios
				.post(
					this.$store.state.API_HOST + '/select_users_detail',
					formData,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						withCredentials: true
					}
				)
				.then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							_this.currentnumber = 1;
							_this.resultList_userdetail = response.data.res
							_this.editList_userdetail = _.cloneDeep(_this.resultList_userdetail);
							_this.editFlg = false
							// let resultList = response.data.res
							// for (let i=0;i < resultList.length;i++){
							//   resultList[i].checked = false
							// }
							// _this.resultList_user = resultList
							console.log(_this.resultList_userdetail);
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.result = "GETエラー";
						console.log(error);
						this.$store.dispatch('logout');
						this.$store.commit('setLoginUser', '')
					}.bind(this)
				);
			return;
		},
		changeChecked(search_type,event){
			if(event == ""){
				let targetList = ""
				let check = ""
				if(search_type == "detail"  && this.paginationFlg){
					if(document.getElementById("userTable") !== null){
						console.log("オーダーテーブル全削除")
						targetList = document.getElementById("userTable").querySelector('tbody').getElementsByClassName("cell-checked")
						check = false
						this.resultList_userdetail = {}
						this.editList_userdetail = {}
						this.ordercount = 0
						this.userid_list = []
					}
				}
				if(targetList !== ""){
					for( let i=0; i < targetList.length; i++ ){
						targetList[i].querySelector('input').checked = check
					}
				}
			}else{
				var checkeSearch = ""
				checkeSearch = event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked
				if(checkeSearch === true){
					event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked = false
					if(search_type == "detail"){
						this.userid_list = this.userid_list.filter(item => item != event.currentTarget.getElementsByClassName("cell-id")[0].textContent);
					}
				}else{
					if(search_type == "detail"){
						this.userid_list.push(event.currentTarget.getElementsByClassName("cell-id")[0].textContent)
					}
					event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked = true
				}
			}
			console.log("ユーザーIDリスト--------------------")
			console.log(this.userid_list)
			console.log("--------------------------------------------")
			this.paginationFlg = false;
		},
		checked_all(bodyData,allCheckFlg,search_type){
			if(this.editedFlg){
				let confirmResult = window.confirm('編集中のデータがあります。\n編集内容を保存せずに操作を続けますか？');
				if(!confirmResult){
					event.preventDefault();
					return false;
				}else{
					this.editFlg = false;
					this.editedFlg = false;
				}
			}
			if(allCheckFlg){
				allCheckFlg = false
				for( let i=0; i < bodyData.length; i++ ){
					bodyData[i].checked = false
				}
				if(search_type == "user"){
					this.resultList_userdetail = {}
					this.editList_userdetail = {}
					this.ordercount = 0
					this.userid_list = []
					console.log("test")
				}
			}else{
				allCheckFlg = true
				for( let i=0; i < bodyData.length; i++ ){
					bodyData[i].checked = true
				}
				if(search_type == "user"){
					let targetList = document.getElementById("userTable").querySelector('tbody').getElementsByClassName("cell-id")
					for (let i = 0; i < targetList.length; i++) {
						this.userid_list.push(targetList[i].textContent)
					}
					this.get_data_user_detail()
					this.changeChecked("","")
				}
			}
		},
		viewOrderInfo(event){
			var _this = this
			// _this.changeChecked(search_type,event);
			var popup = document.getElementById("orderDetail");
			popup.classList.add('is-show');
			var blackBg = document.getElementById('black-bg-orderDetail');
			var closeBtn = document.getElementById('close-btn-orderDetail');
			closePopUp(blackBg);
			closePopUp(closeBtn);
			function closePopUp(elem) {
				if(!elem) return;
				elem.addEventListener('click', function(event) {
					event.stopPropagation();
					popup.classList.remove('is-show');
					_this.reportJsonList = []
				})
			}
			console.log(_this.orderjsonList)
			var orderJson = _this.orderjsonList[event.currentTarget.closest("tr").getAttribute('ordernumber')-1]
			console.log("オーダーjson")
			console.log(event.currentTarget.getAttribute('ordernumber'))
			console.log(orderJson)
			_this.GroupName = orderJson["OrderGroup"]["Name"]
			_this.DoubleReadingMode = orderJson["OrderGroup"]["Order"]["DoubleReadingMode"]
			if(orderJson["OrderGroup"]["Order"]["Anonymously"] == 1){
				document.getElementById("Anonymously").textContent = "あり"
				// document.getElementById("Anonymously").disabled = true
			}else{
				document.getElementById("Anonymously").textContent = "なし"
			}
			_this.Applicant = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Applicant"]
			_this.InterpretationRequests = orderJson["OrderGroup"]["Order"]["OrderInfo"]["InterpretationRequests"]
			_this.OutPatientHospital = orderJson["OrderGroup"]["Order"]["OrderInfo"]["OutPatientHospital"]
			_this.Departments = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Departments"]
			_this.LedgerNumber = orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"]
			_this.EditedPatientName = orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientName"]
			_this.EditedPatientIdeogram = orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientIdeogram"]
			_this.EditedPatinePhonetic = orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatinePhonetic"]
			_this.AttendingPhysician = orderJson["OrderGroup"]["Order"]["OrderInfo"]["AttendingPhysician"]
			_this.Protocol = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Protocol"]
			_this.Operator = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Operator"]
			_this.ContrastAgent = orderJson["OrderGroup"]["Order"]["OrderInfo"]["ContrastAgent"]
			_this.ClinicalInfo = orderJson["OrderGroup"]["Order"]["OrderInfo"]["ClinicalInfo"]
			_this.StudyComments = orderJson["OrderGroup"]["Order"]["OrderInfo"]["StudyComments"]
			_this.OrderComments = orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderComments"]

			// 伝票
			if(orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] !== ""){
				document.getElementById("preview").setAttribute('src','data:image/png;base64,' + orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"])
			}

			var domHeight = document.getElementById("popup-inner_orderDetail").clientHeight;
			document.getElementById("black-bg-orderDetail").style.height = domHeight + 'px'

		},
		noClick(event) {
			console.log(event);
		},
		showFunction(num,type) {
			var _this = this
			console.log(num)
			_this.clicknumber_user = 0
			_this.clicknumber_order = 0
			if(type == "user"){
				_this.clicknumber_user = num
			}
			if(type == "order"){
				_this.clicknumber_order = num
			}
			// this.resultList[num].olgHide = !this.resultList[num].olgHide
			closePopUp(document.body);
			function closePopUp(elem) {
				if(!elem) return;
				elem.addEventListener('click', function() {
					_this.clicknumber_user = 0
					_this.clicknumber_order = 0;
				})
			}
		},
		closeFunction(){
			var _this = this
			_this.clicknumber_user = 0
			_this.clicknumber_order = 0
		},
		closeFunction_user(){
			var _this = this
			_this.clicknumber_user = 0
			// _this.clicknumber_order = 0
		},
		handle_function_call(function_name,event) {
			var _this = this
			_this[function_name](event);
		},
	},
	mounted: function(){
		var _this = this
		_this.get_data('user','')
	}
};
</script>

<style>
.popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	transition: .6s;
}
.popup {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: max-content;
		min-height: 100%;
		z-index: 9999;
		opacity: 0;
		visibility: hidden;
		transition: .6s;
		overflow: auto; /* ←追記 */
		background-color: rgba(0,0,0,.8);
}

.popup.is-show {
	opacity: 1;
	visibility: visible;
}

.popup-inner {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	max-width: 1000px;
	margin-top: 0.5rem;
		/* padding: 50px; / ←下記に修正 */
	padding: 1rem;
	background-color: #fff;
	z-index: 2;
}

#popup-inner_orderDetail {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 60%;
	margin-top: 0.5rem;
		/* padding: 50px; / ←下記に修正 */
	padding: 1rem;
	background-color: #fff;
	z-index: 2;
}
.popup-inner img {
	width: 100%;
}

.popup-inner input {
	width: 100px;
}

.close-btn {
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
}

.close-btn i {
	font-size: 20px;
	color: #333;
}

.black-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.8);
	z-index: 1;
	/* cursor: pointer; */
}
#result {
		color:red
}

#print {
	width: 150px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 1em;
}

.mouse_hover:hover {
	background-color: rgb(118, 183, 239);
}
#ViewZone textarea{
	vertical-align:top;
}
#ViewZone textarea,#ViewZone input,#ViewZone select{
	pointer-events: none;
	background: #e4e4e4;
}
#orderTable, #userTable {
	width: max-content;
}
.tableScroll {
	/* overflow:hidden;
	overflow-y: scroll; */
	overflow: auto;
	border: solid 1px;
	resize: vertical;
}
#orderSearch span.filter-name {
	display: inline-block;
	width: 7em;
}
.container::-webkit-scrollbar {  
	display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
}
#userTable td,#userTable th {
	width: max-content;
}
#orderTable td,#orderTable th {
	width: max-content;
}
#userTable, #orderTable {
		border-top: solid 1px;
		border-bottom: solid 1px
}
#userTable thead, #orderTable thead {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 3;  
}
.hide {
		display: none;
}
.table-wrapper {
	position: relative;
		width: calc(100% - 1rem);
		height: 20rem;
		overflow: auto;
}
.table-wrapper table {
	width: max-content;
	position: relative;
}
.table-wrapper td, th {
	width: max-content;
}
.table-wrapper th {
	position: sticky;
	top: 0px;
	z-index: 4;
}
.table-wrapper td {
	background-color: #ffffff;
	padding: 0.75rem 0.5rem;
	vertical-align: baseline;
}
.table-wrapper .cell-status, .cell-orderer, .cell-function {
	text-align: center;
}
.table-wrapper .cell-function {
	position: sticky;
	right: 0px;
	font-size: 1.5em;
	cursor: pointer;
	z-index: 2;
}
.table-wrapper .cell-function:hover svg {
	opacity: 0.75;
}
.table-wrapper th.cell-function {
	position: sticky;
	top: 0px;
	font-size: 1em;
	cursor: inherit;
	z-index: 3;
}
td.cell-checked,th.cell-checked {
	position: sticky;
	top: 0px;
	left: 0px;
	vertical-align: middle;
}

td.cell-function,th.cell-function{
	position: sticky;
	top: 0px;
	right: 0px;
	vertical-align: middle;
}
.VariableTable th {
	background-color:#d3d3d3 !important;
}

.VariableTable tr:nth-of-type(odd) td {
	background-color: #eee !important;
}

.VariableTable tr:nth-of-type(even) td {
	background-color: #f2f2f2 !important;
}


.userlist-function-user {
	/* position: absolute; */
	top: 25%;
	/* right: 48px; */
	width: max-content;
	z-index: 3;
	background-color: #ffffff;
}
.userlist-function-user .btn {
	border-radius: 5px;
	color: #3b3b3b;
}
.userlist-function-user .btn:first-child {
	border-top-right-radius: 0;
}
.olgHide, .olgHide_function {
	display: none!important;
}
.page {
	overflow: auto;
}

.VariableTable thead th {
	resize: horizontal;
	overflow: hidden;
}

.VariableTable thead th:not(.condition,.cell-checked,.cell-function) {
	cursor: pointer;
}

.VariableTable thead th input:not(.date-form,.form-check-input){
	width: 100%;
}
.VariableTable thead th .dates{
	width: 100%;
}

.hide {
	display: none;
}

th.active {
	color: #000;
}

th.active .arrow {
	opacity: 1;
}

.arrow {
	display: inline-block;
	vertical-align: middle;
	width: 0;
	height: 0;
	margin-left: 5px;
	opacity: 0.66;
}
.arrow.asc {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid #000;
}
.arrow.dsc {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #000;
}
.perPage_label {
	margin-left: 20px;
}
.sortClear {
	margin-left: 20px;
	margin-bottom: 3px;
}
.pagination {
	margin-top: 5px;
}
.page-link {
	color:#333 !important;
}
.active>.page-link,.page-link.active {
	/* background-color:#44aa34e0 !important; */
	color:#fff !important;
}
.form-check-input{
	background-color: #fff;
	color: inherit;
	/* cursor: not-allowed; */
	opacity: 1 !important;
}
#reportMenu{
	float:right;
	margin-right: 20px;
}
.reportMenuItem {
	margin-right: 20px;
	cursor: pointer;
}
#reportPageNum {
	z-index: 9999;
	position: absolute;
	font-size: 45px;
	left: 5%;
	top: 1%;
}
.reportViewControl, #reportPageNum {
	color:rgb(129 13 143);
}
.reportViewControl {
	font-size:70px;
	font-weight:900;
	position:absolute;
	top: 45%;
	z-index: 9999;
	cursor:pointer;
}

#reportList_forPrint{
		page-break-after: avoid;
}

#result_userlist{
	color: indianred;
}

#userlist_top input:not(.form-check-input),#userlist_top textarea,#userlist_top select {
		background-color: white;
		border-style: solid;
		border-width: 1px;
		-webkit-appearance: searchfield;
}

#print{
	background-color: #e4e4e4 !important;
}

/* #userlist_top button {
		background-color: white;
		border-style: none;
} */

#userlist_top button,#userlist_top input,#userlist_top optgroup,#userlist_top select,#userlist_top textarea {
		font: initial;
}

</style>
