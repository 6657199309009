<template>
	<div class="container">
    <div style="display:flex; flex-wrap: wrap; justify-content: space-between;">
      <h2><font-awesome-icon icon="user-edit" />&nbsp;{{ title }}</h2>
      <button class="btn btn-primary" @click="OpenModal()" style="margin-right:20px;">パスワード変更</button>
    </div>

    <!-- パスワード変更ポップアップ -->
    <div id="overlay_passwordChange">
      <div class="popup_passwordChange">
        <div @click="CloseModal()" style="float: right;cursor: pointer; margin-right: 10px;font-size: 20px;">&#10006;</div> <!-- Shows Close Icon -->
        <h3 class="ms-3">パスワード変更</h3>

        <div class="ms-5 mt-5">

          <div>
            <label for="oldPassword" class="col-sm-6 col-form-label">現在のパスワード</label>
            <div class="col-sm-10 mb-2">
              <form>
                <div class="form-wrapper">
                  <input v-bind:type="buttonEye1_type" id="oldPassword" name="oldPassword" v-model="oldPassword" class="form-control">
                  <span id="buttonEye1" @click="pushHideButtons_cp($event)" v-if="buttonEye1_flg === true"><font-awesome-icon icon="fa-solid fa-eye" class="passwordEye" /></span>
                  <span id="buttonEye1_slash" @click="pushHideButtons_cp($event)" v-else><font-awesome-icon icon="fa fa-eye-slash"  class="passwordEye" /></span>
                </div>
              </form>
            </div>
          </div>

          <div>
            <label for="newPassword" class="col-sm-6 col-form-label">新しいパスワード</label>
            <div class="col-sm-10 mb-2">
              <form>
                <div class="form-wrapper">
                  <input v-bind:type="buttonEye2_type" id="newPassword" name="newPassword" v-model="newPassword" class="form-control">
                  <span id="buttonEye2" @click="pushHideButtons_cp($event)" v-if="buttonEye2_flg === true"><font-awesome-icon icon="fa-solid fa-eye" class="passwordEye" /></span>
                  <span id="buttonEye2_slash" @click="pushHideButtons_cp($event)" v-else><font-awesome-icon icon="fa fa-eye-slash"  class="passwordEye" /></span>
                </div>
              </form>
            </div>
          </div>
          <div>
            <label for="newPassword_confirm" class="col-sm-6 col-form-label">新しいパスワードの確認</label>
            <div class="col-sm-10 mb-2">
              <form>
                <div class="form-wrapper">
                  <input v-bind:type="buttonEye3_type" id="newPassword_confirm" name="newPassword_confirm" v-model="newPassword_confirm" class="form-control">
                  <span id="buttonEye3" @click="pushHideButtons_cp($event)" v-if="buttonEye3_flg === true"><font-awesome-icon icon="fa-solid fa-eye" class="passwordEye" /></span>
                  <span id="buttonEye3_slash" @click="pushHideButtons_cp($event)" v-else><font-awesome-icon icon="fa fa-eye-slash"  class="passwordEye" /></span>
                </div>
              </form>
            </div>
          </div>

        </div>

        <div class="ms-5 mt-5">
          <button class="btn btn-primary" @click="changePassword()">変更</button>
          <button class="btn btn-primary ms-3" @click="CloseModal()">キャンセル</button>
        </div>
        <p id="message_cp" class="ms-3 mt-3">{{ message_cp }}</p>
      </div>
    </div>

    <div style="color:red;">※のついた項目は必ず入力してください。</div>
		<div id="ProfileForm" class="container">
			<div class="row mb-2">
				<label for="id" class="col-sm-2 col-form-label">ユーザー番号：</label>
				<div class="col-sm-10"><input type="text" id="id" name="id" v-model="id" class="form-control-plaintext" readonly></div>
			</div>
			<div class="row mb-2">
				<label for="loginname" class="col-sm-2 col-form-label">
          <span style="color:red;">※</span>
          ユーザー名：
        </label>
				<div class="col-sm-10"><input type="text" id="loginname" name="loginname" v-model="loginname" class="form-control"></div>
			</div>
				<input id="oldloginname" name="oldloginname" v-model="oldloginname" class="form-control" style="display:none;">
			<div class="row mb-2">
				<label for="kanjiname" class="col-sm-2 col-form-label">漢字名：</label>
				<div class="col-sm-10"><input type="text" id="kanjiname" name="kanjiname" v-model="kanjiname" class="form-control" @change="inputCheck($event,'ZenkakuKanji')"></div>
        <span class="errorMessage" style="color:red;margin-left:10px;width: auto;margin-top: 10px;"></span>
			</div>
			<div class="row mb-2">
				<label for="kananame" class="col-sm-2 col-form-label">カナ名：</label>
				<div class="col-sm-10"><input type="text" id="kananame" name="kananame" v-model="kananame" class="form-control" @change="inputCheck($event,'ZenkakuKana')"></div>
        <span class="errorMessage" style="color:red;margin-left:10px;width: auto;margin-top: 10px;"></span>
			</div>
			<div class="row mb-2">
				<label for="email" class="col-sm-2 col-form-label">
          <span style="color:red;">※</span>
          メールアドレス：
        </label>
				<div class="col-sm-10"><input type="email" id="email" name="email" v-model="email" class="form-control"></div>
			</div>
			<div class="row mb-2 align-items-center">
				<label class="col-sm-2 col-form-label">契約カテゴリー：</label>
				<div class="col-sm-10 usercategory">
					<div class="form-check form-check-inline"><label class="mr-2"><input type="radio" name="user_category" v-model="user_category" value=1 class="form-check-input">個人ユーザー</label></div>
					<div class="form-check form-check-inline"><label class="mr-2"><input type="radio" name="user_category" v-model="user_category" value=2 class="form-check-input">施設ユーザー</label></div>
					<div class="form-check form-check-inline"><label class="mr-2"><input type="radio" name="user_category" v-model="user_category" value=9 class="form-check-input">その他</label></div>
				</div>
			</div>
			<div v-if="user_category == '2'" class="row mb-2">
				<label for="hospname" class="col-sm-2 col-form-label">
          <span style="color:red;">※</span>
          施設名：
        </label>
				<div class="col-sm-10"><input id="hospname" name="hospname" v-model="hospname" class="form-control"></div>
			</div>
      <div v-if="user_category == '2'" class="row mb-2">
				<label for="hospname_kana" class="col-sm-2 col-form-label">
          <span style="color:red;">※</span>
          施設名（カナ）：
        </label>
				<div class="col-sm-10">
          <input id="hospname_kana" name="hospname_kana" v-model="hospname_kana" class="form-control" @change="inputCheck($event,'ZenkakuKana')">
        </div>
        <span class="errorMessage" style="color:red;margin-left:10px;width: auto;margin-top: 10px;"></span>
			</div>
			<div v-if="user_category == '2'" class="row mb-2">
				<label for="corpname" class="col-sm-2 col-form-label">法人名：</label>
				<div class="col-sm-10"><input id="corpname" name="corpname" v-model="corpname" class="form-control"></div>
			</div>
      <div v-if="user_category == '2'" class="row mb-2">
				<label for="corpname_kana" class="col-sm-2 col-form-label">法人名（カナ）：</label>
				<div class="col-sm-10">
          <input id="corpname_kana" name="corpname_kana" v-model="corpname_kana" class="form-control" @change="inputCheck($event,'ZenkakuKana')">
        </div>
        <span class="errorMessage" style="color:red;margin-left:10px;width: auto;margin-top: 10px;"></span>
			</div>
			<fieldset class="row mb-2">
				<label for="profession" class="col-sm-2 col-form-label">職種：</label>
				<div class="col-sm-10">
					<select id="profession" name="profession" v-model="profession" class="form-select">
						<option value=1>医師</option>
						<option value=2>放射線技師</option>
						<option value=9>その他</option>
					</select>
				</div>
			</fieldset>
			<div class="row mb-2 align-items-center">
				<label for="groupadmin" class="col-sm-2 col-form-label">Grp管理者権限：</label>
				<div class="col-sm-10 form-check form-switch">
					<label><input type="checkbox" id="groupadmin" name="groupadmin" v-model="groupadmin" value=1 class="form-check-input">&nbsp;{{ groupadmin ? 'あり' : 'なし' }}</label>
				</div>
			</div>
			<div class="row mb-2">
				<label for="contracted" class="col-sm-2 col-form-label">契約状況：</label>
				<div class="col-sm-10"><input id="contracted" name="contracted" v-model="contracted" readonly class="form-control-plaintext"></div>
			</div>
			<div class="row mb-2">
				<label for="contract_id" class="col-sm-2 col-form-label">契約番号：</label>
				<div class="col-sm-10"><input id="contract_id" name="contract_id" v-model="contract_id" readonly class="form-control-plaintext"></div>
			</div>
			<div class="row mb-2">
				<label for="reg_status" class="col-sm-2 col-form-label">登録ステータス：</label>
				<div class="col-sm-10"><input id="reg_status" name="reg_status" v-model="reg_status" readonly class="form-control-plaintext"></div>
			</div>
			<button class="btn btn-primary" @click="update">更新</button>
			<p id="message">{{ message }}</p>
		</div>
	</div>
</template>

<script>
// import axios from "axios";
// import crypto from 'crypto-js';
import axios from "axios";
// import {API_IP, API_PORT} from "../../public/const.json"
// import ce from "./checkExpired"
import crypto from 'crypto-js';

export default {
  data(){
    return{
      title: "プロフィール編集",
      message: "",
      message_cp: "",
      id: "",
      loginname: "",
      oldloginname: "",
      kanjiname: "",
      kananame: "",
      email: "",
      user_category: "",
      hospid:"",
      corpid:"",
      hospname: "",
      hospname_kana: "",
      corpname: "",
      corpname_kana: "",
      profession: "",
      groupadmin: false,
      contracted: "",
      contract_id: "",
      reg_status: "",
      ready_check: false,
      resultList_user:{},
      resultList_hosp:{},
      resultList_corp:{},
      oldPassword: "",
      newPassword: "",
      newPassword_confirm: "",
      buttonEye1_type:"password",
      buttonEye2_type:"password",
      buttonEye3_type:"password",
      buttonEye1_flg:true,
      buttonEye2_flg:true,
      buttonEye3_flg:true,
      NGlist:{},
    }
  },
  methods:{
    async get_data() {
      var _this = this
      console.log(localStorage);
      _this.get_data_user();
    },
    async get_data_user() {
      await this.select_user()
      this.set_list_group()
    },
    async select_user(){
      let _this = this;
      await axios.post(this.$store.state.API_HOST + '/select_user',
        null, // nullまたは空オブジェクトを送信
        {
          params: {
            app_name: _this.$store.state.APP_NAME,
            id: _this.$store.state.userID,
          },
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      ).then(
        function(response) {
          if(typeof(response.data) != "string"){
            this.resultList_user = response.data.res_user
            this.resultList_hosp = response.data.res_hosp
            this.resultList_corp = response.data.res_corp
          }else{
            _this.$store.dispatch('logout');
            _this.$store.commit('setLoginUser', '')
          }
        }.bind(this)
      )
      .catch(
          function(error) {
            this.result = "GETエラー";
            console.log(error);
          }.bind(this)
      );
      return ""
    },
    set_list_group() {
      var _this = this
      var userInfo = _this.resultList_user[0]
      for (var key in userInfo) {
        console.log(key + ":" + userInfo[key])
        var target =  _this[key]
        if(target !== null){
          if(key == "reg_status"){
            switch(userInfo[key]){
              case 0:
                _this[key] = "仮登録済み"
                break;
              case 1:
                _this[key] = "本登録済"
                break;
              case 9:
                _this[key] = "休止"
                break;
              default:
                _this[key] = ""
            }
          }else if(key == "hospid"){
            if(_this.resultList_hosp != ""){
              _this[key] = userInfo[key]
              _this.hospname = _this.resultList_hosp[0]["name"]
              _this.hospname_kana = _this.resultList_hosp[0]["name_kana"]
            }
          }else if(key == "corpid"){
            if(_this.resultList_corp != ""){
              _this[key] = userInfo[key]
              _this.corpname = _this.resultList_corp[0]["name"]
              _this.corpname_kana = _this.resultList_corp[0]["name_kana"]
            }
          }else{
            _this[key] = userInfo[key]
          }
          if(key == "loginname"){
            _this.oldloginname = userInfo[key]
          }
        }
      }
    },
    async update(){
      var _this = this
      // 必須項目チェック
      if(this.loginname !== "" && this.email !== "" && (this.user_category != "2" || (this.user_category == "2" && this.hospname != "" && this.hospname_kana != ""))){
        // 漢字名、カナ名入力チェックNGがない場合
        if(Object.keys(this.NGlist).length == 0){
          let data = {'id': this.id,'loginname': this.loginname,'oldloginname': this.oldloginname}
          if(this.kanjiname == null || this.kanjiname == ""){
            data['kanjiname'] =  "-";
          }else{
            data['kanjiname'] = this.kanjiname;
          }
          if(this.kananame == null || this.kananame == ""){
            data['kananame'] =  "-";
          }else{
            data['kananame'] = this.kananame;
          }
          data['email'] = this.email;

          // 選択されたユーザーカテゴリー取得
          let elements = document.getElementsByName('user_category');
          let len = elements.length;
          let checkValue = '';
          for (let i = 0; i < len; i++){
              if (elements[i].checked){
                  checkValue = elements[i].value;
              }
          }
          data['user_category'] = checkValue;
          data['hospid'] = this.hospid
          data['corpid'] = this.corpid
          if(this.hospname == null || this.hospname == ""){
            data['hospname'] =  "-";
          }else{
            data['hospname'] = this.hospname;
          }
          if(this.hospname_kana == null || this.hospname_kana == ""){
            data['hospname_kana'] =  "-";
          }else{
            data['hospname_kana'] = this.hospname_kana;
          }
          console.log("施設名（カナ）：" + this.hospname_kana)
          if(this.corpname == null || this.corpname == ""){
            data['corpname'] =  "-";
          }else{
            data['corpname'] = this.corpname;
          }
          if(this.corpname_kana == null || this.corpname_kana == ""){
            data['corpname_kana'] =  "-";
          }else{
            data['corpname_kana'] = this.corpname_kana;
          }
          if(this.profession == null || this.profession == ""){
            data['profession'] =  "-";
          }else{
            data['profession'] = document.getElementById('profession').value;
          }
          if(document.getElementById("groupadmin").checked){
            data['groupadmin'] =  1;
          }else{
            data['groupadmin'] = 0;
          }

          // // フォームデータの中身確認
          // for (let value of params.entries()) {
          //     console.log(value);
          // }

          this.$store.dispatch('update',data);
          setTimeout(() =>{
            console.log(this.$store.getters.registerResult)
            if(this.$store.getters.registerResult != 0 && this.$store.getters.registerResult != -1 && parseInt(this.$store.getters.registerResult)){
                _this.message = "登録完了しました。"
                _this.get_data()
            }else if (this.$store.getters.registerResult == 0){
                _this.message = "入力されたユーザーはすでに登録されています。"
            }else{
                _this.message = "登録に失敗しました。"
            }
          },1000);
        }else{
          _this.message = "入力に誤りのある項目があります。"
        }
      }else{
        _this.message = "必須項目の入力を行ってください。"
      }
    },
    OpenModal() {
      let element = document.getElementById('overlay_passwordChange')
      element.style.display = 'block'
      this.oldPassword = ""
      this.newPassword = ""
      this.newPassword_confirm = ""
      this.buttonEye1_type = "password"
      this.buttonEye2_type = "password"
      this.buttonEye3_type = "password"
      this.buttonEye1_flg = true
      this.buttonEye2_flg = true
      this.buttonEye3_flg = true
      this.message_cp = ""
    },
    CloseModal() {
      let element = document.getElementById('overlay_passwordChange')
      element.style.display = 'none'
      this.oldPassword = ""
      this.newPassword = ""
      this.newPassword_confirm = ""
    },
    async changePassword(){
      if(this.newPassword === this.newPassword_confirm){
        if(this.newPassword !== ""){
          if(this.newPassword !== this.oldPassword){
            var _this = this
            let data = {'id': localStorage.getItem('userID'),}
            // パスワード暗号化
            let hash_old = crypto.SHA3(this.oldPassword);
            data['oldPassword'] = hash_old.toString();
            let hash_new = crypto.SHA3(this.newPassword);
            data['newPassword'] = hash_new.toString();
            this.$store.dispatch('update_password',data);
            setTimeout(() =>{
              console.log(this.$store.getters.registerResult)
              if(this.$store.getters.registerResult != 0 && this.$store.getters.registerResult != -1 && parseInt(this.$store.getters.registerResult)){
                  _this.message_cp = "パスワードが変更されました。"
                  setTimeout(() =>{
                    _this.CloseModal()
                  },1500)
              }else if (this.$store.getters.registerResult == 0){
                  _this.message_cp = "入力された現在のパスワードは使用されていません。"
              }else{
                  _this.message_cp = "パスワード変更が失敗しました。"
              }
            },1000);
          }else{
            this.message_cp = "パスワードが変更されていません。"
          }
        }else{
          this.message_cp = "新しいパスワードを入力してください。"
        }
      }else{
        this.message_cp = "パスワードが一致していません。再度ご確認ください。"
      }
    },
    pushHideButtons_cp(event) {
			var targetId = event.currentTarget.id
			if (targetId.indexOf('_slash') != -1) {
				this[targetId.replace("_slash","_flg")] = true
        this[targetId.replace("_slash","_type")] = "password"
			} else {
				this[targetId + "_flg"] = false
        this[targetId + "_type"] = "text"
			}
    },
    inputCheck(event,inputcheck){
				var str = event.currentTarget.value;
        var eleID = event.currentTarget.id;
				var NGflg = false
        let pattern = ""
				if(str != ""){
					switch (inputcheck){
						case "Hankaku":
							pattern = /[A-Z|a-z|0-9]/;
							break;
						case "HankakuOmoji":
							pattern = /[A-Z|0-9]/;
							break;
						case "ZenkakuKanji":
							pattern = /([\s+\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
							break;
						case "ZenkakuKana":
							pattern = /[\s+\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]/mu;
							break;
						case "yubinbango":
							pattern = /[0-9]/;
					}
				}
        var reg = new RegExp(pattern)
        var strArray = str.split("")
        for (let i=0;i<strArray.length;i++){
          if(!strArray[i].match(reg) && str != ""){
            NGflg = true
          }
        }
				if(!NGflg){
					// editedstr += strArray[i]
					console.log(eleID + "：入力問題なし")
					event.currentTarget.style.backgroundColor = null
					if(this.NGlist[eleID] >= 1){
						delete this.NGlist[eleID]
					}
					event.currentTarget.parentNode.parentNode.querySelector(".errorMessage").textContent = ""
				}else{
					event.currentTarget.style.backgroundColor = "red"
          this.NGlist[eleID] = 1
          event.currentTarget.parentNode.parentNode.querySelector(".errorMessage").textContent = "入力に誤りがあります。"
				}
			},
  },
  mounted: function(){
      this.get_data()
  }
}
</script>

<style scoped>
div.form-check.form-switch {
	padding-left: 3.5rem;
}

#overlay_passwordChange {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.popup_passwordChange {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
  /* text-align: center; */
  background: white;
}
.popup_passwordChange h3 {
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  background: white;
}
#message_cp {
  color:red;
}
#ProfileForm .col-form-label {
  width:20%;
}
#ProfileForm .col-sm-10 {
  width:500px;
}
#ProfileForm .mb-2 {
  display: flex;
}
</style>