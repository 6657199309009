<template>
	<div ref="menu" class="main-menu" :class="{close: isClose}">
		<div class="close-button" @click="toggleMenu" :style="{ transform: `rotate( ${btnRotation} )` }">
			<font-awesome-icon icon="square-caret-left" />
		</div>
		<div v-show="!isClose" class="menu-title">MENU</div>
		<aside id="sidebar">
			<nav id="global-nav">
				<ul class="mainlist">
					<li v-for="(item, i) of menuItems" :key="i" class="sub-menu" v-on:mouseover="openSubmenu(i)" v-on:mouseleave="closeSubmenu(i)">
						<div v-if="item.href">
							<router-link :to="item.href" class="nav-link" data-bs-toggle="tooltip" data-bs-placement="left" v-bind:title="item.name">
								<span class="list-icon"><font-awesome-icon :icon="item.icon" /></span><span v-show="!isClose">&nbsp;{{ item.name }}</span>
							</router-link>
						</div>
						<div v-else class="subitems">
							<span class="list-icon" data-bs-toggle="tooltip" data-bs-placement="left" v-bind:title="item.name"><font-awesome-icon :icon="item.icon" /></span><span v-show="!isClose">&nbsp;{{ item.name }}</span>
							<ul class="sub-menu-nav" v-show="isOpen_submenu && i === hoverIndex">
								<li v-for="(subitem, j) of item.subItems" :key="j" class="nav-item">
									<router-link :to="subitem.href" class="nav-link">
										<span class="list-icon"><font-awesome-icon :icon="subitem.icon" /></span>&nbsp;{{ subitem.name }}
									</router-link>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</nav>
		</aside>
		<div id="app_version">バージョン：{{ version }}</div>
	</div>
</template>

<script>
export default {
    props: {
        menuItems: Array,
    },
    data() {
        return {
            isClose: false,
			isOpen_submenu: false,
            btnRotation: '270deg',
			version: "1.2.1",
        }
    },
    methods: {
        toggleMenu: function() {
            this.isClose = !this.isClose
            if( this.btnRotation === '270deg' ) {
                this.btnRotation = '180deg'
            } else {
                this.btnRotation = '270deg'
            }
        },
		openSubmenu: function(index) {
			this.isOpen_submenu = true;
			this.hoverIndex = index
		},
		closeSubmenu: function() {
			this.isOpen_submenu = false;
		}
    },
}
</script>

<style scoped>
.main-menu {
	position: relative;
	height: 100%;
	width: auto;
	background-color: #44aa34e0;
	border-radius: 1rem;
	box-shadow: -0.5rem -0.5rem 0.2rem rgb(255, 255, 255), 0.25rem 0.25rem 0.75rem rgba(0,0,0,0.5);
	transition: all 0.5s ease;
	z-index: 999;
}
.main-menu.close {
    width: 4rem;
    /* overflow: hidden; */
}
.main-menu.close ul.nav {
    width: max-content;
}

.main-menu div.menu-title {
	position: absolute;
	top: 0.75rem;
	left: 2rem;
	color: #ffffff;
}

.nav {
    flex-direction: column;
	font-size: 0.91rem;
}

.nav-item {
    margin: 0.5em 0;
	text-align: left;
}
.nav-item .list-icon {
    display: inline-block;
    width: 1.5em;
    margin-right: 0rem;
    text-align: center;
}

.nav-link {
    padding: 0;
	text-decoration: none;
	color: #fff;
	padding: 0 0.75em;
}
.nav-link:hover {
    color: rgb(237, 183, 167);
}

.router-link-active.nav-link {
    border-bottom: solid 2px #0d6efd;
}

.close-button {
    display: flex;
    justify-content: center;
    align-content: center;
	width: 1.25rem;
	margin-bottom: 0.5rem;
	text-align: center;
	font-size: 1.25rem;
	transform: rotate(270deg);
	color: #ffffff;
	transition: all 0.3s ease;
	padding: 0.75em;
    /* padding-bottom: 0.1em; */
	transform-origin:center center;
}

.subitems {
	cursor :default;
	color: #fff;
	padding: 0 0.75em;
}

.sub-menu {
	margin: 0.5em 0;
}

.mainlist {
	list-style: none;
	padding: 0;
	margin: 0;
}

#global-nav .sub-menu-nav {
	position: absolute;
    margin-top: -30px;
    left: 100%;
    width: 250px !important;
    background: #44aa34e0;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
	list-style: none;
	padding-left: 0;
}
#global-nav .sub-menu:hover .sub-menu-nav {
    width: 230px;
	border-radius: 1rem;
}

.sub-menu-nav li {
	position: relative;
}


/* #global-nav .sub-menu > a:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 18px;
	margin: auto;
	vertical-align: middle;
	width: 8px;
	height: 8px;
	border-top: 1px solid #033560;
	border-right: 1px solid #033560;
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
} */

.subitems:after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0.5em 0 0 10px;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    /* -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); */
	border-top: 8px solid #fff;
	border-left: 8px solid transparent;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	float:right;
}

#app_version{
	position: fixed;
    bottom: 25px;
	color: #fff;
	margin-left: 10px;
}

</style>