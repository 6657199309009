<template>
  <div id="announcement-editor">
    <div style="display: flex;">
      <h2><font-awesome-icon icon="fa-bullhorn" />&nbsp;{{ page_title }}</h2>
      <!-- <div class="switchArea">
        <input type="checkbox" id="switch1" v-model="switchFlg">
        <label for="switch1"><span></span></label>
        <div id="swImg"></div>
      </div> -->
      <button class="createAnnouncement btn btn-primary" @click="showModalFlg = true;">新規作成</button>
    </div>

    <!-- 編集・削除画面 -->
    <div v-show="!switchFlg" style="margin-top: 15px;">
      <div>
        <span class="announce_reload" data-bs-toggle="tooltip" data-bs-placement="top" title="アナウンステーブル再読み込み">
          <font-awesome-icon icon="fa-redo-alt" @click="get_data('announce','')" style="font-size: 24px;" />
        </span>
        <span>取得お知らせ件 数：{{announceCount}}件</span>
        <label for="perPage_announce" class="perPage_label">最大表示件数：</label>
        <select name="perPage_announce" id="perPage_announce" v-model="perPage_announce">
          <!-- <option value=1>1件</option> -->
          <option value=10>10件</option>
          <option value=100>100件</option>
          <option value=500>500件</option>
          <option value=1000>1000件</option>
        </select>
        <span>
          <button type="button" class="sortClear btn btn-secondary btn-sm" @click.stop="sortClearEvent(announceItems,sortKey_list_announce,sortOrders_announce,'announce')">ソートクリア</button>
          <button type="button" class="btn btn-secondary btn-sm" @click="deleteAnnouncement_multiple" style="margin-left: 10px; margin-bottom: 3px;">まとめて削除</button>
        </span>
      </div>
      <div class="tableScroll"  v-bind:style="{height:table_height_announce}">
        <table id="announceTable" class="table table-striped table-bordered VariableTable">
          <thead>
            <draggable @end="set_draggable_toLocal('announce')" :list="announceItems2" item-key="no" tag="tr">
              <template #item="{ element, index }">
                <th v-bind:id="element.code + '_announce'" v-bind:key="index" v-bind:class="'px-2 py-1 align-middle ' + element.class" v-bind:style="{width: table_width_announce[element.width]}">
                  <div @click="sortEvent(element.code,sortOrders_announce,'announce')">
                    {{ element.name }}
                    <span  v-if="index!=0 && index!=announceItems2.length-2" class="arrow" :class="sortOrders_announce[element.code] > 0 ? 'asc' : sortOrders_announce[element.code] < 0 ? 'dsc' : ''"></span>
                    <span style="color:rgb(181 66 128);margin-left: 1px;">{{ sortNumber(element.code) }}</span>
                  </div>
                </th>
              </template>
            </draggable>
            <tr>
              <th v-bind:class="'condition px-2 py-1 align-middle ' + item.class" v-for="(item,index) in announceItems2" v-bind:genre="item.genre" v-bind:key="index">
                <div v-if="item.type==='date'" class="dates">
                  <input v-bind:type="item.type" v-model="table_cond_announce[item.cond.start]" class="date-form" @change="get_data">
                  <span>～</span>
                  <input v-bind:type="item.type" v-model="table_cond_announce[item.cond.end]" class="date-form" @change="get_data">
                </div>
                <div v-else-if="item.type==='checkbox'">
                  <input type="checkbox" class="form-check-input" v-model="allCheckFlg_announce" @click.stop="checked_all(resultList_announce,allCheckFlg_announce,'announce')">
                </div>
                <div v-else-if="item.type==='select'">
                  <select v-if="item.code == 'domain_id' || item.code == 'user_id'" v-model="table_cond_announce[item.cond]" @change="get_data">
                    <!-- <option value=1>1件</option> -->
                    <option value=""></option>
                    <option v-for="(item2, j) of this[item.code + '_list']" :key="j" :value=splitName_start(item2)>{{ item2 }}</option>
                  </select>

                  <select v-else v-model="table_cond_announce[item.cond]" @change="get_data">
                    <!-- <option value=1>1件</option> -->
                    <option value=""></option>
                    <option v-for="(item2, j) of this[item.code + '_radio_list']" :key="j" :value=item2.value>{{ item2.name }}</option>
                  </select>
                </div>
                <div v-else-if="item.type===''"></div>
                <div v-else>
                  <input v-bind:type="item.type" v-model="table_cond_announce[item.cond]" @change="get_data">
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="( item, n ) in filteredAnnounce" :key="n" class='groupRow mouse_hover' v-bind:announcenumber="n+1" @click="changeChecked('announce',$event)">
              <template v-for="(item2) in announceItems2" :key="item2">
                <td v-if="item2.code=='checked'" class="cell-checked">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" v-model="item.checked" disabled="disabled">
                    </div>
                </td>
                <td v-else-if="item2.code=='listfunction'" class="col-sm-4 px-2 py-1 align-middle cell-function" @click.stop="showFunction(n+1,'announce')" :style="{'text-align': 'center', 'z-index': clicknumber_announce === n+1 ? 2 : 'auto'}">
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                  <div class="btn-group-vertical userlist-function-user" v-if="clicknumber_announce === n+1" :style="{'position': 'absolute', 'right': '20px', 'z-index': clicknumber_announce === n+1 ? 1 : 'auto', 'important': '1'}">
                    <button v-for="(item, i) of olgButtonList_announce" :key="i" type="button" class="btn btn-outline-secondary btn-sm" @click.stop="handle_function_call(item.action,$event)">{{ item.name }}</button>
                  </div>
                </td>
                <td v-else :class="'col-sm-4 px-2 py-1 align-middle cell-' + item2.code + ' ' + item2.class">
                  {{item[item2.code]}}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <paginate
          :page-count="getPageCount"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback_announce"
          :prev-text="'＜'"
          :next-text="'＞'"
          :container-class="'pagination'"
          :page-class="'page-item'">
        </paginate>
      </div>

      <!-- 編集画面 -->
      <div v-if="editTargetSEQ != 0" style="margin-top: 50px;">
        <div style="display: flex;">
          <div style="margin-bottom: 10px;">SEQ:{{editTargetSEQ}}</div>
          <button class="btn btn-primary" @click="deleteAnnouncement" style="margin-left: auto;">削除</button>
        </div>
        <div class="title-editor">
          <label for="title">タイトル:</label>
          <input type="text" id="title" v-model="announcement_created.title" class="title-input" />
        </div>
        <div class="content-editor">
          <label for="content">詳細内容:</label>
          <vue-editor v-model="announcement_created.content" :options="editorOptions"></vue-editor>
        </div>
        <button id="updateAnnouncement" class="btn btn-primary" @click="updateAnnouncement">更新</button>
        <button id="stopeditannounce" class="btn btn-primary" @click="editAnnouncement_stop" style="margin-left: 20px;">戻る</button>
      </div>
      <div id="editmessage">{{ editmessage }}</div>

    </div>

    <!-- 新規作成画面 -->
    <div v-show="showModalFlg" class="modal">
        <div class="modal-content" @click.stop>
            <div>
              <div class="title-editor">
                  <label for="title">タイトル:</label>
                  <input type="text" id="title" v-model="announcement.title" class="title-input" />
                </div>
                <div class="content-editor">
                  <label for="content">詳細内容:</label>
                  <vue-editor v-model="announcement.content" :options="editorOptions"></vue-editor>
                </div>
                <div class="target-radio edit-row">
                  <label>配信先:</label>
                  <label class="radio-option" v-for="(item, i) of target_radio_list" :key="i">
                    <input type="radio" v-model="announcement.target" :value=item.value /> {{ item.name }}
                  </label>
                </div>
                <div v-if="announcement.target === 'order'" class="type-radio edit-row">
                  <label>配信タイプ:</label>
                  <label class="radio-option" v-for="(item, i) of type_radio_list" :key="i">
                    <input type="radio" v-model="announcement.type" :value=item.value /> {{ item.name }}
                  </label>
                </div>
                <div v-if="announcement.type === '1'" class="specified-recipient edit-rows">
                  <div class="domain-radio">
                    <label for="domain_id" class="label-with-input">ドメイン:</label>
                    <span id="domain_id" style="display: inline-flex; width:250px;">
                      <v-combobox
                        v-bind:items="domain_id_list"
                        v-model="announcement.domain_id"
                        variant="solo"
                        @input="checkInput"
                        :disabled="!isDomainIdEditable"
                        :class="{ 'disabled-input': !isDomainIdEditable }"
                      ></v-combobox>
                    </span>
                  </div>
                  <div class="user-radio edit-row">
                    <label for="user_id" class="label-with-input">ユーザー:</label>
                    <span id="user_id" style="display: inline-flex; width:250px;">
                      <v-combobox
                        v-bind:items="user_id_list"
                        v-model="user_id_disp"
                        variant="solo"
                        @input="checkInput"
                        :disabled="!isUserIdEditable"
                        :class="{ 'disabled-input': !isUserIdEditable }"
                      ></v-combobox>
                    </span>
                  </div>
                </div>
                <div class="severity-radio edit-row">
                  <label>重要度:</label>
                  <!-- 重要度のラジオボタン -->
                  <label class="radio-option" v-for="(item, i) of severity_radio_list" :key="i">
                    <input type="radio" v-model="announcement.severity" :value=item.value />{{ item.name }}
                  </label>
                </div>
                <div class="must-respond-radio edit-row">
                  <label>要回答:</label>
                  <!-- 要回答のラジオボタン -->
                  <label class="radio-option" v-for="(item, i) of must_respond_radio_list" :key="i">
                    <input type="radio" v-model="announcement.must_respond" :value=item.value />{{ item.name }}
                  </label>
                </div>
                <button id="delivery" class="btn btn-primary" @click="createAnnouncement">配信</button>
                <button id="cancel" class="btn btn-primary" @click="createAnnouncement_stop">戻る</button>
                <div id="message">{{ message }}</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue3-editor";
import draggable from 'vuedraggable'
import Paginate from 'vuejs-paginate-next'
import tableCreate from './mixins/TableCreate'

export default {
  mixins: [tableCreate],
  components: {
    VueEditor,
    draggable,
		Paginate,
  },
  data() {
    var announceColumns = [
      { name: '更新日時', code: 'updatedatetime',class: '', cond:{start:"cond_updatedatetime_start",end:"cond_updatedatetime_end"},width:"width_updatedatetime",type:"date"},
      {name:"SEQ",code:"seq",class: '',cond:"cond_seq",width:"width_seq",type:"number"},
      {name:"対象",code:"target",class: '',cond:"cond_target",width:"width_target",type:"select"},
      {name:"配信タイプ",code:"type",class: '',cond:"cond_type",width:"width_type",type:"select"},
      {name:"ドメイン",code:"domain_id",class: '',cond:"cond_domain_id",width:"width_domain_id",type:"select"},
      {name:"ユーザー",code:"user_id",class: '',cond:"cond_user_id",width:"width_user_id",type:"select"},
      {name:"作成者ID",code:"creator_id",class: '',cond:"cond_creator_id",width:"width_creator_id",type:"text"},
      {name:"重要度",code:"severity",class: '',cond:"cond_severity",width:"width_severity",type:"select"},
      {name:"要回答",code:"must_respond",class: '',cond:"cond_must_respond",width:"width_must_respond",type:"select"},
      {name:"タイトル",code:"title",class: '',cond:"cond_title",width:"width_title",type:"text"},
      {name:"詳細内容",code:"content",class: 'hide',cond:"",width:"",type:""},
      { name: '登録日時', code: 'insertdatetime',class: '', cond:{start:"cond_insertdatetime_start",end:"cond_insertdatetime_end"},width:"width_insertdatetime",type:"date"},
      {name:"削除フラグ",code:"delete",class: 'hide',cond:"",width:"",type:""},
    ];
    // TableCreate用プロパティ start-------------------------------------------------------------------------------
    var sortOrders_announce = {};
    for (let count = 0; count < announceColumns.length; count++) {
      sortOrders_announce[announceColumns[count]['code']] = 0
    }
    var table_cond_announce_common = {
      cond_seq:"",
      cond_target:"",
      cond_type:"",
      cond_domain_id:"",
      cond_announce_id:"",
      cond_creator_id:"",
      cond_severity:"",
      cond_must_respond:"",
      cond_title:"",
      cond_insertdatetime_start:"",
      cond_insertdatetime_end:"",
      cond_updatedatetime_start:"",
      cond_updatedatetime_end:"",
    }
    var table_width_announce_common = {
      width_seq:"60px",
      width_checked:"60px",
      width_target:"100px",
      width_type:"100px",
      width_domain_id:"100px",
      width_announce_id:"100px",
      width_creator_id:"100px",
      width_severity:"100px",
      width_must_respond:"100px",
      width_title:"200px",
      width_insertdatetime:"100px",
      width_updatedatetime:"100px",
      width_listfunction:"100px",
    }
    // TableCreate用プロパティ end-------------------------------------------------------------------------------
    return {
      page_title: "お知らせ運用",
      announcement: {
        title: "",
        content: "",
        target: "order", // 配信先
        type: 0, // 配信タイプ
        domain_id: "", // DOMAIN_ID
        user_id: "", // USER_ID
        creator_id: this.$store.state.userID, //作成者ユーザーID
        severity: 0, //重要度
        must_respond: 0, //要回答
      },
      announcement_created_list:[], //作成済のお知らせリスト
      announcement_created:{ //作成済のお知らせ編集
        title: "",
        content: "",
      },
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      table_cond_announce: {
        ...table_cond_announce_common
      },
      table_width_announce: {
        ...table_width_announce_common,
      },
      table_height_announce:"35vh",
      announceItems: announceColumns,
      announceItems2:[
        { name: '選択', code: 'checked', class: 'checked-item cell-checked',cond:"",width:"width_checked",type:"checkbox"},
        ...announceColumns,
        { name: '操作', code: 'listfunction', class: 'cell-function',cond:"",width:"width_listfunction",type:""}
      ],
      sortKey: '',
			sortOrders_announce: sortOrders_announce,
			sortKey_list_announce:[],
			// 1ページに表示する件数
			perPage_announce: 10,
			perPage_report: 1,
			currentPage_announce:1,
			currentPage_report:1,
			resultList_announce:[],
			allCheckFlg_announce:false,
			allCheckFlg_order:false,
			selectMaxNum_announce:500,
			selectMaxNum_announcedetail:500,
			cond_announce:{},
      clicknumber_announce: 0,
			// TableCreate用プロパティ end-------------------------------------------------------------------------------
      olgButtonList_announce: [
				{ name: '編集', action: "editAnnouncement" , class: "editAnnouncement" },
				{ name: '削除', action: "deleteAnnouncement" , class: "deleteAnnouncement" },
				{ name: '閉じる', action: "closeFunction", class:"closeFunction"},
			],
      editorOptions: {
        placeholder: "詳細内容を入力してください...",
      },
      target_radio_list:[
        {name:"オーダー者向け",value:"order"},
        {name:"読影者向け",value:"read"},
        {name:"すべて向け",value:"global"},
      ],
      type_radio_list:[
        {name:"共通",value:"0"},
        {name:"配信指定",value:"1"},
      ],
      severity_radio_list:[
        {name:"通常",value:"0"},
        {name:"重要",value:"1"},
        {name:"最重要",value:"2"},
      ],
      must_respond_radio_list:[
        {name:"通常",value:"0"},
        {name:"要回答",value:"1"},
      ],
      message:"",
      editmessage:"",
      domain_id_list: [],
      user_id_list: [],
      user_id_disp: "",
      switchFlg:false,
      announceCount:0,
      perPage:10,
      editTargetSEQ:0,
      showModalFlg:false,
    };
  },
  created(){
    var _this = this
		_this.get_data()
  },
  computed: {
    isDomainIdEditable() {
      return !this.announcement.user_id; // User_id が入力されていない場合に編集可能
    },
    isUserIdEditable() {
      return !this.announcement.domain_id; // Domain_id が入力されていない場合に編集可能
    },
    // TableCreate用プロパティ start-------------------------------------------------------------------------------
		// 昇順または降順でデータをソート
		filteredAnnounce: function () {
			// return (arg) => {
				console.log('computed発火')
				return this.sortFunc(this.announceItems,this.resultList_announce,this.sortKey_list_announce,this.sortOrders_announce,this.perPage_announce,this.currentPage_announce,"announce");
			// }
		},
		sortNumber: function() {
			const _this = this
			return (arg) => {
				return _this.addSortNumber(arg,"announce")
			}
		},
		getPageCount: function() {
			return this.MathCeil(this.resultList_announce,this.perPage_announce);
		},
		// TableCreate用プロパティ end-------------------------------------------------------------------------------
  },
  methods: {
    // TableCreate用プロパティ start-------------------------------------------------------------------------------
    clickCallback_announce(pageNum) {
			let _this = this
			this.currentPage_announce = Number(pageNum);
			this.paginationFlg = true
			setTimeout(() => {_this.changeChecked("detail","")},10)
		},
		sortEvent(code,sortOrders,search_type){
			let genre = ""
			if(search_type == "announce"){
				genre = "detail"
			}
			let throughFlg = this.sortBy(code,"","",sortOrders,search_type);
			if(!throughFlg){
				this.changeChecked(genre,"")
			}
		},
		sortClearEvent(Items,sortKey_list,sortOrders,search_type){
			let genre = ""
			if(search_type == "announce"){
				genre = "detail"
			}
			this.changeChecked(genre,"")
			this.sortClear(Items,sortKey_list,sortOrders,search_type)
			this.get_data('announce','')
		},
    changeChecked(search_type,event){
			if(event == ""){
				let targetList = ""
				let check = ""
				if(search_type == "detail"  && this.paginationFlg){
					if(document.getElementById("announceTable") !== null){
						targetList = document.getElementById("announceTable").querySelector('tbody').getElementsByClassName("cell-checked")
						check = false
						this.resultList_announcedetail = {}
						this.editList_announcedetail = {}
						this.ordercount = 0
						this.userid_list = []
					}
				}
				if(targetList !== ""){
					for( let i=0; i < targetList.length; i++ ){
						targetList[i].querySelector('input').checked = check
					}
				}
			}else{
				var checkeSearch = ""
				checkeSearch = event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked
				if(checkeSearch === true){
					event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked = false
					// if(search_type == "detail"){
					// 	this.userid_list = this.userid_list.filter(item => item != event.currentTarget.getElementsByClassName("cell-id")[0].textContent);
					// }
				}else{
					// if(search_type == "detail"){
					// 	this.userid_list.push(event.currentTarget.getElementsByClassName("cell-id")[0].textContent)
					// }
					event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked = true
          this.editAnnouncement(event)
				}
			}
			this.paginationFlg = false;
		},
    checked_all(bodyData,allCheckFlg,search_type){
      console.log(search_type)
			if(allCheckFlg){
				allCheckFlg = false
				for( let i=0; i < bodyData.length; i++ ){
					bodyData[i].checked = false
				}
			}else{
				allCheckFlg = true
				for( let i=0; i < bodyData.length; i++ ){
					bodyData[i].checked = true
				}
			}
		},
    // TableCreate用プロパティ end-------------------------------------------------------------------------------

    splitName_start(item) {
      return item.split(':')[0];
    },
    splitName_end(item) {
      return item.split(':')[1];
    },
    async get_data(){
      let genre = "announce"
			let resObj = ""
			let cond = {}
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
			resObj = this.set_conditions(this.announceItems,cond,this.perPage_announce,this.table_cond_announce,genre)
			this.cond_announce = resObj.cond
			this.perPage_announce = resObj.perPage
			// TableCreate用プロパティ end-------------------------------------------------------------------------------
      // ドメインID、ユーザーIDを取得
      let _this = this
      await axios.post(this.$store.state.API_HOST + '/get_announcement',
        this.cond_announce,
        {
          params: {
            app_name: _this.$store.state.APP_NAME,
            max:_this.selectMaxNum_announce,
            user_id: _this.$store.state.userID,
          },
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      ).then(
        function(response) {
          if(typeof(response.data) != "string"){
            _this.announcement_created_list = response.data.res
            _this.domain_id_list = response.data.domain_list
            _this.user_id_list = response.data.users_list
            _this.set_list()
          }else{
            _this.$store.dispatch('logout');
            _this.$store.commit('setLoginUser', '')
          }
        }.bind(this)
      )
      .catch(
          function(error) {
            this.editmessage = "GETエラー";
            console.log(error);
          }.bind(this)
      );
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
			this.set_draggable(genre)
			this.table_height_announce = this.set_height(this.table_height_announce,"table_height_announce",genre,document.querySelector('#' + 'announceTable').parentNode)
			this.set_width(this.announceItems,this.table_width_announce,genre)
			// TableCreate用プロパティ end-------------------------------------------------------------------------------

    },
    async set_list() {
      var _this = this
      // 深いコピーを作成
      _this.resultList_announce = JSON.parse(JSON.stringify(_this.announcement_created_list));
      for( var i=0; i < _this.resultList_announce.length; i++ ){
        var row = _this.resultList_announce[i]
        for (var key in row) {
          // console.log(key)
          if(key == "insertdatetime" || key == "updatedatetime"){
            var date = row[key].substr(0,10).replace(/-/g,"/")
            var time = row[key].substr(11,8)
            _this.resultList_announce[i][key] = date + " " + time
          }else if(key == "target" || key == "type" || key == "severity" || key == "must_respond"){
            const result = this[key + "_radio_list"].find(item => item.value === String(row[key]));
            _this.resultList_announce[i][key] = result.name
          }else if(key == "domain_id" || key == "user_id"){
            if(row[key] != ""){
              const result = this[key + "_list"].filter(item => item.startsWith(String(row[key]) + ":"));
              _this.resultList_announce[i][key] = result[0].split(":")[1]
            }
          }
        }
      }
      _this.announceCount = _this.resultList_announce.length
    },
    showFunction(num,type) {
			var _this = this
			console.log(num)
			_this.clicknumber_announce = 0
			if(type == "announce"){
				_this.clicknumber_announce = num
			}
			closePopUp(document.body);
			function closePopUp(elem) {
				if(!elem) return;
				elem.addEventListener('click', function() {
					_this.clicknumber_announce = 0
				})
			}
		},
		closeFunction(){
			var _this = this
			_this.clicknumber_announce = 0
		},
    handle_function_call(function_name,event) {
			var _this = this
			_this[function_name](event);
		},
    // // テーブルフィルター機能
    // filter_data(key, value, type) {
    //   let key_replaced = key.replace("cond_", "");
      
    //   // フィルタリング関数
    //   const filteredData = this.announcement_created_list.filter(item => {
    //     if (value !== "") {
    //       if(type === "date"){
    //         // 日付型の場合
    //         const key_replaced2 = key_replaced.replace("_start","").replace("_end","")
    //         const itemValue = item[key_replaced2];
    //         const itemDate = new Date(itemValue);
    //         const filterDate = new Date(value);
    //         if (key_replaced.includes("start")) {
    //           filterDate.setHours(0, 0, 0, 0); // 時間を0に設定
    //           // datetime_startの場合、後の日付を取得
    //           return itemDate >= filterDate;
    //         } else {
    //           filterDate.setHours(23, 59, 59, 999);
    //           // datetime_endの場合、過去の日付を取得
    //           return itemDate <= filterDate;
    //         }
    //       } else if (type === "number") {
    //         const itemValue = item[key_replaced];
    //         // 数値型の場合、完全一致
    //         return itemValue === parseInt(value); // 数値型に変換して比較
    //       } else if (type === "text") {
    //         const itemValue = item[key_replaced];
    //         // 文字型の場合、部分一致
    //         return itemValue.includes(value);
    //       }
    //     }
    //     // 上記以外の場合、条件を満たす
    //     return true;
    //   });

    //   // 結果を格納する配列
    //   this.resultList_announce = [];

    //   // フィルタリングされたデータを配列に格納
    //   filteredData.forEach(item => {
    //     this.resultList_announce.push(item);
    //   });

    //   console.log(this.resultList_announce);
    // },
    // お知らせをDBに新規登録
    async createAnnouncement() {
      this.announcement.user_id = this.user_id_disp.split(":")[0]
      // お知らせを作成するための処理をここに追加
      console.log("タイトル:", this.announcement.title);
      console.log("詳細内容:", this.announcement.content);
      console.log("配信先:", this.announcement.target);
      console.log("配信タイプ:", this.announcement.type);
      console.log("DOMAIN_ID:", this.announcement.domain_id);
      console.log("USER_ID:", this.announcement.user_id);
      console.log("重要度:", this.announcement.severity);
      console.log("要回答:", this.announcement.must_respond);
      if(this.announcement.title != "" && this.announcement.content != ""){
        if(this.announcement.target == "order" && this.announcement.type == 1 && (this.announcement.domain_id == "" && this.announcement.user_id == "")){
          this.message = "ドメインIDもしくはユーザーIDを入力してください。"
        }else{
          // お知らせをサーバーに送信
          let _this = this;
          let data = _this.announcement
          await axios.post(this.$store.state.API_HOST + '/create_announcement',
            data,
            {
              params: {
                app_name: _this.$store.state.APP_NAME,
                user_id: _this.$store.state.userID,
              },
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          ).then(
            function(response) {
              if(typeof(response.data) != "string"){
                this.resetAnnouncement()
                this.message = "配信完了しました。"
              }else{
                this.message = "配信が失敗しました。"
                _this.$store.dispatch('logout');
                _this.$store.commit('setLoginUser', '')
              }
            }.bind(this)
          )
          .catch(
              function(error) {
                this.message = "GETエラー";
                console.log(error);
              }.bind(this)
          );
        }
      }else{
        this.message = "タイトルと詳細内容は必須入力です。"
      }
      return;
    },
    // お知らせ新規作成中止
    createAnnouncement_stop(){
      let confirmResult = window.confirm('編集中のデータがあります。\n編集内容を保存せずに操作を続けますか？');
				if(!confirmResult){
					return false;
				}
				else{
					this.showModalFlg = false;
				}
    },
    // お知らせ編集画面表示
    editAnnouncement(event){
      this.editmessage = ""
      if (event.currentTarget.tagName === 'TR') {
        this.editTargetSEQ = event.currentTarget.getElementsByClassName("cell-seq")[0].textContent
      }else{
        this.editTargetSEQ = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-seq")[0].textContent
      }
      // 特定のseqを持つ要素のインデックスを取得
      const index = this.resultList_announce.findIndex(item => item.seq === parseInt(this.editTargetSEQ));
      this.announcement_created.title = this.resultList_announce[index]["title"]
      this.announcement_created.content = this.resultList_announce[index]["content"]
    },
    // お知らせ編集中止
    editAnnouncement_stop(){
      let confirmResult = window.confirm('編集中のデータがあります。\n編集内容を保存せずに操作を続けますか？');
				if(!confirmResult){
					return false;
				}
				else{
					this.editTargetSEQ = 0;
				}
    },
    // お知らせ編集内容をDBに反映
    async updateAnnouncement(){
      if(this.announcement_created.title != "" && this.announcement_created.content != ""){
        // お知らせをサーバーに送信
        let _this = this;
        const index = this.resultList_announce.findIndex(item => item.seq === parseInt(this.editTargetSEQ));
        this.announcement_created_list[index]["title"] = this.announcement_created.title
        this.announcement_created_list[index]["content"] = this.announcement_created.content
        let data = this.announcement_created_list[index]
        await axios.post(this.$store.state.API_HOST + '/update_announcement',
          data,
          {
            params: {
              app_name: _this.$store.state.APP_NAME,
              user_id: _this.$store.state.userID,
            },
            headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        ).then(
          function(response) {
            if(typeof(response.data) != "string"){
              this.editmessage = "配信完了しました。"
              _this.set_list()
            }else{
              this.editmessage = "配信が失敗しました。"
              _this.$store.dispatch('logout');
              _this.$store.commit('setLoginUser', '')
            }
          }.bind(this)
        )
        .catch(
            function(error) {
              this.editmessage = "GETエラー";
              console.log(error);
            }.bind(this)
        );
      }else{
        this.editmessage = "タイトルと詳細内容は必須入力です。"
      }
      return;
    },
    async deleteAnnouncement(event){
      let confirmResult = window.confirm('削除してよろしいですか？');
      if(!confirmResult){
        return false;
      }else{
        let _this = this
        let targetSEQ = 0
        if (event.currentTarget.tagName === 'TR') {
          targetSEQ = event.currentTarget.getElementsByClassName("cell-seq")[0].textContent
        }else{
          targetSEQ = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-seq")[0].textContent
        }
        const index = this.announcement_created_list.findIndex(item => item.seq === parseInt(targetSEQ));
        let data = this.announcement_created_list[index]
        await axios.post(this.$store.state.API_HOST + '/delete_announcement',
          data,
          {
            params: {
              app_name: _this.$store.state.APP_NAME,
              user_id: _this.$store.state.userID,
            },
            headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        ).then(
          function(response) {
            if(typeof(response.data) != "string"){
              this.editmessage = "削除完了しました。"
              // DBから取得した配列、表示用の配列から削除対象のデータを削除
              _this.announcement_created_list.splice(index, 1);
              _this.set_list()
            }else{
              this.editmessage = "削除に失敗しました。"
              _this.$store.dispatch('logout');
              _this.$store.commit('setLoginUser', '')
            }
          }.bind(this)
        )
        .catch(
            function(error) {
              this.editmessage = "GETエラー";
              console.log(error);
            }.bind(this)
        );
        return;
      }
    },
    async deleteAnnouncement_multiple(){
      let confirmResult = window.confirm('チェックした全てのお知らせを削除してよろしいですか？');
      if(!confirmResult){
        return false;
      }else{
        let _this = this
        this.editmessage = ""
        let targetList = document.getElementById("announceTable").querySelector('tbody').getElementsByClassName("cell-checked")
        let deleteTargetList = []
        for (let i =0;i < targetList.length;i++){
          if(targetList[i].querySelector('input').checked){
            let targetSEQ = targetList[i].parentNode.getElementsByClassName("cell-seq")[0].textContent
            const index = this.announcement_created_list.findIndex(item => item.seq === parseInt(targetSEQ));
            deleteTargetList.push(this.announcement_created_list[index])
          }
        }
        console.log(deleteTargetList)
        if(deleteTargetList.length ==0){
          _this.editmessage = "削除したいお知らせにチェックを入れてください。"
          return;
        }else{
          await axios.post(this.$store.state.API_HOST + '/delete_announcement_multiple',
            deleteTargetList,
            {
              params: {
                app_name: _this.$store.state.APP_NAME,
                user_id: _this.$store.state.userID,
              },
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          ).then(
            function(response) {
              if(typeof(response.data) != "string"){
                this.editmessage = "削除完了しました。"
                // DBから取得した配列、表示用の配列から削除対象のデータを削除
                for (const item of deleteTargetList) {
                  const index = _this.announcement_created_list.indexOf(item);
                  if (index !== -1) {
                    _this.announcement_created_list.splice(index, 1);
                  }
                }
                _this.set_list()
              }else{
                this.editmessage = "削除に失敗しました。"
                _this.$store.dispatch('logout');
                _this.$store.commit('setLoginUser', '')
              }
            }.bind(this)
          )
          .catch(
              function(error) {
                this.editmessage = "GETエラー";
                console.log(error);
              }.bind(this)
          );
        }
      }
    },
    checkInput() {
      // Domain_id と User_id のどちらか一方に値が入ったかを確認
      if (this.announcement.domain_id || this.announcement.user_id) {
        // どちらか一方に値が入っている場合、もう片方を編集不可に設定
        if (this.announcement.domain_id) {
          this.announcement.user_id = "";
        } else {
          this.announcement.domain_id = "";
        }
      }
    },
    // お知らせ新規作成画面初期化
    resetAnnouncement() {
      this.announcement.title = "";
      this.announcement.content = "";
      this.announcement.target = "order";
      this.announcement.type = 0;
      this.announcement.domain_id = "";
      this.announcement.user_id = "";
      this.announcement.creator_id = this.$store.state.userID;
      this.announcement.severity = 0;
      this.announcement.must_respond = 0;
    }
  },
  closeModal(event) {
      // モーダル内の要素をクリックしていたら何もしない
      if (event.target.classList.contains('modal-content')) {
          return;
      }
      // モーダル外をクリックした場合、モーダルを閉じる
      this.showModalFlg = false;
  },
};
</script>

<style scoped>
/* スタイリングを追加してラジオボタンや入力欄を装飾することができます */
.edit-row {
  margin-bottom: 20px;
}
#announcement-editor {
  max-width: 70%;
  /* margin: 0 auto; */
}

.title-editor {
  margin-bottom: 20px;
}

.title-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.content-editor {
  margin-bottom: 20px;
}

/* ラジオボタン間のマージンを設定 */
.radio-option {
  margin-right: 15px;
  margin-left: 5px;
}

#domain_id, #user_id {
  display: inline-flex;
  width: 250px;
  top: 5px;
  position: relative;
}

#delivery {
  margin-top: 10px;
}

#cancel {
  margin-left:10px;
  margin-top: 10px;
}

/* 編集不可の入力欄に適用されるスタイル */
.disabled-input {
  background-color: #ccc; /* 灰色の背景色 */
}

#message,#editmessage {
  color:red;
}

 /* === ボタンを表示するエリア ============================== */
 .switchArea {
  line-height    : 38px;                /* 1行の高さ          */
  letter-spacing : 0;                   /* 文字間             */
  text-align     : center;              /* 文字位置は中央     */
  font-size      : 16px;                /* 文字サイズ         */

  position       : relative;            /* 親要素が基点       */
  margin-left    : auto;                /* 中央寄せ           */
  width          : 142px;               /* ボタンの横幅       */
  background     : #f2f2f2;                /* デフォルト背景色   */
}

 /* === チェックボックス ==================================== */
.switchArea input[type="checkbox"] {
  display        : none;            /* チェックボックス非表示 */
}

 /* === チェックボックスのラベル（標準） ==================== */
.switchArea label {
  display        : block;               /* ボックス要素に変更 */
  box-sizing     : border-box;          /* 枠線を含んだサイズ */
  height         : 38px;                /* ボタンの高さ       */
  border         : 2px solid #1a1aff;   /* 未選択タブのの枠線 */
  border-radius  : 19px;                /* 角丸               */
}

 /* === チェックボックスのラベル（ONのとき） ================ */
.switchArea input[type="checkbox"]:checked +label {
  border-color   : #78bd78;             /* 選択タブの枠線     */
}

 /* === 表示する文字（標準） ================================ */
.switchArea label span:after{
  content        : "新規作成";               /* 表示する文字       */
  padding        : 0 0 0 22px;          /* 表示する位置       */
  color          : #1a1aff;             /* 文字色             */
}

 /* === 表示する文字（ONのとき） ============================ */
.switchArea  input[type="checkbox"]:checked + label span:after{
  content        : "編集・削除";                /* 表示する文字       */
  padding        : 0 22px 0 0;          /* 表示する位置       */
  color          : #78bd78;             /* 文字色             */
}

 /* === 丸部分のSTYLE（標準） =============================== */
.switchArea #swImg {
  position       : absolute;            /* 親要素からの相対位置*/
  width          : 30px;                /* 丸の横幅           */
  height         : 30px;                /* 丸の高さ           */
  background     : #1a1aff;             /* カーソルタブの背景 */
  top            : 4px;                 /* 親要素からの位置   */
  left           : 4px;                 /* 親要素からの位置   */
  border-radius  : 15px;                /* 角丸               */
  transition     : .2s;                 /* 滑らか変化         */
}

 /* === 丸部分のSTYLE（ONのとき） =========================== */
.switchArea input[type="checkbox"]:checked ~ #swImg {
  transform      : translateX(104px);    /* 丸も右へ移動       */
  background     : #78bd78;             /* カーソルタブの背景 */
}


/* TableCreate用プロパティ start------------------------------------------------------------------------------- */
#announceTable, #announceTable td,#announceTable th {
	width: max-content;
}
#announcement-editor input:not(.form-check-input), #announcement-editor textarea, #announcement-editor select {
    background-color: white;
    border-style: solid;
    border-width: 1px;
    -webkit-appearance: searchfield;
}

#announcement button,#announcement input,#announcement optgroup,#announcement select,#useannouncementrlist_top textarea {
		font: initial;
}
/* TableCreate用プロパティ end---------------------------------------------------------------------------------- */

.announce_reload {
  cursor: pointer;
  margin-right: 15px;
}

.createAnnouncement {
  margin-left    : auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    /* padding: 20px; */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 60%;
    padding: 30px;
}

.modalClose {
    font-size: 2rem;
    margin-left: auto;
    margin-right: 15px;
}

.modalClose:hover {
    cursor: pointer;
}

.content-editor .quillWrapper{
  background-color: white;
}

</style>
