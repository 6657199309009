<template>
  <div id="orderlist_top" class="orderlist container">
    <h2>
      <font-awesome-icon icon="rectangle-list" />&nbsp;オーダーリスト
      <span id="reportMenu">
        <span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="グループテーブル再読み込み">
          <font-awesome-icon icon="fa-redo-alt" @click="get_data('group','')" />
        </span>
        <span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="一括レポート表示">
          <font-awesome-icon icon="fa-file-lines" @click="ViewCheckedReport()" />
        </span>
        <span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="一括レポート保存">
          <font-awesome-icon icon="fa-cloud-arrow-down" @click="download_Reports()"/>
        </span>
        <span class="reportMenuItem" data-bs-toggle="tooltip" data-bs-placement="top" title="一括レポート印刷">
          <font-awesome-icon icon="fa-print" @click="funcprint_allReport()" />
        </span>
      </span>
    </h2>
    <p id="result_orderlist" v-if="result != ''">{{ result }}</p>
    <br />
  <div>
    <span>取得グループ：{{groupcount}}件</span>
    <label for="perPage_group" class="perPage_label">最大表示件数：</label>
    <select name="perPage_group" id="perPage_group" v-model="perPage_group">
      <option value=10>10件</option>
      <option value=100>100件</option>
      <option value=500>500件</option>
      <option value=1000>1000件</option>
    </select>
    <span>
      <button type="button" class="sortClear btn btn-secondary btn-sm" @click.stop="sortClearEvent(groupItems,sortKey_list_group,sortOrders_group,'group')">ソートクリア</button>
    </span>
  </div>
    <div class="tableScroll"  v-bind:style="{height:table_height_group}">
      <table id="groupTable" class="table table-striped table-bordered VariableTable">
        <thead>
          <draggable @end="set_draggable_toLocal('group')" :list="groupItems2" item-key="no" tag="tr">
            <template #item="{ element, index }">
              <th v-bind:id="element.code + '_group'" v-bind:key="index" v-bind:class="'px-2 py-1 align-middle ' + element.class" v-bind:style="{width: table_width_group[element.width]}">
                <div @click="sortEvent(element.code,sortOrders_group,'group')">
                  {{ element.name }}
                  <span  v-if="index!=0 && index!=groupItems2.length-2" class="arrow" :class="sortOrders_group[element.code] > 0 ? 'asc' : sortOrders_group[element.code] < 0 ? 'dsc' : ''"></span>
                  <span style="color:rgb(181 66 128);margin-left: 1px;">{{ sortNumber(element.code) }}</span>
                </div>
              </th>
            </template>
          </draggable>
          <tr>
            <th v-bind:class="'condition px-2 py-1 align-middle ' + item.class" v-for="(item,index) in groupItems2" v-bind:genre="item.genre" v-bind:key="index">
              <div v-if="item.type==='date'" class="dates">
                <input v-bind:type="item.type" v-model="table_cond_group[item.cond.start]" class="date-form" @change="get_data('group','')">
                <span>～</span>
                <input v-bind:type="item.type" v-model="table_cond_group[item.cond.end]" class="date-form" @change="get_data('group','')">
              </div>
              <div v-else-if="item.type==='checkbox'">
                <input type="checkbox" class="form-check-input" v-model="allCheckFlg_group" @click.stop="checked_all(resultList_group,allCheckFlg_group,'group')">
              </div>
              <div v-else-if="item.type===''"></div>
              <div v-else>
                <input v-bind:type="item.type" v-model="table_cond_group[item.cond]" @change="get_data('group','')">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( item, n ) in filteredGroup" :key="n" class='groupRow mouse_hover' v-bind:groupnumber="n+1"  @click="get_data('order',$event)">
            <template v-for="(item2) in groupItems2" :key="item2">
              <td v-if="item2.code=='checked'" class="cell-checked">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" v-model="item.checked" disabled="disabled">
                  </div>
              </td>
              <td v-else-if="item2.code=='listfunction'" class="col-sm-4 px-2 py-1 align-middle cell-function" @click.stop="showFunction(n+1,'group')" :style="{'text-align': 'center', 'z-index': clicknumber_group === n+1 ? 2 : 'auto'}">
                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                <div class="btn-group-vertical orderlist-function-group" v-if="clicknumber_group === n+1" :style="{'position': 'absolute', 'right': '0px', 'z-index': clicknumber_group === n+1 ? 1 : 'auto', 'important': '1'}">
                  <button v-for="(item, i) of olgButtonList_group" :key="i" type="button" class="btn btn-outline-secondary btn-sm" @click.stop="handle_function_call(item.action,$event)">{{ item.name }}</button>
                </div>
              </td>
              <td v-else :class="'col-sm-4 px-2 py-1 align-middle cell-' + item2.code + ' ' + item2.class">
                {{item[item2.code]}}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <paginate
        :page-count="getPageCount"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback_group"
        :prev-text="'＜'"
        :next-text="'＞'"
        :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate>
    </div>

    <div style="margin-top:40px">
      <span>取得オーダー：{{ordercount}}件</span>
      <label for="perPage_order" class="perPage_label">最大表示件数：</label>
      <select name="perPage_order" id="perPage_order" v-model="perPage_order">
        <option value=10>10件</option>
        <option value=100>100件</option>
        <option value=500>500件</option>
        <option value=1000>1000件</option>
        <option value="all">全て</option>
      </select>
      <span>
        <button type="button" class="sortClear btn btn-secondary btn-sm" @click.stop="sortClearEvent(orderItems,sortKey_list_order,sortOrders_order,'order')">ソートクリア</button>
      </span>
    </div>

    <div class="tableScroll" v-bind:style="{height:table_height_order}">
      <table id="orderTable" class="table table-striped table-bordered VariableTable">
        <thead>
          <draggable @end="set_draggable_toLocal('order')" :list="orderItems2" item-key="no" tag="tr">
            <template #item="{ element, index }">
              <th v-bind:id="element.code + '_order'" v-bind:key="index" v-bind:class="'px-2 py-1 align-middle ' + element.class" v-bind:style="{width: table_width_order[element.width]}">
                <div @click="sortEvent(element.code,sortOrders_order,'order')">
                  {{ element.name }}
                  <span  v-if="index!=0 && index!=orderItems2.length-2" class="arrow" :class="sortOrders_order[element.code] > 0 ? 'asc' : sortOrders_order[element.code] < 0 ? 'dsc' : ''" ></span>
                  <span style="color:rgb(181 66 128);margin-left: 1px;">{{ sortNumber_order(element.code) }}</span>
                </div>
              </th>
            </template>
          </draggable>
          <tr>
            <th v-bind:class="'condition px-2 py-1 align-middle ' + item.class" v-for="(item,index) in orderItems2" v-bind:genre="item.genre" v-bind:key="index">
              <div v-if="item.type==='date'" class="dates">
                <input v-bind:type="item.type" v-model="table_cond_order[item.cond.start]" class="date-form" @change="get_data('order','')">
                <span>～</span>
                <input v-bind:type="item.type" v-model="table_cond_order[item.cond.end]" class="date-form" @change="get_data('order','')">
              </div>
              <div v-else-if="item.type==='checkbox'">
                <input type="checkbox" class="form-check-input" v-model="allCheckFlg_order" @click.stop="checked_all(resultList_order,allCheckFlg_order,'order')">
              </div>
              <div v-else-if="item.type==='select' && item.code=='status'">
                <select v-model="table_cond_order[item.cond]" @change="get_data('order','')">
                  <option value=""></option>
                  <option value=0>画像更新中</option>
                  <option value=1>画像更新済</option>
                  <option value=2>オーダー完了</option>
                  <option value=3>読影完了</option>
                  <option value=9>キャンセル</option>
                </select>
              </div>
              <div v-else-if="item.type==='select' && item.code=='StudyModalities'">
                <select v-model="table_cond_order[item.cond]" @change="get_data('order','')">
                  <option value=""></option>
                  <option value="CR">CR</option>
                  <option value="CT">CT</option>
                  <option value="DR">DR</option>
                  <option value="ECG">ECG</option>
                  <option value="ES">ES</option>
                  <option value="MG">MG</option>
                  <option value="MR">MR</option>
                  <option value="OT">OT</option>
                  <option value="PT">PT</option>
                  <option value="RF">RF</option>
                  <option value="SC">SC</option>
                  <option value="US">US</option>
                  <option value="XA">XA</option>
                  <option value="OP">OP</option>
                </select>
              </div>
              <div v-else-if="item.type===''"></div>
              <div v-else>
                <input v-bind:type="item.type" v-model="table_cond_order[item.cond]" @change="get_data('order','')">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( item, n ) in filteredOrder" :key="n" class='orderRow mouse_hover' v-bind:ordernumber="n+1" @click="changeChecked('',$event)">
            <template v-for="(item2) in orderItems2" :key="item2">
              <td v-if="item2.code=='checked'" class="cell-checked">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" v-model="item.checked" disabled="disabled" checked>
                  </div>
              </td>
              <td v-else-if="item2.code=='listfunction'" class="col-sm-4 px-2 py-1 align-middle cell-function" @click.stop="showFunction(n+1,'order')" :style="{'text-align': 'center', 'z-index': clicknumber_order === n+1 ? 2 : 'auto'}">
                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                <div class="btn-group-vertical orderlist-function-group" v-if="clicknumber_order === n+1" :style="{'position': 'absolute', 'right': '0px', 'z-index': clicknumber_order === n+1 ? 1 : 'auto', 'important': '1'}">
                  <button v-for="(item, i) of olgButtonList" :key="i" type="button" class="btn btn-outline-secondary btn-sm" @click.stop="handle_function_call(item.action,$event)">{{ item.name }}</button>
                </div>
              </td>
              <td v-else-if="item2.code=='StudyDate' || item2.code=='StudyModalities'" :class="'col-sm-4 px-2 py-1 align-middle cell-' + item2.code + ' ' + item2.class">
                {{item['orderjson'].OrderGroup.Order.ImageInfo[0].StudyInfo[item2.code]}}
              </td>
              <td v-else :class="'col-sm-4 px-2 py-1 align-middle cell-' + item2.code + ' ' + item2.class">
                {{item[item2.code]}}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <paginate
        :page-count="getPageCount_order"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback_order"
        :prev-text="'＜'"
        :next-text="'＞'"
        :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate>
    </div>
    <div class="popup" id="js-popup">
      <span id="reportPageNum">{{currentPage_report + '/' + reportJsonList.length}}</span>
      <span v-if="currentPage_report > 1" class="reportViewControl" style="left: 5%;" @click.stop="reportPage(-1)">＜</span>

      <span class="popup-inner">
        <form id="print-form">
          <input type="button" value="レポート印刷" id="print" @click="funcprint()" />
        </form>
        <div class="close-btn" id="js-close-btn">X</div>
        <div id="reportList">
          <ReportTemplate :contents="reportJsonList" :currentPage_report="currentPage_report" :domain="domain"></ReportTemplate>
        </div>
      </span>

      <span v-if="currentPage_report != reportJsonList.length" class="reportViewControl" style="right: 5%;" @click.stop="reportPage(1)">＞</span>
      <div class="black-background" id="js-black-bg">
      </div>
    </div>
    <div class="popup" id="orderDetail">
      <span id="popup-inner_orderDetail">
        <div class="close-btn" id="close-btn-orderDetail">X</div>
        <div id="ViewZone">
          <span>
            <div id="orderGroupInputZone" class="row justify-content-center border border-0">
              <h3 style="color:black;">オーダーグループ情報表示欄</h3>
              <label for="GroupName">グループ名：</label>
              <input name="GroupName" v-model="GroupName">
              <label for="DoubleReadingMode">種別：</label>
              <select name="DoubleReadingMode" id="DoubleReadingMode" v-model="DoubleReadingMode">
                  <option value=0>1読影</option>
                  <option value=3>２重読影（オープン,１レポート）</option>
                  <option value=4>２重読影（ブラインド,１レポート）</option>
              </select>
              <label for="Anonymously">匿名：</label>
              <span id="Anonymously" style="padding:0px"></span>
              <br>
              <label for="GroupComment">伝言：</label>
              <textarea name="GroupComment" v-model="GroupComment"></textarea>
            </div>

            <div id="orderInputZone" class="row justify-content-center border border-0">
              <h3 style="color:black;">オーダー情報表示欄</h3>
              <label for="Applicant">作成者：</label>
              <input name="Applicant" v-model="Applicant">
              <label for="InterpretationRequests">読影要求：</label>
              <select name="InterpretationRequests" id="InterpretationRequests" v-model="InterpretationRequests">
                  <option value=0>通常</option>
                  <option value=1>優先</option>
                  <option value=2>緊急</option>
              </select>
              <label for="OutPatientHospital">入院外来：</label>
              <select name="OutPatientHospital" id="OutPatientHospital" v-model="OutPatientHospital">
                  <option value=-1>なし</option>
                  <option value=0>入院</option>
                  <option value=1>外来</option>
                  <option value=2>受託</option>
                  <option value=3>健診</option>
              </select>
              <label for="Departments">診療科：</label>
              <select name="Departments" id="Departments" v-model="Departments">
                  <option value="内科">内科</option>
                  <option value="外科">外科</option>
                  <option value="健診科">健診科</option>
                  <option value="放射線科">放射線科</option>
                  <option value="その他">その他</option>
              </select>
              <label for="LedgerNumber">台帳番号：</label>
              <input name="LedgerNumber" v-model="LedgerNumber">
              <br>
              <label for="EditedPatientName">患者名：</label>
              <input name="EditedPatientName" v-model="EditedPatientName">
              <label for="EditedPatientIdeogram">漢字：</label>
              <input name="EditedPatientIdeogram" v-model="EditedPatientIdeogram">
              <label for="EditedPatinePhonetic">フリガナ：</label>
              <input name="EditedPatinePhonetic" v-model="EditedPatinePhonetic">
              <label for="BodyPart">部位：</label>
              <input name="BodyPart" v-model="BodyPart">
              <br>
              <label for="AttendingPhysician">主治医：</label>
              <input name="AttendingPhysician" v-model="AttendingPhysician">
              <label for="Protocol">プロトコル：</label>
              <input name="Protocol" v-model="Protocol">
              <label for="Operator">オペレータ：</label>
              <input name="Operator" v-model="Operator">
              <br><br>
              <label for="ContrastAgent">造影剤：</label>
              <textarea name="ContrastAgent" v-model="ContrastAgent"></textarea>
              <label for="ClinicalInfo">臨床情報：</label>
              <textarea name="ClinicalInfo" v-model="ClinicalInfo"></textarea>
              <br><br>
              <label for="StudyComments">検査伝言：</label>
              <textarea name="StudyComments" v-model="StudyComments"></textarea>
              <label for="OrderComments">依頼伝言：</label>
              <textarea name="OrderComments" v-model="OrderComments"></textarea>
              <br><br>
              <label for="OrderDocument">伝票：</label>
              <div id="dropZone_OD" style="border: 1px solid; padding: 30px;">
                  <img id="preview" alt="">
              </div>
            </div>
          </span>
        </div>
      </span>
      <div class="black-background" id="black-bg-orderDetail">
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
// import {API_IP, API_PORT} from "../../public/const.json"
import draggable from 'vuedraggable'
import Paginate from 'vuejs-paginate-next'
import tableCreate from './mixins/TableCreate'
import ReportTemplate from "./ReportTemplate.vue"
import html2pdf from "html2pdf.js"

export default {
  name: "OrderList",
  mixins: [tableCreate],
  components: {
    draggable,
    Paginate,
    ReportTemplate,
  },
  data() {
    var groupColumns =  [
        { name: 'グループ名', code: 'ordergroupname',class: '' ,cond:"cond_ordergroupname",width:"width_ordergroupname",type:"text"},
        { name: 'ステータス', code: 'status',class: '' ,cond:"cond_status",width:"width_status",type:"number"},
        { name: 'オーダー者ID', code: 'orderer',class: '', cond:"cond_orderer",width:"width_orderer",type:"number"},
        { name: '施設名', code: 'domain',class: '', cond:"cond_domain",width:"width_domain",type:"text"},
        { name: '登録日時', code: 'insertdatetime',class: '', cond:{start:"cond_insertdatetime_start",end:"cond_insertdatetime_end"},width:"width_insertdatetime",type:"date"},
        { name: '更新日時', code: 'updatedatetime',class: '', cond:{start:"cond_updatedatetime_start",end:"cond_updatedatetime_end"},width:"width_updatedatetime",type:"date"},
        { name: 'seq', code: 'seq',class: 'hide' ,cond:'',width:"width_seq",type:""},
      ];
    var orderColumns = [
        { name: 'オーダー番号', code: 'orderid',class: '',cond:"cond_orderid",width:"width_orderid",type:"text"},
        { name: '患者ID', code: 'patientid',class: '',cond:"cond_patientid",width:"width_patientid",type:"text"},
        { name: 'ステータス', code: 'status',class: '',cond:"cond_status",width:"width_status",type:"select"},
        { name: 'オーダー者ID', code: 'orderer',class: '',cond:"cond_orderer",width:"width_orderer",type:"number"},
        { name: '施設名', code: 'domain',class: '',cond:"cond_domain",width:"width_domain",type:"text"},
        { name: '検査日', code: 'StudyDate',class: '',cond:{start:"cond_StudyDate_start",end:"cond_StudyDate_end"},width:"width_StudyDate",type:"date"},
        { name: 'モダリティ', code: 'StudyModalities',class: '',cond:"cond_StudyModalities",width:"width_StudyModalities",type:"select"},
        { name: '登録日時', code: 'insertdatetime',class: '',cond:{start:"cond_insertdatetime_start",end:"cond_insertdatetime_end"},width:"width_insertdatetime",type:"date"},
        { name: '更新日時', code: 'updatedatetime',class: '',cond:{start:"cond_updatedatetime_start",end:"cond_updatedatetime_end"},width:"width_updatedatetime",type:"date"},
        { name: '保持ステータス', code: 'savestatus',class: 'hide',cond:"",width:"",type:""},
        { name: 'オーダーグループseq', code: 'ordergroupseq',class: 'hide',cond:"cond_ordergroupseq",width:"width_ordergroupseq",type:""},
        { name: 'オーダーUID', code: 'orderuid',class: 'hide',cond:"cond_orderuid",width:"width_orderuid",type:"text"},
      ]
    // TableCreate用プロパティ start-------------------------------------------------------------------------------
    var sortOrders_group = {};
    for (let count = 0; count < groupColumns.length; count++) {
      sortOrders_group[groupColumns[count]['code']] = 0
    }
    var sortOrders_order = {};
    for (let count = 0; count < orderColumns.length; count++) {
      sortOrders_order[orderColumns[count]['code']] = 0
    }
    var table_cond_group_common = {
      cond_status:"",
      cond_orderer:"",
      cond_domain:"",
      cond_insertdatetime_start:"",
      cond_insertdatetime_end:"",
      cond_updatedatetime_start:"",
      cond_updatedatetime_end:"",
    }
    var table_width_group_common = {
      width_checked:"60px",
      width_status:"100px",
      width_orderer:"120px",
      width_domain:"100px",
      width_insertdatetime:"100px",
      width_updatedatetime:"100px",
      width_listfunction:"100px",
    }
    var table_cond_order_common = {
      cond_orderid:"",
      cond_patientid:"",
      cond_status:"",
      cond_orderer:"",
      cond_domain:"",
      cond_StudyDate:"",
      cond_StudyModalities:"",
      cond_insertdatetime_start:"",
      cond_insertdatetime_end:"",
      cond_updatedatetime_start:"",
      cond_updatedatetime_end:"",
    }
    var table_width_order_common = {
      width_checked:"60px",
      width_orderid:"150px",
      width_patientid:"120px",
      width_status:"100px",
      width_orderer:"120px",
      width_domain:"100px",
      width_StudyDate:"100px",
      width_StudyModalities:"80px",
      width_insertdatetime:"100px",
      width_updatedatetime:"100px",
      width_listfunction:"100px",
    }
    // TableCreate用プロパティ end-------------------------------------------------------------------------------
    return {
      key_orderID:"",
      key_PatientID: "",
      key_status: "",
      result: "",
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      table_cond_group: {
        cond_ordergroupname:"",
        ...table_cond_group_common
            },
      table_width_group: {
        width_ordergroupname:"170px",
        ...table_width_group_common,
        width_seq:"0px",
            },
      table_cond_order: {
        cond_orderid:"",
        cond_patientid:"",
        ...table_cond_order_common,
        // cond_ordergroupseq:"",
        cond_orderuid:"",
            },
      table_width_order: {
        width_orderid:"170px",
        width_patientid:"170px",
        ...table_width_order_common,
        width_ordergroupseq:"0px",
        width_orderuid:"0px",
            },
      table_height_group:"35vh",
      table_height_order:"35vh",
      groupItems: groupColumns,
      groupItems2:[
        { name: '選択', code: 'checked', class: 'checked-item cell-checked',cond:"",width:"width_checked",type:"checkbox"},
        ...groupColumns,
        { name: '操作', code: 'listfunction', class: 'cell-function',cond:"",width:"width_listfunction",type:""}
      ],
      orderItems: orderColumns,
      orderItems2: [
        { name: '選択', code: 'checked', class: 'checked-item cell-checked',cond:"",width:"width_checked",type:"checkbox"},
        ...orderColumns,
        { name: '操作', code: 'listfunction', class: 'cell-function',cond:"",width:"width_listfunction",type:""}
      ],
      sortKey: '',
      sortOrders_group: sortOrders_group,
      sortOrders_order: sortOrders_order,
      sortKey_list_group:[],
      sortKey_list_order:[],
      // 1ページに表示する件数
      perPage_group: 10,
      perPage_order: "all",
      perPage_report: 1,
      currentPage_group:1,
      currentPage_order:1,
      currentPage_report:1,
      resultList_group:{},
      resultList_order:{},
      allCheckFlg_group:false,
      allCheckFlg_order:false,
      selectMaxNum_group:500,
      selectMaxNum_order:10000,
      cond_group:{},
      cond_order:{},
      // TableCreate用プロパティ end-------------------------------------------------------------------------------
      groupnumber:0,
      ordernumber:0,
      groupcount:0,
      ordercount:0,
      ready_check:false,
      ordergroupseq_list:[],
      postcount:0,
      orderuid:"",
      postcount2:0,
      htmlContent: "",
      orderjsonList: [],
      GroupName: "",
      DoubleReadingMode: "",
      Anonymously: "",
      GroupComment: "",
      Applicant: "",
      InterpretationRequests: "",
      OutPatientHospital: "",
      Departments: "",
      LedgerNumber: "",
      EditedPatientName: "",
      EditedPatientIdeogram: "",
      EditedPatinePhonetic: "",
      BodyPart: "",
      AttendingPhysician: "",
      Protocol: "",
      Operator: "",
      ContrastAgent: "",
      ClinicalInfo: "",
      StudyComments: "",
      OrderComments: "",
      Base64text: "",
      olgButtonList_group: [
        { name: 'レポート表示', action: "viewReport_group" , class: "viewReport_group" },
        { name: 'レポート保存', action: "downloadReport_group" , class: "downloadReport_group" },
        { name: 'レポート印刷', action: "printReport_group" , class: "printReport_group" },
        { name: 'オーダー取消', action: "orderCancel_group", class: "orderCancel_group" },
        { name: 'レポートリンク', action: "reportLinkCopy_group", class: "reportLinkCopy_group" },
        { name: 'キー情報コピー', action: "keyInfoCopy_group", class: "keyInfoCopy_group" },
        // { name: 'オーダー詳細', action: "viewOrderInfo_group", class: "viewOrderInfo_group" },
        { name: '閉じる', action: "closeFunction_group", class:"closeFunction_group" },
      ],
      olgButtonList: [
        { name: 'レポート表示', action: "viewReport" , class: "viewReport" },
        { name: 'レポート保存', action: "downloadReport" , class: "downloadReport" },
        { name: 'レポート印刷', action: "printReport" , class: "printReport" },
        { name: 'オーダー取消', action: "orderCancel", class: "orderCancel" },
        { name: 'オーダー詳細', action: "viewOrderInfo", class: "viewOrderInfo" },
        { name: 'レポートリンク', action: "reportLinkCopy", class: "reportLinkCopy" },
        { name: 'キー情報コピー', action: "keyInfoCopy", class: "keyInfoCopy" },
        { name: '閉じる', action: "closeFunction", class:"closeFunction" },
      ],
      olgHide: false,
      olgHide_function: false,
      clicknumber_group: 0,
      clicknumber_order: 0,
      reportView_orderuidList:[],
      reportJsonList:[],
      paginationFlg : false,
      domain: localStorage.getItem("domain")
    };
  },
  watch:{
    select: function(){
      this.currentPage = 1;
    }
  },
  computed: {
    // TableCreate用プロパティ start-------------------------------------------------------------------------------
    // 昇順または降順でデータをソート
    filteredGroup: function () {
      // return (arg) => {
        console.log('computed発火')
        return this.sortFunc(this.groupItems,this.resultList_group,this.sortKey_list_group,this.sortOrders_group,this.perPage_group,this.currentPage_group,"group");
      // }
    },
    sortNumber: function() {
      const _this = this
      return (arg) => {
        return _this.addSortNumber(arg,"group")
      }
    },
    getPageCount: function() {
      return this.MathCeil(this.resultList_group,this.perPage_group);
    },
    // 昇順または降順でデータをソート
    filteredOrder: function () {
      // return (arg) => {
        console.log('computed2発火')
        if(this.perPage_order == "all"){
          return this.sortFunc(this.orderItems,this.resultList_order,this.sortKey_list_order,this.sortOrders_order,this.resultList_order.length,this.currentPage_order,"order");
        }else{
          return this.sortFunc(this.orderItems,this.resultList_order,this.sortKey_list_order,this.sortOrders_order,this.perPage_order,this.currentPage_order,"order");
        }
      // }
    },
    sortNumber_order: function() {
      const _this = this
      return (arg) => {
        return _this.addSortNumber(arg,"order")
      }
    },
    getPageCount_order: function() {
      if(this.perPage_order == "all"){
        return this.MathCeil(this.resultList_order,this.resultList_order.length);
      }else{
        return this.MathCeil(this.resultList_order,this.perPage_order);
      }
    },
    getPageCount_report: function() {
      return this.MathCeil(this.reportJsonList,this.perPage_report);
    },
    // TableCreate用プロパティ end-------------------------------------------------------------------------------
  },
  methods: {
    clickCallback_group(pageNum) {
      let _this = this
      this.currentPage_group = Number(pageNum);
      this.paginationFlg = true
      setTimeout(() => {_this.changeChecked("order","")},10)
    },
    clickCallback_order(pageNum) {
      let _this = this
      this.currentPage_order = Number(pageNum);
      setTimeout(_this.changeChecked("",""),10)
    },
    clickCallback_report(pageNum) {
      this.currentPage_report = Number(pageNum);
    },
    sortEvent(code,sortOrders,search_type){
      let genre = ""
      if(search_type == "group"){
        genre = "order"
      }
      let throughFlg = this.sortBy(code,"","",sortOrders,search_type);
      if(!throughFlg){
        this.changeChecked(genre,"")
      }
    },
    sortClearEvent(Items,sortKey_list,sortOrders,search_type){
      let genre = ""
      if(search_type == "group"){
        genre = "order"
      }
      this.changeChecked(genre,"")
      this.sortClear(Items,sortKey_list,sortOrders,search_type)
    },
    async get_data(search_type,event) {
      let genre = ""
      if(search_type == "group" || search_type == "order"){
        genre = "order"
      }
      if(search_type == "group" && event == ""){
        this.paginationFlg = true
      }
      this.changeChecked(genre,event)
      // console.log(localStorage);

      if(search_type == "group") {
        this.get_data_group()
      }else if (search_type == "order"){
        this.get_data_order()
      }
    },
    async get_data_group() {
      let genre = "group"
      let genre_order = "order"
      let resObj = ""
      let resObj_order = ""
      let cond = {}
      let cond_order = {}
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      resObj = this.set_conditions(this.groupItems,cond,this.perPage_group,this.table_cond_group,genre)
      this.cond_group = resObj.cond
      this.perPage_group = resObj.perPage
      // TableCreate用プロパティ end-------------------------------------------------------------------------------
      await this.select_group()
      await this.set_list_group()
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      this.set_draggable(genre)
      this.table_height_group = this.set_height(this.table_height_group,"table_height_group",genre,document.querySelector('#' + 'groupTable').parentNode)
      this.set_width(this.groupItems,this.table_width_group,genre,)
      // TableCreate用プロパティ end-------------------------------------------------------------------------------

      // orderテーブル初期設定
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      resObj_order = this.set_conditions(this.orderItems,cond_order,this.perPage_order,this.table_cond_order,genre_order)
      this.cond_order = resObj_order.cond
      this.perPage_order = resObj_order.perPage
      this.set_draggable(genre_order)
      this.table_height_order = this.table_height_order = this.set_height(this.table_height_order,"table_height_order",genre_order,document.querySelector('#' + 'orderTable').parentNode)
      this.set_width(this.orderItems,this.table_width_order,genre_order)
      // TableCreate用プロパティ end-------------------------------------------------------------------------------
    },
    async select_group() {
      var _this = this
      // クッキーからセッションIDを取得
      // const sessionId = document.cookie
      //   .split('; ')
      //   .find(row => row.startsWith('session_id='))
      //   .split('=')[1];

      // console.log("セッションID：" + sessionId)

      await axios
        .post(
          _this.$store.state.API_HOST + '/select_groups/',
          _this.cond_group,
          {
              params: {
                  'app_name': _this.$store.state.APP_NAME,
                  'max': _this.selectMaxNum_group,
                  'id': _this.$store.state.userID,
              },
              headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json',
              },
              withCredentials: true
          }
        )
        .then(
          function(response) {
            console.log(response)
            if(typeof(response.data) != "string"){
              _this.groupcount = response.data.res.length
              _this.resultList_group = response.data.res
              console.log(_this.resultList_group);
            }else{
              _this.$store.dispatch('logout');
              _this.$store.commit('setLoginUser', '')
            }
          }.bind(this)
        )
        .catch(
          function(error) {
            this.result = "GETエラー";
            console.log(error);
            this.$store.dispatch('logout');
            this.$store.commit('setLoginUser', '')
          }.bind(this)
        );
      return;
    },
    async set_list_group() {
      var _this = this
      var groups = _this.resultList_group
      for( var i=0; i < groups.length; i++ ){
        var group = groups[i]
        for (var key in group) {
          // console.log(key)
          if(key == "insertdatetime" || key == "updatedatetime"){
            var date = group[key].substr(0,10).replace(/-/g,"/")
            var time = group[key].substr(11,8)
            _this.resultList_group[i][key] = date + " " + time
          }
        }
      }
    },
    async get_data_order() {
      let resObj = ""
      let genre = "order"
      let cond = {}
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      resObj = this.set_conditions(this.orderItems,cond,this.perPage_order,this.table_cond_order,genre)
      this.cond_order = resObj.cond
      this.perPage_order = resObj.perPage
      // TableCreate用プロパティ end-------------------------------------------------------------------------------
      await this.select_order()
      await this.set_list_order()
      // TableCreate用プロパティ start-------------------------------------------------------------------------------
      this.set_draggable(genre)
      // this.set_height(this.table_height_order,"table_height_order",genre,document.querySelector('#' + 'orderTable').parentNode)
      this.set_width(this.orderItems,this.table_width_order,genre)
      // TableCreate用プロパティ end-------------------------------------------------------------------------------
    },
    async select_order() {
      var _this = this
      if(this.ordergroupseq_list.length == 0){
        this.resultList_order = {}
        this.ordercount = 0
      }else{
        // 検索条件追加（グループSEQは検索のたびに使用するため、setConditionでは追加せず、ここで設定）
        this.cond_order = Object.assign(JSON.parse(this.cond_order),{ordergroupseq:this.ordergroupseq_list})
        console.log("オーダーコンディション")
        console.log(_this.cond_order)
        await axios
          .post(_this.$store.state.API_HOST + '/select_orders/',
            _this.cond_order,
            {
              params: {
                  'app_name': _this.$store.state.APP_NAME,
                  'max': _this.selectMaxNum_order,
                  'id': _this.$store.state.userID,
              },
              headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json',
              },
              withCredentials: true
            }
          ).then(
            function(response) {
              if(typeof(response.data) != "string"){
                _this.ordercount = response.data.res.length
                _this.resultList_order = response.data.res
                console.log(_this.resultList_order);
              }else{
                _this.$store.dispatch('logout');
                _this.$store.commit('setLoginUser', '')
              }
            }.bind(this)
          )
          .catch(
            function(error) {
              this.result = "GETエラー";
              console.log(error);
              this.$store.dispatch('logout');
              this.$store.commit('setLoginUser', '')
            }.bind(this)
          );
      }
      return;
    },
    async set_list_order() {
      var _this = this
      var orders = _this.resultList_order
      _this.orderjsonList.length = 0;
      for( var k=0; k < orders.length; k++ ){
        var order = orders[k]
        for (var key_order in order) {
          // let target_cell = document.getElementById("orderTable").getElementsByClassName("cell-" + key_order)[k];
          if(key_order == "status"){
            _this.resultList_order[k]["savestatus"] = order[key_order]
            // target_cell.setAttribute("status",order[key_order])
            switch(order[key_order]){
              case 0:
                _this.resultList_order[k][key_order] = "画像更新中"
                break;
              case 1:
                _this.resultList_order[k][key_order] = "画像更新済"
                break;
              case 2:
                _this.resultList_order[k][key_order] = "オーダー完了"
                break;
              case 3:
                _this.resultList_order[k][key_order] = "読影完了"
                break;
              case 9:
                _this.resultList_order[k][key_order] = "キャンセル"
                break;
            }
          }else if(key_order == "orderjson"){
            _this.orderjsonList.push(order[key_order])
            let year = _this.resultList_order[k][key_order].OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate.substr(0,4)
            let month = _this.resultList_order[k][key_order].OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate.substr(4,2)
            let day = _this.resultList_order[k][key_order].OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate.substr(6,2)
            _this.resultList_order[k][key_order].OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate = year + "/" + month + "/" + day
          }else if(key_order == "insertdatetime" || key_order == "updatedatetime"){
            var date = order[key_order].substr(0,10).replace(/-/g,"/")
            var time = order[key_order].substr(11,8)
            _this.resultList_order[k][key_order] = date + " " + time
          }else{
            _this.resultList_order[k][key_order] = order[key_order]
          }
        }
      }
    },
    async viewReport(event){
      var status = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-savestatus")[0].textContent
      var _this = this
      if(status == "3"){
        _this.reportView_orderuidList = []
        _this.reportView_orderuidList.push(event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-orderuid")[0].textContent)
        console.log("オーダーUID：" + _this.orderuid)
        console.log(localStorage);
        await this.select_report()
        console.log("要素数：" + _this.reportJsonList.length)
        if(_this.reportJsonList.length != 0){
          _this.set_report()
          _this.result = ""
        }else{
          _this.result = "読影が完了してないため、レポートを表示できませんでした。"
        }
      }else{
        alert("指定のオーダーは読影完了していないため、レポート表示できません。");
      }
    },
    async viewReport_group(event){
      var status = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-status")[0].textContent
      if(status == "3"){
        var ordergroupseq = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-seq")[0].textContent
        var _this = this
        console.log("レポート表示グループSEQ：" + ordergroupseq)
        await this.select_report('group',ordergroupseq)
        console.log("要素数：" + _this.reportJsonList.length)
        if(_this.reportJsonList.length != 0){
          _this.set_report()
          _this.result = ""
        }else{
          _this.result = "読影が完了してないため、レポートを表示できませんでした。"
        }
      }else{
        _this.result = "読影が完了してないため、レポートを表示できませんでした。"
      }
    },
    // チェックボックスにチェックしたレポートを表示
    async ViewCheckedReport(genre){
      this.result = ""
      this.reportView_orderuidList = []
      let targetList = document.getElementById("orderTable").querySelector('tbody').getElementsByClassName("cell-checked")
      for (let i =0;i < targetList.length;i++){
        if(targetList[i].querySelector('input').checked){
          let status = targetList[i].parentNode.getElementsByClassName("cell-savestatus")[0].textContent
          if(status == "3"){
            this.reportView_orderuidList.push(targetList[i].parentNode.getElementsByClassName("cell-orderuid")[0].textContent)
          }else{
            if(genre == "download"){
              this.result = "読影が完了してないため、一部のレポートはダウンロードできませんでした。"
            }else if(genre == "allprint"){
              this.result = "読影が完了してないため、一部のレポートは印刷できませんでした。"
            }else{
              this.result = "読影が完了してないため、一部のレポートは表示できませんでした。"
            }
          }
        }
      }
      console.log("レポート表示対象オーダーUID-------------------")
      console.log(this.reportView_orderuidList)
      console.log("--------------------------------------------")
      if(this.reportView_orderuidList.length > 0){
        await this.select_report();
        if(this.reportJsonList.length != 0){
          if(genre !== "download" && genre !== "allprint"){
            this.currentPage_report = 1
            await this.set_report();
          }
        }else{
          this.result = "表示できるレポートが見つかりませんでした。"
        }
      }else{
        this.result = "グループテーブルまたはオーダーテーブルより、レポート操作をしたいオーダーのチェックボックスにチェックを入れてください。"
      }
    },
    async select_report(search_type,ordergroupseq) {
      var _this = this
      let formData = new FormData()
      if(search_type == 'group'){
        formData.append("orderuidList_str",JSON.stringify([]))
        formData.append("ordergroupseq",ordergroupseq)
      }else{
        formData.append("orderuidList_str",JSON.stringify(this.reportView_orderuidList))
        formData.append("ordergroupseq","none")
      }
      await axios.post(
        _this.$store.state.API_HOST + '/select_report/',
        formData,
        {
          params: {
              'app_name': _this.$store.state.APP_NAME,
              'user_id': _this.$store.state.userID,
          },
          withCredentials: true
        }
      ).then(
        function(response) {
          console.log(response)
          if(typeof(response.data) != "string"){
            _this.reportJsonList = response.data.res
            console.log("レポート出力用Json表示---------------------------------------------")
            console.log(_this.reportJsonList);
            console.log(_this.reportJsonList[0]["reportjson"]);
            console.log(_this.reportJsonList[0]["orderjson"]);
            console.log("------------------------------------------------------------------")
          }else{
            _this.$store.dispatch('logout');
            _this.$store.commit('setLoginUser', '')
          }
        }.bind(this)
      )
      .catch(
        function(error) {
          this.result = "GETエラー";
          console.log(error);
          this.$store.dispatch('logout');
          this.$store.commit('setLoginUser', '')
        }.bind(this)
      );
      return;
    },
    async set_report(){
      var _this = this;
      // ポップアップ表示
      var popup = document.getElementById('js-popup');
      if(!popup) return;
      popup.classList.add('is-show');
      var blackBg = document.getElementById('js-black-bg');
      var closeBtn = document.getElementById('js-close-btn');
      closePopUp(blackBg);
      closePopUp(closeBtn);
      function closePopUp(elem) {
        if(!elem) return;
        elem.addEventListener('click', function(event) {
          event.stopPropagation();
          popup.classList.remove('is-show');
          _this.reportJsonList = []
        })
      }
    },
    // 開いているレポートのページ番号算出
    reportPage(num){
      if(num == 1){
        this.currentPage_report += 1;
      }else{
        this.currentPage_report -= 1;
      }
    },
    // レポートを取得し、モーダル表示はせずに単体で印刷またはダウンロードを行う
    async funcReport(event,funcName,search_type){
      var status = ""
      if(search_type == "group"){
        status = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-status")[0].textContent
      }else{
        status = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-savestatus")[0].textContent
      }
      var _this = this
      if(status == "3"){
        if(search_type == "group"){
          let ordergroupseq = event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-seq")[0].textContent
          await this.select_report('group',ordergroupseq)
        }else{
          _this.reportView_orderuidList = []
          _this.reportView_orderuidList.push(event.currentTarget.parentNode.parentNode.parentNode.getElementsByClassName("cell-orderuid")[0].textContent)
          console.log(localStorage);
          await this.select_report()
        }
        console.log("要素数：" + _this.reportJsonList.length)
        if(_this.reportJsonList.length != 0){
          if(funcName == "print"){
            this.funcprint();
          }else if(funcName == "download"){
            this.funcDownload();
          }
        }else{
          if(funcName == "print"){
            _this.result = "読影が完了してないため、レポートを印刷できませんでした。"
          }else if(funcName == "download"){
            _this.result = "読影が完了してないため、レポートをダウンロードできませんでした。"
          }
        }
      }else{
        if(funcName == "print"){
          alert("指定のオーダーは読影完了していないため、レポートを印刷できません。");
        }else if(funcName == "download"){
          alert("指定のオーダーは読影完了していないため、レポートをダウンロードできません。");
        }
      }
    },
    // 操作メニューのレポート印刷ボタンより印刷される
    async printReport(event){
      await this.funcReport(event,"print")
    },
    // 操作メニューのレポート印刷ボタンより印刷される
    async printReport_group(event){
      await this.funcReport(event,"print","group")
    },
    // レポート印刷ボタンより印刷される
    funcprint() {
      // 一時的にすべての要素を非表示にして複数レポート印刷用の要素を作成。
      if(document.getElementById("reportList").getElementsByClassName("reports").length > 0){
        document.getElementById("app").style.display = "none";
        let clone_element = document.getElementById("reportList").cloneNode(true)
        clone_element.id = "reportList_forPrint"
        document.body.after(clone_element)
        let reports = document.getElementById("reportList_forPrint").getElementsByClassName("reports")
        for (let i=0;i < reports.length;i++){
          reports[i].firstChild.style.display= "block";
          reports[i].getElementsByClassName("modal-report")[0].style.borderStyle = "hidden"
        }
        window.print()
        document.getElementById("reportList_forPrint").remove();
        // this.reportJsonList = []
        document.getElementById("app").style.display = "block";
      }
    },
    async funcprint_allReport(){
      // 一時的にすべての要素を非表示にして複数レポート印刷用の要素を作成。
      await this.ViewCheckedReport("allprint");
      if(document.getElementById("reportList").getElementsByClassName("reports").length > 0){
        document.getElementById("app").style.display = "none";
        let clone_element = document.getElementById("reportList").cloneNode(true)
        clone_element.id = "reportList_forPrint"
        document.body.after(clone_element)
        let reports = document.getElementById("reportList_forPrint").getElementsByClassName("reports")
        for (let i=0;i < reports.length;i++){
          reports[i].firstChild.style.display= "block";
          reports[i].getElementsByClassName("modal-report")[0].style.borderStyle = "hidden"
        }
        window.print()
        document.getElementById("reportList_forPrint").remove();
        this.reportJsonList = []
        document.getElementById("app").style.display = "block";
      }
    },
    async downloadReport(event){
      await this.funcReport(event,"download")
    },
    async downloadReport_group(event){
      await this.funcReport(event,"download","group")
    },
    funcDownload(){
      let reports = document.getElementById("reportList").getElementsByClassName("reports")
      for (let i=0;i < reports.length;i++){
        let filename = "report" + (i+1) + ".pdf"
        let element = reports[i].getElementsByClassName("modal-report")[0]; // 対象要素を指定
        reports[i].getElementsByClassName("modal-report")[0].style.minHeight = "290mm";
        let option = {
          margin:[5,0,0,1], // 余白
          // css_media_type: print,
          filename: filename, // ファイル名
          image: { type: 'jpg', quality: 1 }, // PDFの生成に使用される画像のタイプとクオリティ
          // html2canvas: { scale: 2, useCORS: true }, // html2canvasで使用される設定を記述。useCORS: trueを設定すると別ドメインの画像を表示できる（サイトによってはできないこともある）
          jsPDF: { format: 'a4', orientation: 'portrait'}, // jsPDFで使用される設定を記述
          pagebreak: { avoid: ['.inputDiv','.key-images']}
        };

        reports[i].getElementsByClassName("modal-report")[0].style.borderStyle = "hidden"

        html2pdf()
        .from(element)
        .set(option)
        .save()
        .then(() => {
          // 成功
          console.log("ダウンロード成功ファイル名：" + filename)
        })
        .catch((e) => {
          // 失敗
          console.log("ダウンロード失敗ファイル名：" + filename)
          console.log(e)
        })
        .finally(() => {
          this.reportJsonList = []
          reports[i].getElementsByClassName("modal-report")[0].style.minHeight = "297mm";
        });
      }
    },
    async download_Reports(){
      await this.ViewCheckedReport("download");
      this.funcDownload();
    },
    changeChecked(search_type,event){
      if(event == ""){
        let targetList = ""
        let check = ""
        if(search_type == "order"  && this.paginationFlg){
          if(document.getElementById("groupTable") !== null){
            console.log("オーダーテーブル全削除")
            targetList = document.getElementById("groupTable").querySelector('tbody').getElementsByClassName("cell-checked")
            check = false
            this.resultList_order = {}
            this.ordercount = 0
            this.ordergroupseq_list = []
          }
        }else{
          if(document.getElementById("groupTable") !== null){
            targetList = document.getElementById("orderTable").querySelector('tbody').getElementsByClassName("cell-checked")
            check = true
          }
        }
        if(targetList !== ""){
          for( let i=0; i < targetList.length; i++ ){
            targetList[i].querySelector('input').checked = check
          }
        }
      }else{
        var checkeSearch = ""
        checkeSearch = event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked
        if(checkeSearch === true){
          event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked = false
          if(search_type == "order"){
            this.ordergroupseq_list = this.ordergroupseq_list.filter(item => item != event.currentTarget.getElementsByClassName("cell-seq")[0].textContent);
          }
        }else{
          if(search_type == "order"){
            this.ordergroupseq_list.push(event.currentTarget.getElementsByClassName("cell-seq")[0].textContent)
          }
          event.currentTarget.getElementsByClassName("cell-checked")[0].querySelector('input').checked = true
        }
      }
      console.log("オーダーグループSEQリスト--------------------")
      console.log(this.ordergroupseq_list)
      console.log("--------------------------------------------")
      this.paginationFlg = false;
    },
    checked_all(bodyData,allCheckFlg,search_type){
      if(allCheckFlg){
        allCheckFlg = false
        for( let i=0; i < bodyData.length; i++ ){
          bodyData[i].checked = false
        }
        if(search_type == "group"){
          this.resultList_order = {}
          this.ordercount = 0
          this.ordergroupseq_list = []
        }
      }else{
        allCheckFlg = true
        for( let i=0; i < bodyData.length; i++ ){
          bodyData[i].checked = true
        }
        if(search_type == "group"){
          let targetList = document.getElementById("groupTable").querySelector('tbody').getElementsByClassName("cell-seq")
          for (let i = 0; i < targetList.length; i++) {
            this.ordergroupseq_list.push(targetList[i].textContent)
          }
          this.get_data_order()
          this.changeChecked("","")
        }
      }
    },
    viewOrderInfo(event){
      var _this = this
      // _this.changeChecked(search_type,event);
      var popup = document.getElementById("orderDetail");
      popup.classList.add('is-show');
      var blackBg = document.getElementById('black-bg-orderDetail');
      var closeBtn = document.getElementById('close-btn-orderDetail');
      closePopUp(blackBg);
      closePopUp(closeBtn);
      function closePopUp(elem) {
        if(!elem) return;
        elem.addEventListener('click', function(event) {
          event.stopPropagation();
          popup.classList.remove('is-show');
          _this.reportJsonList = []
        })
      }
      console.log(_this.orderjsonList)
      var orderJson = _this.orderjsonList[event.currentTarget.closest("tr").getAttribute('ordernumber')-1]
      console.log("オーダーjson")
      console.log(event.currentTarget.getAttribute('ordernumber'))
      console.log(orderJson)
      _this.GroupName = orderJson["OrderGroup"]["Name"]
      _this.DoubleReadingMode = orderJson["OrderGroup"]["Order"]["DoubleReadingMode"]
      if(orderJson["OrderGroup"]["Order"]["Anonymously"] == 1){
        document.getElementById("Anonymously").textContent = "あり"
        // document.getElementById("Anonymously").disabled = true
      }else{
        document.getElementById("Anonymously").textContent = "なし"
      }
      _this.Applicant = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Applicant"]
      _this.InterpretationRequests = orderJson["OrderGroup"]["Order"]["OrderInfo"]["InterpretationRequests"]
      _this.OutPatientHospital = orderJson["OrderGroup"]["Order"]["OrderInfo"]["OutPatientHospital"]
      _this.Departments = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Departments"]
      _this.LedgerNumber = orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"]
      _this.EditedPatientName = orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientName"]
      _this.EditedPatientIdeogram = orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientIdeogram"]
      _this.EditedPatinePhonetic = orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatinePhonetic"]
      _this.AttendingPhysician = orderJson["OrderGroup"]["Order"]["OrderInfo"]["AttendingPhysician"]
      _this.Protocol = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Protocol"]
      _this.Operator = orderJson["OrderGroup"]["Order"]["OrderInfo"]["Operator"]
      _this.ContrastAgent = orderJson["OrderGroup"]["Order"]["OrderInfo"]["ContrastAgent"]
      _this.ClinicalInfo = orderJson["OrderGroup"]["Order"]["OrderInfo"]["ClinicalInfo"]
      _this.StudyComments = orderJson["OrderGroup"]["Order"]["OrderInfo"]["StudyComments"]
      _this.OrderComments = orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderComments"]

      // 伝票
      if(orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] !== ""){
        document.getElementById("preview").setAttribute('src','data:image/png;base64,' + orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"])
      }

      var domHeight = document.getElementById("popup-inner_orderDetail").clientHeight;
      document.getElementById("black-bg-orderDetail").style.height = domHeight + 'px'

    },
    async orderCancel(event) {
      this.orderCancel_axios(event,"order")
      return;
    },
    async orderCancel_group(event) {
      this.orderCancel_axios(event,"group")
    },
    async orderCancel_axios(event,search_type){
      var _this = this
      let formData = new FormData()
      let cancelTarget = ""
      let ordergroupseq = ""
      let orderuid = ""
      if(search_type == "group"){
        ordergroupseq = event.currentTarget.closest("tr").getElementsByClassName("cell-seq")[0].textContent
        formData.append("orderuid","none")
        formData.append("ordergroupseq",ordergroupseq)
        cancelTarget = event.currentTarget.closest("tr").getElementsByClassName("cell-ordergroupname")[0].textContent
      }else{
        orderuid = event.currentTarget.closest("tr").getElementsByClassName("cell-orderuid")[0].textContent
        ordergroupseq = event.currentTarget.closest("tr").getElementsByClassName("cell-ordergroupseq")[0].textContent
        formData.append("orderuid",orderuid)
        formData.append("ordergroupseq",ordergroupseq)
        cancelTarget = event.currentTarget.closest("tr").getElementsByClassName("cell-orderid")[0].textContent
      }
      await axios.post(
        _this.$store.state.API_HOST + '/order_cancel/',
        formData,
        {
          params: {
              'app_name': _this.$store.state.APP_NAME,
              'user_id': _this.$store.state.userID,
          },
          withCredentials: true
        }
      ).then(
        function(response) {
          console.log(response)
          if(typeof(response.data) != "string"){
            if(search_type == "group"){
              _this.result = "オーダーをキャンセルしました。  [グループ名：" + cancelTarget + "]";
              console.log("オーダーをキャンセルしました。  [グループSEQ：" + ordergroupseq + "]")
            }else{
              _this.result = "オーダーをキャンセルしました。  [オーダー番号：" + cancelTarget + "]";
              console.log("オーダーをキャンセルしました。  [オーダーUID：" + orderuid + "]")
            }
            _this.get_data('group','')
          }else{
            console.log("ログアウト")
            _this.$store.dispatch('logout');
            _this.$store.commit('setLoginUser', '')
          }
        }.bind(this)
      )
      .catch(
        function(error) {
          this.result = "GETエラー";
          console.log(error);
          this.$store.dispatch('logout');
          this.$store.commit('setLoginUser', '')
        }.bind(this)
      );
    },
    // レポートを開くためのリンクをクリップボードにコピー
    reportLinkCopy_group(event){
      let seq = event.currentTarget.closest("tr").getElementsByClassName("cell-seq")[0].textContent
      let value = "[レポート](" + location.origin + '/reportlink' + "?ordergroupseq=" + seq + ")"
      this.copyToClipboard(value)
    },
    reportLinkCopy(event){
      let seq = event.currentTarget.closest("tr").getElementsByClassName("cell-orderuid")[0].textContent
      let value = "[レポート](" + location.origin + '/reportlink' + "?orderuid=" + seq + ")"
      this.copyToClipboard(value)
    },
    // オーダーを特定するためのキー情報をクリップボードにコピー
    keyInfoCopy_group(event){
      let ordergtoupname = event.currentTarget.closest("tr").getElementsByClassName("cell-ordergroupname")[0].textContent
      let domain = event.currentTarget.closest("tr").getElementsByClassName("cell-domain")[0].textContent
      let value = "グループ名：" + ordergtoupname + "  施設名：" + domain
      this.copyToClipboard(value)
    },
    keyInfoCopy(event){
      let patientid = event.currentTarget.closest("tr").getElementsByClassName("cell-patientid")[0].textContent
      let domain = event.currentTarget.closest("tr").getElementsByClassName("cell-domain")[0].textContent
      let studydate = event.currentTarget.closest("tr").getElementsByClassName("cell-StudyDate")[0].textContent
      let modality = event.currentTarget.closest("tr").getElementsByClassName("cell-StudyModalities")[0].textContent
      let value = "患者名：" + patientid + "  施設名：" + domain + "  検査日：" + studydate + "  モダリティ：" + modality
      this.copyToClipboard(value)
    },
    copyToClipboard(value){
      console.log(value)
      if (!navigator.clipboard) {
        alert("このブラウザは対応していません");
        return;
      }
      navigator.clipboard.writeText(value).then(
        () => {
          alert('文章をコピーしました。');
        },
        () => {
          alert('コピーに失敗しました。');
      });
    },
    noClick(event) {
      console.log(event);
    },
    showFunction(num,type) {
      var _this = this
      console.log(num)
      _this.clicknumber_group = 0
      _this.clicknumber_order = 0
      if(type == "group"){
        _this.clicknumber_group = num
      }
      if(type == "order"){
        _this.clicknumber_order = num
      }
      // this.resultList[num].olgHide = !this.resultList[num].olgHide
      closePopUp(document.body);
      function closePopUp(elem) {
        if(!elem) return;
        elem.addEventListener('click', function() {
          _this.clicknumber_group = 0
          _this.clicknumber_order = 0;
        })
      }
    },
    closeFunction(){
      var _this = this
      _this.clicknumber_group = 0
      _this.clicknumber_order = 0
    },
    closeFunction_group(){
      var _this = this
      _this.clicknumber_group = 0
      // _this.clicknumber_order = 0
    },
    handle_function_call(function_name,event) {
      var _this = this
      _this[function_name](event);
    },
  },
  mounted: function(){
    var _this = this
    _this.get_data('group','')
  }
};
</script>

<style>
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: .6s;
}
.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: max-content;
    min-height: 100%;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: .6s;
    overflow: auto; /* ←追記 */
    background-color: rgba(0,0,0,.8);
}

.popup.is-show {
  opacity: 1;
  visibility: visible;
}

.popup-inner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1000px;
    margin-top: 0.5rem;
     /* padding: 50px; / ←下記に修正 */
    padding: 1rem;
    background-color: #fff;
    z-index: 2;
}

#popup-inner_orderDetail {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    margin-top: 0.5rem;
     /* padding: 50px; / ←下記に修正 */
    padding: 1rem;
    background-color: #fff;
    z-index: 2;
}
.popup-inner img {
  width: 100%;
}

.popup-inner input {
  width: 100px;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.close-btn i {
  font-size: 20px;
  color: #333;
}

.black-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 1;
  /* cursor: pointer; */
}
#result {
    color:red
}

#print {
  width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 1em;
}

.mouse_hover:hover {
  background-color: rgb(118, 183, 239);
}
#ViewZone textarea{
  vertical-align:top;
}
#ViewZone textarea,#ViewZone input,#ViewZone select{
  pointer-events: none;
  background: #e4e4e4;
}
#orderTable, #groupTable {
  width: max-content;
}
.tableScroll {
  /* overflow:hidden;
  overflow-y: scroll; */
  overflow: auto;
  border: solid 1px;
  resize: vertical;
}
#orderSearch span.filter-name {
  display: inline-block;
  width: 7em;
}
.container::-webkit-scrollbar {  
  display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
}
#groupTable td,#groupTable th {
  width: max-content;
}
#orderTable td,#orderTable th {
  width: max-content;
}
#groupTable, #orderTable {
    border-top: solid 1px;
    border-bottom: solid 1px
}
#groupTable thead, #orderTable thead {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;  
}
.hide {
    display: none;
}
.table-wrapper {
  position: relative;
    width: calc(100% - 1rem);
    height: 20rem;
    overflow: auto;
}
.table-wrapper table {
  width: max-content;
  position: relative;
}
.table-wrapper td, th {
  width: max-content;
}
.table-wrapper th {
  position: sticky;
  top: 0px;
  z-index: 4;
}
.table-wrapper td {
  background-color: #ffffff;
  padding: 0.75rem 0.5rem;
  vertical-align: baseline;
}
.table-wrapper .cell-status, .cell-orderer, .cell-function {
  text-align: center;
}
.table-wrapper .cell-function {
  position: sticky;
  right: 0px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 2;
}
.table-wrapper .cell-function:hover svg {
  opacity: 0.75;
}
.table-wrapper th.cell-function {
  position: sticky;
  top: 0px;
  font-size: 1em;
  cursor: inherit;
  z-index: 3;
}
td.cell-checked,th.cell-checked {
  position: sticky;
  top: 0px;
  left: 0px;
  vertical-align: middle;
}

td.cell-function,th.cell-function{
  position: sticky;
  top: 0px;
  right: 0px;
  vertical-align: middle;
}
.VariableTable th {
  background-color:#d3d3d3 !important;
}

.VariableTable tr:nth-of-type(odd) td {
  background-color: #eee !important;
}

.VariableTable tr:nth-of-type(even) td {
  background-color: #f2f2f2 !important;
}


.orderlist-function-group {
  /* position: absolute; */
  top: 25%;
  /* right: 48px; */
  width: max-content;
  z-index: 3;
  background-color: #ffffff;
}
.orderlist-function-group .btn {
  border-radius: 5px;
  color: #3b3b3b;
}
.orderlist-function-group .btn:first-child {
  border-top-right-radius: 0;
}
.olgHide, .olgHide_function {
  display: none!important;
}
.page {
  overflow: auto;
}

.VariableTable td, .VariableTable th {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.VariableTable thead th {
  resize: horizontal;
  overflow: hidden;
}

.VariableTable thead th:not(.condition,.cell-checked,.cell-function) {
  cursor: pointer;
}

.VariableTable thead th input:not(.date-form,.form-check-input){
  width: 100%;
}
.VariableTable thead th .dates{
  width: 100%;
}

.hide {
  display: none;
}

th.active {
  color: #000;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}
.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #000;
}
.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}
.perPage_label {
  margin-left: 20px;
}
.sortClear {
  margin-left: 20px;
  margin-bottom: 3px;
}
.pagination {
  margin-top: 5px;
}
.page-link {
  color:#333 !important;
}
.active>.page-link,.page-link.active {
  /* background-color:#44aa34e0 !important; */
  color:#fff !important;
}
.form-check-input{
  background-color: #fff;
  color: inherit;
  /* cursor: not-allowed; */
  opacity: 1 !important;
}
#reportMenu{
  float:right;
  margin-right: 20px;
}
.reportMenuItem {
  margin-right: 20px;
  cursor: pointer;
}
#reportPageNum {
  z-index: 9999;
  position: absolute;
  font-size: 45px;
  left: 5%;
  top: 1%;
}
.reportViewControl, #reportPageNum {
  color:rgb(129 13 143);
}
.reportViewControl {
  font-size:70px;
  font-weight:900;
  position:absolute;
  top: 45%;
  z-index: 9999;
  cursor:pointer;
}

#reportList_forPrint{
	page-break-after: avoid;
}

#result_orderlist{
  color: indianred;
}

#orderlist_top input:not(.form-check-input),#orderlist_top textarea,#orderlist_top select {
    background-color: white;
    border-style: solid;
    border-width: 1px;
}

#print{
  background-color: #e4e4e4 !important;
}

/* #orderlist_top button {
    background-color: white;
    border-style: none;
} */

#orderlist_top button,#orderlist_top input,#orderlist_top optgroup,#orderlist_top select,#orderlist_top textarea {
    font: initial;
}

</style>
