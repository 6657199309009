<template>
    <div class="container">
        <h2><font-awesome-icon icon="gauge-high" />&nbsp;{{ pageTitle }}</h2>
        <div class="row gy-3 mb-3">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h3 class="m-0 px-0 pt-2 fs-4"><font-awesome-icon icon="square-rss" />&nbsp;{{ newsTitle }}</h3>
                    </div>
                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">発信日</th>
                                    <th scope="col">メッセージ</th>
                                    <th scope="col">新着</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( item, i ) of msgs" :key="i">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.message }}</td>
                                    <td><span v-if="item.new" class="badge bg-danger">new</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            btnName: 'Button',
            pageTitle: 'ダッシュボード',
            newsTitle: '運営からのお知らせ',
            readStatusSummaryTitle: '読影完了状況',
            msgs: [
                {date: '22/4/20', new: false, message: 'ようこそ、EsForumへ!', },
                {date: '22/4/22', new: false, message: '「お試し遠隔画像診断サービス」をぜひご活用ください。', },
                {date: '22/4/28', new: false, message: '本サービスへお申込みいただきありがとうございました。', },
                {date: '22/5/2', new: true, message: '4/29分の読影オーダーが読影完了しています。', },
            ],
            readStatusSummary: [
                {order: '2.20220502.114225.444236', orderDate: '22/5/2', status: '完了'},
                {order: '2.20220502.114444.349932', orderDate: '22/5/2', status: '完了'},
                {order: '2.20220502.115347.904465', orderDate: '22/5/2', status: '完了'},
            ],
            readPath: 'https://www.esfill.co.jp/esinfo/read.php'
        }
    },
    methods: {
        loadMessages: function( url ) {
            fetch ( url )
            .then ( res => res.json() )
            .then ( data => {
                this.msgs = data
            } )
        }
    },
    mounted: function() {
        this.msgs.reverse()
        this.loadMessages( this.readPath )
    },
}
</script>