<template>
    <div class="container">
        <h2><font-awesome-icon icon="fa-id-card" />&nbsp;{{ pageTitle }}</h2>
        <div class="row gy-3 mb-3">
            <button id = "userInfo" class="btn btn-sm btn-info" @click="showUserInfo()" style="width: 200px;height 50px;">クライアント情報表示</button>
            <div id = "messagePreview">
                <div v-for="(item,index) in messageList" v-bind:key="index">
                    {{ item }}
                </div>

            </div>
            <!-- <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                        <h3 class="m-0 px-0 pt-2 fs-4"><fa icon="square-rss" />&nbsp;{{ newsTitle }}</h3>
                    </div>
                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">発信日</th>
                                    <th scope="col">メッセージ</th>
                                    <th scope="col">新着</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( item, i ) of msgs" :key="i">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.message }}</td>
                                    <td><span v-if="item.new" class="badge bg-danger">new</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                        <h3 class="m-0 px-0 pt-2 fs-4"><fa icon="inbox" />&nbsp;{{ readStatusSummaryTitle }}</h3>
                    </div>
                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">オーダーNo</th>
                                    <th scope="col">オーダー日</th>
                                    <th scope="col">状況</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( item, i ) of readStatusSummary" :key="i">
                                    <td>{{ item.order }}</td>
                                    <td>{{ item.orderDate }}</td>
                                    <td>{{ item.status }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import {API_IP, API_PORT} from "../const/const.js"

export default {
    data() {
        return {
            btnName: 'Button',
            pageTitle: '管理者画面',
            postList: [],
            messageList: [],
            // newsTitle: '運営からのお知らせ',
            // readStatusSummaryTitle: '読影完了状況',
            // msgs: [
            //     {date: '22/4/20', new: false, message: 'ようこそ、EsForumへ!', },
            //     {date: '22/4/22', new: false, message: '「お試し遠隔画像診断サービス」をぜひご活用ください。', },
            //     {date: '22/4/28', new: false, message: '本サービスへお申込みいただきありがとうございました。', },
            //     {date: '22/5/2', new: true, message: '4/29分の読影オーダーが読影完了しています。', },
            // ],
            // readStatusSummary: [
            //     {order: '2.20220502.114225.444236', orderDate: '22/5/2', status: '完了'},
            //     {order: '2.20220502.114444.349932', orderDate: '22/5/2', status: '完了'},
            //     {order: '2.20220502.115347.904465', orderDate: '22/5/2', status: '完了'},
            // ],
        }
    },
    // mounted: function() {
    //     this.msgs.reverse()
    // },
    methods: {
        async showUserInfo() {
            await this.settingData();
            console.log(this.postList)

            var _this = this
            var obj = {"data":this.postList}
            const post_url = this.$store.state.API_HOST + '/manage'
            axios.post(post_url, obj)
            .then(
                function(response) {
                    console.log(response["data"])
                    for(let i=0;i < response["data"].length; i++){
                        _this.messageList.push(response["data"][i])
                    }
                }.bind(this)
            )
            .catch(
                function(error) {
                    console.log(error.response);
                }.bind(this)
            );
        },
        async settingData() {
            this.postList.length = 0;
            this.messageList.length = 0;
            
            // URL
            this.postList.push("URL：" + location.href)

            // ホスト名
            this.postList.push("ホスト名：" + location.hostname)

            // IPアドレス
            const API_URL = 'https://api.ipify.org/?format=json'
            const res = await fetch(API_URL)
            const data = await res.json()
            this.postList.push("IPアドレス：" + data.ip)
            // fetch('https://ipinfo.io?callback')
            // .then(res => res.json())
            // .then(json => console.log("IPアドレス：" + json.ip))

            // ポート番号
            this.postList.push("ポート番号：" + location.port)

            // プロトコル
            this.postList.push("プロトコル：" + location.protocol)

            // 使用ブラウザ
            var userAgent = window.navigator.userAgent.toLowerCase();
            var browser = ""
            if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
                browser = 'Internet Explorer';
            } else if(userAgent.indexOf('edge') != -1) {
                browser = 'Edge';
            } else if(userAgent.indexOf('chrome') != -1) {
                browser = 'Google Chrome';
            } else if(userAgent.indexOf('safari') != -1) {
                browser = 'Safari';
            } else if(userAgent.indexOf('firefox') != -1) {
                browser = 'FireFox';
            } else if(userAgent.indexOf('opera') != -1) {
                browser = 'Opera';
            } else {
                browser = '不明';
            }
            this.postList.push("使用ブラウザ：" + browser)

            // ブラウザ名
            this.postList.push("ブラウザ名：" + navigator.appName)

            // ブラウザ使用言語
            this.postList.push("ブラウザ使用言語：" + navigator.language)

            // ブラウザプラットフォーム
            this.postList.push("ブラウザプラットフォーム：" + navigator.platform)

            // ブラウザバージョン
            this.postList.push("ブラウザバージョン：" + navigator.appVersion)

            // ブラウザユーザーエージェント
            this.postList.push("ブラウザユーザーエージェント：" + navigator.userAgent)

            // リファラー
            this.postList.push("リファラー：" + document.referrer)

            // ドメイン名
            this.postList.push("ドメイン名：" + document.domain)
            

            // 使用OS
            var ua = window.navigator.userAgent.toLowerCase();
            var osName = ""
            if(ua.indexOf("windows nt") !== -1) {
                osName = "Microsoft Windows"
            } else if(ua.indexOf("android") !== -1) {
                osName = "Android"
            } else if(ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1) {
                osName = "iOS"
            } else if(ua.indexOf("mac os x") !== -1) {
                osName = "macOS"
            } else if(ua.indexOf("linux") !== -1) {
                osName = "Linux"
            } else {
                osName = "不明"
            }
            this.postList.push("使用OS：" + osName)

            // 位置情報
            await this.getLocation();

            // 画面横幅
            this.postList.push("画面横幅：" + screen.width)

            // 画面高さ
            this.postList.push("画面高さ：" + screen.height)

            // 画面色深度
            this.postList.push("画面色深度：" + screen.colorDepth)

            // ブラウザビューポート幅
            this.postList.push("ブラウザビューポート幅：" + window.innerWidth)

            // ブラウザビューポート高さ
            this.postList.push("ブラウザビューポート高さ：" + window.innerHeight)

            // デバイスピクセル比
            this.postList.push("デバイスピクセル比：" + window.devicePixelRatio)

            return;
        },
        getLocation () {
            console.log("位置情報取得")
            return new Promise((resolve, reject) => {
                // 現在地を取得
                navigator.geolocation.getCurrentPosition(
                    // 取得成功した場合
                    (position) => {
                        console.log(position.coords)
                        this.postList.push("緯度：" + position.coords.latitude);
                        this.postList.push("経度：" + position.coords.longitude);
                        this.postList.push("高度：" + position.coords.altitude);
                        this.postList.push("緯度・経度の誤差：" + position.coords.accuracy);
                        this.postList.push("高度の誤差：" + position.coords.altitudeAccuracy);
                        this.postList.push("方角（0が北、90が東、180が南、270が西）：" + position.coords.heading);
                        this.postList.push("速度（位置情報を追跡する場合に使用）：" + position.coords.speed);
                        // console.log(result); // ここは正しく表示
                        resolve(position.coords);
                    },
                    // 取得失敗した場合
                    (error) => {
                        switch (error.code) {
                            case 1: //PERMISSION_DENIED
                            this.postList.push("位置情報：位置情報の利用が許可されていません");
                            // alert("位置情報の利用が許可されていません");
                            break;
                            case 2: //POSITION_UNAVAILABLE
                            this.postList.push("位置情報：現在位置が取得できませんでした");
                            // alert("現在位置が取得できませんでした");
                            break;
                            case 3: //TIMEOUT
                            this.postList.push("位置情報：タイムアウトになりました");
                            // alert("タイムアウトになりました");
                            break;
                            default:
                            this.postList.push("位置情報：その他のエラー(エラーコード:" + error.code + ")");
                            // alert("その他のエラー(エラーコード:" + error.code + ")");
                            break;
                        }
                        reject(error.code);
                    }
                );
            }).catch(function(error) {

                console.log(error.code);

            }).then(() => console.log("Next successful handler runs"));
        },
    },
}
</script>