import { createRouter, createWebHistory } from 'vue-router';
// import Comments from './views/Comments.vue';
import LoginScreen from './views/LoginScreen.vue';
import RegisterScreen from './views/RegisterScreen.vue';
import DicomParser from './views/DicomParser.vue';
import JpegParser from './views/JpegParser.vue';
import ProfileEdit from './views/ProfileEdit.vue';
import OrderList from './views/OrderList.vue';
import HomeScreen from './views/HomeScreen.vue';
// import TopScreen from './views/TopScreen.vue';
import store from './store';
import ManageScreen from './views/ManageScreen.vue';
import VerifyScreen from './views/VerifyScreen.vue';
import RegisterDoctor from './views/RegisterDoctor.vue';
import ReportLink from './views/ReportLink.vue';
import ManageUser from './views/ManageUser.vue';
import EditAnnouncement from './views/EditAnnouncement.vue';


const routes = [
  //  {
  //   path:'/',
  //   component:TopScreen,
  //   // beforeEnter(to, from, next){
  //   //  if (store.getters.userID != 0 && store.getters.userID != -1) {
  //   //   next();
  //   //  } else {
  //   //   next('/login');
  //   //  }
  //   // }
  //  },
   {
    path:'/',
    component:LoginScreen,
    beforeEnter(){
      if(store.getters.userID != 0){
        store.state.userID = 0
        localStorage.removeItem("useID")
        store.dispatch('logout');
        store.commit('setLoginUser', '')
      }
    }
   },
   {
    path:'/register',
    component:RegisterScreen,
    beforeEnter(to, from, next){
     if (store.getters.userID != 0 && store.getters.userID != -1) {
      next('/');
     } else {
      next();
     }
    }
   }, 
   {
    path:'/profileedit',
    component:ProfileEdit,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/dicomparser',
    component:DicomParser,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
   path:'/Jpegparser',
   component:JpegParser,
   beforeEnter(to, from, next){
    if (store.getters.userID != 0) {
      next();
    } else {
      if(localStorage.getItem("userID")){
        store.state.userID = localStorage.getItem("userID")
        next();
      }else{
        next('/');
      }
    }
   }
  },
   {
    path:'/orderlist',
    component:OrderList,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/manage_user',
    component:ManageUser,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/RegisteredDoctor',
    component:RegisterDoctor,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/reportlink',
    component:ReportLink,
    beforeEnter(to, from, next){
      next();
    }
   },
   {
    path:'/home',
    component:HomeScreen,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
   path:'/manage',
    component:ManageScreen,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/verify/:token',
    component:VerifyScreen,
    beforeEnter(to, from, next){
     if (store.getters.userID != 0 && store.getters.userID != -1) {
      next('/');
     } else {
      next();
     }
    }
   },
   {
    path:'/edit_announcement',
    component:EditAnnouncement,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router