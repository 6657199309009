import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import { loadFonts } from './plugins/webfontloader'

/**
 * import Bootstrap5
 */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

/**
 * Load Fontawesome 5
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// //Import Froala Editor 
// import 'froala-editor/js/plugins.pkgd.min.js';
// //Import third party plugins
// import 'froala-editor/js/third_party/embedly.min';
// import 'froala-editor/js/third_party/font_awesome.min';
// import 'froala-editor/js/third_party/spell_checker.min';
// import 'froala-editor/js/third_party/image_tui.min';
// // Import Froala Editor css files.
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/css/froala_style.min.css';


// // Import Froala Editor component
// import VueFroala from 'vue-froala-wysiwyg';

// import { quillEditor } from 'vue3-quill'
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

loadFonts()
library.add(fas)

// Vue アプリケーションを作成します
const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(router)
app.use(store)
app.use(vuetify)
app.use(VueQuillEditor)
// app.config.productionTip = false
// app.use(cors({ origin: true, credentials: true }));
app.mount('#app')
