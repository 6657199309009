<template>
  <div id="app">
    <header id="head" class="top-banner">
      <div class="mx-auto px-3 py-3 text-start">
        <img src="icon_esmanage.png" alt="EsManage Logo" class="logo-top-page-link" @click="gotoTop()">
        <login-status-menu :login="isAuthenticated"></login-status-menu>
      </div>
      <!-- <template v-if="isAuthenticated">
        <router-link to="/dicomparser" class="header-item">DCMファイルアップロード</router-link>
        <router-link to="/orderlist" class="header-item">オーダーリスト</router-link>
        <router-link to="/profileedit" class="header-item">プロフィール編集</router-link>
        <button  class="header-item btn btn-primary" id="logout" @click="logout">ログアウト</button>
      </template>
      <template v-if="!isAuthenticated">
        <router-link to="/login" class="header-item">ログイン</router-link>
        <router-link to="/register" class="header-item">登録</router-link>
      </template> -->
    </header>
    <main>
      <nav v-if="isAuthenticated" class="main-menu">
        <main-menu
          :menuItems="menuItems"
        ></main-menu>
      </nav>
      <router-view class="page"></router-view>
    </main>
  </div>
</template>

<script>
import LoginStatusMenu from './components/LoginStatusMenu.vue';
import MainMenu from './components/MainMenu.vue'

export default {
  components: {
    LoginStatusMenu,
    MainMenu,
  },
  data() {
    return {
      menuItems:[
        {name: 'オーダー状況の確認', href: '/orderlist', icon: 'rectangle-list'},
        // {
        //   name: 'オーダー状況の確認', icon: 'clipboard-user',
        //   subItems:[
        //     {name: '全体', href: '/OrderView_all', icon: 'clipboard-user'},
        //     {name: '契約単位', href: '/OrderView_agreement', icon: 'clipboard-user'},
        //     {name: 'ユーザー単位', href: '/OrderView_user', icon: 'clipboard-user'},
        //   ]
        // },
        {name: 'お知らせ運用', href: '/edit_announcement', icon: 'fa-bullhorn'},
        {name: 'ユーザーの管理', href: '/manage_user', icon: 'fa-user'},
        {
          name: '施設の管理', icon: 'fa-people-roof',
          subItems:[
            {name: '施設管理', href: '/manage_hospitals', icon: 'fa-hospital'},
            {name: '法人管理', href: '/manage_corporations', icon: 'fa-hospital-user'},
          ]
        },
        {name: '契約の管理', href: '/manage_customers', icon: 'fa-id-card'},
        {
          name: 'アプリケーション設定', icon: 'clipboard-user',
          subItems:[
            {name: 'メニュー設定', href: '/AppSetting_menu', icon: 'clipboard-user'},
            {name: 'ストレージ管理', href: '/AppSetting_storage', icon: 'clipboard-user'},
            {name: '言語設定', href: '/AppSetting_language', icon: 'clipboard-user'},
            {name: 'GUIレイアウト管理', href: '/AppSetting_layout', icon: 'clipboard-user'},
          ]
        },
        // {name: 'システムの稼働状況管理', href: '/OperationStatus', icon: 'rectangle-list'},
        {
          name: 'システムの稼働状況', icon: 'rectangle-list',
          subItems:[
            {name: 'テスト', href: '/AppSetting_menu', icon: 'clipboard-user'},
            {name: 'テスト管理', href: '/AppSetting_storage', icon: 'clipboard-user'},
            {name: '言語設定', href: '/AppSetting_language', icon: 'clipboard-user'},
            {name: 'GUIレイアウト管理', href: '/AppSetting_layout', icon: 'clipboard-user'},
          ]
        },
        {name: 'サービスの利用状況', href: '/UsageSituation', icon: 'fa-id-card'},
        {name: 'システム負荷の監視', href: '/SystemLoad', icon: 'fa-id-card'},
        {name: '登録読影医の管理', href: '/RegisteredDoctor', icon: 'fa-id-card'},
      ],
    }
  },
  computed: {
    isAuthenticated(){
      return this.$store.getters.userID !== 0 && this.$store.getters.userID !== -1;
    }
  },
  methods:{
    // logout(){
    //   console.log("ログアウトfrom App.vue")
    //   this.$store.dispatch('logout');
    // },
    gotoTop() {
      console.log("ホーム画面に戻ります。")
      this.$router.push('/orderlist')
    }
  },
  created(){
    console.log("vue開始")
    this.$store.dispatch('loadJSONData',"");
  }
}
</script>

<style scoped>
.header-item{
  padding: 10px;
  cursor: pointer;
}
</style>
<style>
/* @import 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'; */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
  display: flex;
	flex-direction: column;
	min-height: 100vh;
}
#app main {
  flex: 1 1 auto;
  background-color: #f2f2f2;
  display: flex;
}
#app main nav.main-menu {
  padding: 0.75rem 0 1rem 1rem;
  flex: 0 0 auto;
  background-color: transparent;
}
#app main .page {
  padding: 0.75rem 0 0 0.25rem;
  flex: 1 1 auto;
}

#logout {
  float: right;
  padding: 5px;
  margin-right: 5px;
}
.top-banner div {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-banner div img {
  height: 100%;
}
.logo-top-page-link {
  filter: drop-shadow(2px 6px 2px rgba(0,0,0,0.25));
  cursor: pointer;
}

#app main .page {
    padding: 0.75rem 0 0 1.25rem;
    flex: 1 1 auto;
}

a[href="/Manage"]{
    position: fixed;
    bottom: 25px;
    color: #000000 !important;
}

a[href="/Manage"]:hover{
    color: #2a2222 !important;
}
</style>
